import {User} from "../users/users.models";
import {Finish, PaymenTerm, Process, RoofingType, ShippingMethod, Warranty} from "../masterdata/masterdata.models";
import {Product, ProductPrice} from "../products/products.model";
import {BaseModel, BaseModelShared} from "../base/base.model";

export class Order extends BaseModel {
    orderNumber: number;
    versionNumber: number;
    parentId: string;
    employeeId: string;
    employee: User = new User();
    responsibleId: string;
    responsible: User = new User();
    orderCustomers: OrderCustomer[] = [];
    orderCustomerId: string;
    orderParts: OrderPart[] = [];
    contactId: number;
    customerId: number;
    customer: any;
    warrantyId: string;
    warranty: Warranty = new Warranty();
    shippingMethodId: string;
    shippingMethod: ShippingMethod = new ShippingMethod();
    paymentTermId: string;
    paymentTerm: PaymenTerm = new PaymenTerm();
    uisVersion: number = 3;
    projectTitle: string;
    projectDescription: string;
    remarks: string;
    orderDate: number;
    orderDateDate: Date;
    sendDate: number;
    sendDateDate: Date;
    labourHours: number;
    labourCost: number;
    travelHours: number;
    travelCost: number;
    craneHours: number;
    craneCost: number;
    transportHours: number;
    safetyHours: number;
    calculationHours: number;
    calculationCost: number;
    preparationHours: number;
    inspectionHours: number;
    parkingCost: number;
    profitAndRisk: number;
    commonCosts: number;
    bonusContractPrice: number;
    totalPrice: number;
    vatLabour: number;
    vatMaterials: number;
    standardText01: string;
    standardText02: string;
    standardText03: string;
    standardText04: string;
    standardText05: string;
    standardConditions: string;
    standardInvoiceDescription: string;
    order: boolean;
    addQuotation: boolean;
    windLoad: boolean;
    constructionPhysicalCalculation: boolean;
    cadDrawings: boolean;
    photos: boolean;
    privateAssignment: boolean;
    goProjectId: number;
    year: number;
    newTotal: number;
    readOnly: boolean;
    displayName: string;
    displayTitle: string;
    isConstruction: boolean;
    isRenovation: boolean;
    facilityGroups: any = [];
    facilities: OrderFacility[] = [];
    preferredSuppliers: any = [];
    workAddress: string;
    workCity: string;
    totals: any;
}

export class OrderCustomer extends BaseModel {
    orderId: string;
    contactId: number;
    contact: any;
    customerId: number;
    customer: any;
    sortOrder: string;
}

export class OrderPart extends BaseModel {
    orderId: string;
    code: string = 'A';
    description: string;
    size: number;
    _sizeFilled: boolean;
    length: number;
    _lengthFilled: boolean;
    salesPrice: number;
    finishes: OrderPartsFinish[] = [];
    roofingTypeId: string = '';
    roofingType: RoofingType = new RoofingType();
}

export class OrderPartsFinish extends BaseModel {
    orderPartId: string;
    finishId: string = '';
    finish: Finish = new Finish();
    processId: string = '';
    process: Process = new Process();
    description: string;
    size: number;
    sortOrderNumber: number;
    details: OrderDetail[] = [];
    recipeId: string = '';
    choices: OrderPartsFinishChoice[] = [];
    finishText: string;
}

export class OrderDetail extends BaseModel {
    orderPartFinishId: string = '';
    productId: string = '';
    product: Product = new Product();
    processId: string = '';
    process: Process = new Process();
    productPriceId: string = '';
    productPrice: ProductPrice = new ProductPrice();
    materialCost: number;
    materialFactor: number;
    materialLoss: number;
    piecesPerHour: number;
    labourCost: number;
    labourFactor: number;
    quantity: number;
    explanation: string;
    sortOrderNumber: number;
    detailText: string;
    modifiedText: string;
}

export class OrderFacility extends BaseModel {
    public orderId: string;
    public facilityProductGroupId: string;
    public facilityProductId: string;
    public facilityProduct: Product = new Product();
    public quantity: number = 0;
    public costPerUnit: number = 0;
    public multiplier: number = 1;
    public remarks: string;
}

export class OrderDetailRecipe extends BaseModel {
    name: string = '';
    description: string = '';
    choicesLevelOne: string;
    choices: string;
    details: string;
}

export class OrderPartsFinishChoice {
    choiceId: string = '--';
    choiceName: string;
    sortOrderNumber: number = 0;
    subChoices: OrderPartsFinishChoice[] = [];
    fieldValue: any = null;
    fieldType: string;
    isField: boolean;
    hasFields: boolean;
}
