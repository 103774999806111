<div style="height: calc(50% - 60px);">
    <p-table *ngIf="!_showPartsTree" #orderPartsFinishesTable tableStyleClass="djc-table" [loading]="_finishesLoading"
             [value]="orderPartsFinishes" [rows]="10" [paginator]="false" [pageLinks]="5" [scrollable]="true" scrollHeight="flex"
             selectionMode="single" dataKey="id" sortMode="single" [sortField]="'sortOrderNumber'" [sortOrder]="1" (onRowSelect)="selectFinish($event)"
             [contextMenu]="cm">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:8em">#</th>
                <th style="width:15em">{{ 'orders.size' | translate }}</th>
                <th style="width:20em">{{ 'orders.finish' | translate }}</th>
                <th>{{ 'base.strDescription' | translate }}</th>
                <th *ngIf="_canEdit" style="width:10em;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-orderPartFinish let-editing="editing" let-ri="rowIndex">
            <tr [pSelectableRow]="orderPartFinish" [ngStyle]="_st.getRowStyle(orderPartFinish.id, selectedFinish.id, ri)" [pContextMenuRow]="orderPartFinish">
                <td style="width:8em">{{ orderPartFinish.sortOrderNumber }}</td>
                <td style="width:15em" *ngIf="!_canEdit">{{ orderPartFinish.size }}</td>
                <td style="width:15em" *ngIf="_canEdit" pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [thousandSeparator]="_auth.getUserSettings().thousandSeparator" [decimalSeparator]="_auth.getUserSettings().decimalSeparator"
                                       [(ngModel)]="orderPartFinish.size" (ngModelChange)="orderPartFinishSave(orderPartFinish)" (onFocus)="selectInputContent($event)"></p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPartFinish.size }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width:20em" *ngIf="!_canEdit">{{ orderPartFinish.finish.code }}</td>
                <td style="width:20em" *ngIf="_canEdit" pEditableColumn [ngStyle]="{'background-color': orderPartFinish.finishId.length > 0 ? '' : 'red'}">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <djc-autoComplete [ngClass]="'djcAutoComplete'" appendTo="body" [suggestions]="_finishesFiltered" (completeMethod)="filterFinishes($event)"
                                              (onClear)="allFinishes()" field="label" (onSelect)="changeFinish($event, ri, orderPartFinish)"
                                              (onFocus)="selectInputContent($event)" [scrollHeight]="'300px'" [panelStyle]="{'width':'100em'}"
                                              [ngModelOptions]="{standalone: true}" [ngModel]="{label: orderPartFinish.finish.code}"
                                              [dropdown]="true" [dropdownMode]="'current'">
                            </djc-autoComplete>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPartFinish.finish.code }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="order.uisVersion >= 3" pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText [(ngModel)]="orderPartFinish.finishText"/>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPartFinish.finishText }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="_canEdit && order.uisVersion < 3" pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText [(ngModel)]="orderPartFinish.description" (ngModelChange)="orderPartFinishSave(orderPartFinish)"/>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPartFinish.description }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width:10em;" *ngIf="_canEdit">
                    <button *ngIf="order.uisVersion >= 3" pButton type="button" icon="fa fa-list" class="ui-button-info" style="margin-right: .3em;margin-left: .2em"
                            (click)="displayChoices(orderPartFinish.id)"></button>
                    <button *ngIf="_canDelete" pButton type="button" icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderPartFinishDelete(orderPartFinish, ri)"></button>
                    <button *ngIf="ri < orderPartsFinishes.length - 2" pButton type="button" icon="fa fa-arrow-down" class="ui-button" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderPartFinishMove(orderPartFinish, ri + 1)"></button>
                    <button *ngIf="ri == orderPartsFinishes.length - 2" pButton type="button" icon="fa fa-arrow-down" class="ui-button"
                            style="margin-right: .3em;margin-left: .2em; background-color: lightgray; border-color: lightgray"></button>
                    <button *ngIf="ri > 0 && ri !== orderPartsFinishes.length - 1" pButton type="button" icon="fa fa-arrow-up" class="ui-button" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderPartFinishMove(orderPartFinish, ri - 1)"></button>
                    <button *ngIf="ri == 0 && ri !== orderPartsFinishes.length - 1" pButton type="button" icon="fa fa-arrow-up" class="ui-button"
                            style="margin-right: .3em;margin-left: .2em; background-color: lightgray; border-color: lightgray"></button>
                </td>
            </tr>
            <tr *ngIf="orderPartFinish.id == selectedFinish.id && selectedFinish.finishId.length > 0 && _displayChoices">
                <td [colSpan]="maxCols">
                    <finishChoice [parentChoiceId]="selectedFinish.finishId" [(choices)]="selectedFinish.choices"
                                  [level]="0" (choicesChange)="emitChoiceChange($event)" [parentDescription]="selectedFinish.description"></finishChoice>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-contextMenu #cm [model]="_finishMenu" appendTo="body"></p-contextMenu>
</div>
<orderDetails *ngIf="!_displayChoices" [finish]="selectedFinish" [saveDetails]="_saveDetails" [reloadDetails]="_loadDetails" [order]="order" (detailsSaved)="addDetail($event)"></orderDetails>
<orderDetailRecipes *ngIf="_displayChoices" [recipes]="recipes" (recipeSelected)="recipeSelected($event)"></orderDetailRecipes>
<p-footer>
    <button type="button" pButton icon="fa fa-close" (click)="hideDialog()" label="{{ 'base.back' | translate }}"></button>
    <button *ngIf="order.uisVersion >= 3" type="button" pButton icon="fa fa-save" (click)="preSaveRecipe()" label="{{ 'orders.saveRecipe' | translate }}"></button>
</p-footer>
<ng-keyboard-shortcuts [shortcuts]="_kbShortCuts"></ng-keyboard-shortcuts>
<ng-keyboard-shortcuts-help [key]="'f1'" [closeKey]="'esc'" [title]="'Help'"></ng-keyboard-shortcuts-help>
<p-dialog [(visible)]="showRecipeDialog" [title]="recipeFormItems.name">
    <div class="ui-g">
        <div class="ui-g-4">{{ 'orders.newRecipe' | translate }}</div>
        <div class="ui-g-8"><p-checkbox [disabled]="newRecipe" [(ngModel)]="newRecipe" [binary]="true"></p-checkbox></div>
    </div>
    <div class="ui-g">
        <div class="ui-g-4">{{ 'base.strName' | translate }}</div>
        <div class="ui-g-8"><input pInputText type="text" [(ngModel)]="recipeFormItems.name" [disabled]="!newRecipe"/></div>
    </div>
    <div class="ui-g">
        <div class="ui-g-4">{{ 'base.strDescription' | translate }}</div>
        <div class="ui-g-8"><input pInputTextarea type="text" [(ngModel)]="recipeFormItems.description" /></div>
    </div>
    <div class="ui-g">
        <button type="submit" pButton icon="fa fa-save" (click)="saveRecipe()" label="{{ 'orders.saveRecipe' | translate }}"></button>
    </div>
</p-dialog>
