import {Component, EventEmitter, Input, Output} from "@angular/core";
import {SelectItem} from "primeng/api";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService, responseModel} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder} from "@angular/forms";
import {ProductsService} from "./products.service";
import {HttpClient, HttpEvent, HttpHeaders} from "@angular/common/http";
import {Product, ProductImport, ProductPrice} from "./products.model";

@Component({
    selector: 'csvImport',
    templateUrl: './csvimport.component.html',
    styleUrls: ['products.component.css']
})
export class CsvImportComponent {

    public _visible: boolean = false;

    public csvImport: boolean = true;
    public suppliers: any[] = [];
    public suppliersDD: SelectItem[] = [];
    public importModels: SelectItem[] = [];
    public headerFields: SelectItem[] = [];
    public connectedFields: any[] = [];
    public connectedPriceFields: any[] = [];
    public priceTypes: any[] = [];
    public importModel: ProductImport = new ProductImport();
    public import: any = {supplierId: 0, sheetName: ''};
    public showModelBuild: boolean = false;
    public buildModel: boolean;
    public files: File[] = [];
    public uploadUrl: string = '';
    public returnValues: any;
    public sheets: SelectItem[] = [];
    public tmpFile: string = '';
    public notFoundItems: any[] = [];
    public productTypes: SelectItem[] = [];
    public productGroups: SelectItem[] = [];
    private _cachedProductGroups: any[] = [];
    public defaultProductType: string = '';
    public selectedPriceType: any = {optionName: '', materialColumn: '', purchaseColumn: ''}

    @Input() get visible() {
        return this._visible;
    }
    set visible(visible: boolean) {
        this._visible = visible;
    }
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public _auth: AuthService, public _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder, private _productsService: ProductsService, private _http: HttpClient) {
        this.loadSuppliers();
        this.loadProductTypes();
        this.priceTypes.push({optionName: 'Standaard', optionCode: 'STD', materialColumn: '', purchaseColumn: ''});
    }

    beforeUpload(event) {
        event.formData.append('supplierId', this.import.supplierId);
        event.formData.append('action', 'build');
        this.files = event.files;
    }

    addPriceType(event, rowIndex) {
        if ((rowIndex + 1) === this.priceTypes.length) {
            this.priceTypes.push({optionName: '', optionCode: '', materialColumn: '', purchaseColumn: ''});
        }
    }

    afterUpload(event) {
        this.files = event.files;
        let response: responseModel = event.originalEvent.body;
        this.returnValues = response.data.records.headers;
        this.sheets = [];
        this.returnValues.forEach(function(sheetName, index) {
            this.sheets.push({value: index, label: sheetName.name});
        }, this);
        this.headerFields = this.returnValues[this.sheets[0].value].headers;
        this.import.sheetName = this.sheets[0].label;
        this.connectedFields = response.data.data.fields;
        this.connectedPriceFields = response.data.data.priceFields;
        this.tmpFile = response.data.data.tmpFile;
    }

    loadSuppliers() {
        this._apiService.setActionUrl('base/go');
        let filter = {type: 'supplier'};
        this._apiService.specialPostAction(JSON.stringify(filter), 'loadrelations').subscribe(response => {
            if (response.success) {
                this.suppliers = response.data.records;
                this.suppliersDD.push({value: '', label: this._transService.instant('base.useSelectValue')});
                this.suppliers.forEach(function(supplier) {
                    this.suppliersDD.push({value: supplier.id, label: supplier.name});
                }, this)
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadProductTypes() {
        this._apiService.setActionUrl('products/types');
        this._apiService.setFilter({field: 'uisVersion', operator: 'ge', value: 3});
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(response => {
            if (response.success) {
                this.productTypes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    selectProductType(event) {
        this.productGroups = [];
        if (this._cachedProductGroups[event.value] === undefined) {
            this._apiService.setActionUrl('products/groups');
            this._apiService.setFilter({field: 'productTypeId', operator: 'eq', value: event.value});
            this._apiService.setSort({field: 'description', direction: 'ASC'});
            this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(response => {
                if (response.success) {
                    this._cachedProductGroups[event.value] = response.data.records;
                    this.productGroups = this._cachedProductGroups[event.value];
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        } else {
            this.productGroups = this._cachedProductGroups[event.value];
        }
    }

    changeImportModel(event) {
        if (event.value === -1) {
            this.buildModel = true;
        }
    }

    changeSheet(event) {
        this.import.sheetName = this.returnValues[event.value].name;
        this.headerFields = this.returnValues[event.value].headers;
    }

    importFile() {
        let formData = new FormData();
        for(let i = 0; i < this.files.length; i++) {
            formData.append('files', this.files[i], this.files[i].name);
        }
        formData.append('action', 'import');
        formData.append('supplierId', this.import.supplierId);
        formData.append('sheetName', this.import.sheetName);
        formData.append('columnMapping', JSON.stringify(this.connectedFields));
        formData.append('defaultProductType', this.defaultProductType);
        formData.append('productPriceMapping', JSON.stringify(this.priceTypes));

        let headers: HttpHeaders = new HttpHeaders();
        this._http.post('api/products/import/import', formData, {
            headers: headers
        }).subscribe((response: responseModel) => {
            if (response.success) {
                this.notFoundItems = response.data.data;
                this.csvImport = false;
            }
        });
        let dummyEvent = {value: this.defaultProductType};
        this.selectProductType(dummyEvent);
    }

    checkColumns() {
        let disabled = false;
        this.connectedFields.forEach(function (field) {
            switch (field.field) {
                case 'materialCost':
                case 'itemCode':
                case 'name':
                    if (field.column == '---' || field.column == -1) {
                        disabled = true;
                    }
                    break;
                case 'description':
                    if (field.column == '---') {
                        disabled = true;
                    }
                    break;
            }
        });
        if (this.defaultProductType === '') {
            disabled = true;
        }
        for (let priceType of this.priceTypes) {
            if (priceType.optionName.length > 0 && priceType.optionCode.length === 0) {
                disabled = true;
                break;
            }
        }
        return disabled;
    }

    cancelCSV() {
        this.csvImport = true;
        this.sheets = [];
        this.headerFields = [];
        this.connectedFields = [];
        this.visibleChange.emit(false);
    }

    enableSaveProduct(notFound) {
        return (notFound.productGroupId === '')
    }

    saveProduct(event, notFound, row) {
        if (notFound.productGroupId === '') {
            notFound.import = false;
            return;
        }
        let newProduct = new Product();
        newProduct.description = notFound.description;
        newProduct.parentProductId = '';
        newProduct.itemCode = notFound.itemCode;
        notFound.prices.forEach(function(price) {
            let newProductPrice = new ProductPrice();
            newProductPrice.materialCost = price.materialCost;
            newProductPrice.purchasePrice = price.purchasePrice;
            newProductPrice.optionName = price.optionName;
            newProductPrice.optionCode = price.optionCode;
            newProduct.productPrices.push(newProductPrice);
        })
        newProduct.productGroupId = notFound.productGroupId;
        newProduct.name = notFound.name;
        newProduct.supplierId = notFound.supplierId;
        this.notFoundItems[row].visible = false;
        this._apiService.setActionUrl('products/products');
        this._apiService.saveRecord(newProduct).subscribe(response => {
            if (response.success) {
                this.notFoundItems.splice(row, 1);
            } else {
                this._auth.addError(response.errorMsg);
                this.notFoundItems[row].visible = true;
            }
        });
    }

    removeProduct(event, notFound, row) {
        this.notFoundItems[row].visible = false;
        this.notFoundItems.splice(row, 1);
    }

    selectPriceType(event) {
        this.selectedPriceType = event.data;
    }


}
