<div id="moduleMenu">
    <span *ngIf="selectedItem" class="itemName floatLeft">{{ selectedItem }}</span>
    <span id="masterdataMenuTitle" class="floatRight">{{ 'products.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button *ngIf="_showTypes || _showProducts"
                class="pButton floatLeft" type="button" pButton icon="fa fa-refresh" (click)="loadTypes()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="_auth.modules['products'].authLevel >= 20 && (_showTypes || _showProducts)"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createType()" label="{{ 'base.create' | translate }}"></button>
        <button *ngIf="_showTypes || _showProducts"
                class="pButton floatLeft" type="button" pButton icon="fa fa-refresh" (click)="switchVersion()" label="{{ 'base.switchVersion' | translate }} {{ uisVersion }}"></button>
        <button *ngIf="_auth.modules['products'].authLevel >= 20 && (_showTypes || _showProducts) && uisVersion > 2"
                class="pButton floatLeft" type="button" pButton icon="fa fa-upload" (click)="importCSV()" label="{{ 'products.importPriceList' | translate }}"></button>
        <button *ngIf="_auth.modules['products'].authLevel >= 20 && (_showTypes || _showProducts) && uisVersion > 2"
                class="pButton floatLeft" type="button" pButton icon="fa fa-list" (click)="showImports()" label="{{ 'products.importedPriceLists' | translate }}"></button>
    </div>
    <div class="ui-g module">
        <div class="ui-g-12 ui-md-12">
            <p-table #typesTable tableStyleClass="djc-table" [styleClass]="'table'" [loading]="typesLoading" [value]="productTypes" [rows]="_auth.defaultRows"
                     [paginator]="false" [pageLinks]="5" selectionMode="single" [(selection)]="selectedProductType" (onRowUnselect)="cancelUnselectPT($event)"
                     (onRowSelect)="selectProductType()" [scrollable]="true" [scrollHeight]="'flex'" [(contextMenuSelection)]="selectedProductType"
                     [contextMenu]="cmMenu" editMode="row" dataKey="id" [sortField]="'code'" [sortOrder]="1" (onContextMenuSelect)="selectProductType()">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'code'" style="width:15em">Code
                            <p-sortIcon [field]="'code'"></p-sortIcon>
                        </th>
                        <th>{{ 'base.strDescription' | translate }}</th>
                        <th *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData" [pContextMenuRow]="rowData" [pSelectableRow]="rowData" [ngStyle]="_st.getRowStyle(rowData.id, selectedProductType.id, ri, editing)">
                        <td style="width:15em">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.code" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.code}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.description" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.description}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em; text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="typeEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="typeEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="typeEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>
                    <tr *ngIf="_showProcesses && selectedProductType.id == rowData.id">
                        <td colspan="2">
                            <p-table #processTable [value]="processesDD" tableStyleClass="djc-table">
                                <ng-template pTemplate="header">
                                    <tr>
                    <th style="width: 3em;"></th>
                    <th>{{ 'base.strDescription' | translate }}</th>
                </tr>
                                </ng-template>
                <ng-template pTemplate="body" let-processRow>
                    <tr>
                        <td style="width: 3em;">
                            <p-checkbox (onChange)="updateProcess($event, processRow)" [binary]="true" [ngModel]="getProcessChecked(processRow)"></p-checkbox>
                        </td>
                        <td>{{ processRow.label }}</td>
                    </tr>
                </ng-template>
                            </p-table>
                        </td>
                    </tr>
                    <tr *ngIf="_showGroups && selectedProductType.id == rowData.id">
                        <td colspan="2">
                            <p-table #groupsTable [value]="productGroups" tableStyleClass="djc-table" [loading]="groupsLoading" selectionMode="single"
                                     [(selection)]="selectedProductGroup" (onRowUnselect)="cancelUnselectPG($event)" (onContextMenuSelect)="selectProductGroup()"
                                     (onRowSelect)="selectProductGroup()" [(contextMenuSelection)]="selectedProductGroup" [contextMenu]="cmGroupMenu"
                                     editMode="row" dataKey="id" [sortField]="'description'" sortOrder="1">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>{{ 'base.strDescription' | translate }}</th>
                                        <th>{{ 'products.alert' | translate }}</th>
                                        <th *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em;"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-groupRow let-editGroup="editing" let-groupRi="rowIndex">
                                    <tr [pEditableRow]="groupRow" [pContextMenuRow]="groupRow" [ngStyle]="_st.getRowStyle(groupRow.id, selectedProductGroup.id, groupRi, editGroup)">
                                        <td>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="groupRow.description" required>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{groupRow.description}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-checkbox [(ngModel)]="groupRow.popupAlert" [binary]="true"></p-checkbox>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-checkbox disabled [(ngModel)]="groupRow.popupAlert" [binary]="true"></p-checkbox>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em; text-align:center">
                                            <button *ngIf="!editGroup" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="groupEditInit(groupRow)"></button>
                                            <button *ngIf="editGroup" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                                    (click)="groupEditSave(groupRow)"></button>
                                            <button *ngIf="editGroup" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                                    (click)="groupEditCancel(groupRow, groupRi)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-contextMenu #cmMenu [model]="_contextMenu"></p-contextMenu>
            <p-contextMenu #cmGroupMenu [model]="_contextMenuGroup"></p-contextMenu>
            <div *ngIf="_showImports">
                <p-dialog [header]="'Ingelezen prijslijsten'" [(visible)]="_showImports" (onHide)="_showImports = false" positionTop="250" styleClass="ui-g-9 pDialog"
                          [modal]="true">
                    <productimports></productimports>
                </p-dialog>
            </div>
            <div *ngIf="_showProducts">
                <p-dialog [header]="selectedProductGroup.productType.code" [(visible)]="_showProducts" (onHide)="cancelProductListDialog()" positionTop="250"
                      styleClass="ui-g-9 pDialog" [modal]="true">
                    <div class="moduleItemMenu">
                        <button *ngIf="_showTypes || _showProducts"
                                class="pButton floatLeft" type="button" pButton icon="fa fa-refresh" (click)="loadProducts()" label="{{ 'base.refresh' | translate }}"></button>
                        <button *ngIf="_auth.modules['products'].authLevel >= 20 && (_showTypes || _showProducts)"
                                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createProduct()" label="{{ 'base.create' | translate }}"></button>
                        <button *ngIf="_auth.modules['products'].authLevel >= 20 && (_showProducts)"
                                class="pButton" type="button" pButton icon="fa fa-check-square" (click)="toggleAvailable()" label="{{ (available === '1') ? 'Beschikbaar' : 'Niet beschikbaar' }}"></button>
                    </div>
                    <p-table #productTable tableStyleClass="djc-table" [value]="products" [rows]="15" [paginator]="true" [pageLinks]="5" [sortField]="'name'"
                             [sortOrder]="1" selectionMode="single" [(selection)]="selectedProduct" editMode="row" dataKey="id" (onRowSelect)="selectProduct()"
                             [contextMenu]="cmProducts" [(contextMenuSelection)]="selectedProduct" (onRowUnselect)="cancelUnselectProduct($event)">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [pSortableColumn]="'name'">{{ 'base.strName' | translate }}
                                    <p-sortIcon [field]="'name'"></p-sortIcon>
                                </th>
                                <th>Code</th>
                                <th>{{ 'base.supplier' | translate }}</th>
                                <th>Prijs</th>
                                <th *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em;"></th>
                            </tr>
                            <tr>
                                <th><input pInputText type="text" (input)="productTable.filter($event.target.value, 'name', 'startsWith')"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em;"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                            <tr [pEditableRow]="rowData"  [pContextMenuRow]="rowData" [pSelectableRow]="rowData" [ngStyle]="_st.getRowStyle(rowData.id, selectedProduct.id, ri, editing)">
                                <td>{{rowData.name}}</td>
                                <td>{{rowData.itemCode}}</td>
                                <td>{{rowData.supplier.name}}</td>
                                <td><p-table tableStyleClass="djc-table" [value]="rowData.productPrices" [sortField]="'optionName'" [sortOrder]="1">
                                    <ng-template pTemplate="body" let-priceData let-priceri="rowIndex">
                                        <tr>
                                            <td>{{priceData.optionName}}</td>
                                            <td><span class="price right">{{ priceData.materialCost |currency :'EUR':'symbol':'1.3-3':'nl' }}</span></td>
                                        </tr>
                                    </ng-template>
                                </p-table></td>
                                <td *ngIf="_auth.modules['products'].authLevel >= 20">
                                    <button pButton type="button" icon="fa fa-pencil" class="ui-button-info" (click)="editProduct(rowData)"></button>
                                </td>
                            </tr>
                            <tr *ngIf="_showProductProcesses && selectedProduct.id == rowData.id">
                                <td colspan="4">
                                    <p-table tableStyleClass="djc-table" [value]="productProcesses" [sortField]="'process.description'" [sortOrder]="1" editMode="row"
                                             dataKey="id">
                                        <ng-template pTemplate="header">
                                            <tr>
                            <td style="width: 60em;">{{ 'masterdata.process' | translate }}</td>
                            <td style="width: 10em;">{{ 'orders.construct' | translate }}</td>
                            <td style="width: 10em;">{{ 'orders.renovation' | translate }}</td>
                            <td *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em; text-align:center"></td>
                        </tr>
                                        </ng-template>
                        <ng-template pTemplate="body" let-processData let-editProcess="editing" let-processRow="rowIndex">
                            <tr [pEditableRow]="processData" [ngStyle]="_st.getRowStyle(processData.id, selectedProduct.id, processRow, editProcess)">
                                <td>{{ processData.process.description }}</td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <djc-spinner min="0" step="0.1" [(ngModel)]="processData.piecesPerHourConstruct" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                         [decimalSeparator]="_auth.getUserSettings().decimalSeparator" ></djc-spinner>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ processData.piecesPerHourConstruct }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <djc-spinner min="0" step="0.1" [(ngModel)]="processData.piecesPerHourRenovation" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                         [decimalSeparator]="_auth.getUserSettings().decimalSeparator" ></djc-spinner>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ processData.piecesPerHourRenovation }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td *ngIf="_auth.modules['products'].authLevel >= 20" style="width:8em; text-align:center">
                                    <button *ngIf="!editProcess" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="processEditInit(processData)"></button>
                                    <button *ngIf="editProcess" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                            (click)="processEditSave(processData)"></button>
                                    <button *ngIf="editProcess" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                            (click)="processEditCancel(processData, processRow)"></button>
                                </td>
                            </tr>
                        </ng-template>
                                    </p-table>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-contextMenu #cmProducts [model]="_contextMenuProduct" appendTo="body"></p-contextMenu>
                </p-dialog>
            </div>
            <p-dialog [header]="selectedProduct.itemCode + ' - ' + selectedProduct.name" [(visible)]="_showProductDialog" (onHide)="cancelProductDialog()"
                      [modal]="true" [responsive]="true" styleClass="ui-g-9 pDialog">
                <form [formGroup]="_productForm" novalidate (ngSubmit)="preSaveProductForm()">
                    <div class="ui-g">
                        <div class="ui-g-3"><label for="prodItemCode">{{ 'products.itemCode' | translate }}</label></div>
                        <div class="ui-g-9"><input pInputText id="prodItemCode" formControlName="itemCode"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3"><label for="prodName">{{ 'base.strName' | translate }}</label></div>
                        <div class="ui-g-9"><input pInputText id="prodName" formControlName="name"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">{{ 'base.strDescription' | translate }}</div>
                        <div class="ui-g-9"><textarea pInputTextarea formControlName="description" cols="150" rows="3" autoResize="true"></textarea></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3"><label for="prodSupplier">{{ 'products.supplier' | translate }}</label></div>
                        <div class="ui-g-9"><p-dropdown id="prodSupplier" [options]="_supplierDD" formControlName="supplierId"
                                                        filter="true" virtualScroll="true" [itemSize]="30" appendTo="body"></p-dropdown></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3"><label for="available">{{ 'products.available' | translate }}</label></div>
                        <div class="ui-g-9"><p-checkbox [disabled]="selectedProduct.hasParentProduct" id="available" formControlName="available" binary="true" (onChange)="selectedProduct.available = $event.checked"></p-checkbox></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3"><label for="hasParentProduct">{{ 'products.hasParentProduct' | translate }}</label></div>
                        <div class="ui-g-9"><p-checkbox id="hasParentProduct" formControlName="hasParentProduct" binary="true" (onChange)="selectedProduct.hasParentProduct = $event.checked"></p-checkbox></div>
                    </div>
                    <div class="ui-g" *ngIf="selectedProduct.hasParentProduct">
                        <div class="ui-g-3"><label for="parentProduct">{{ 'products.parentProduct' | translate }}</label></div>
                        <div class="ui-g-9">
                            <p-dropdown id="parentProduct" [options]="_productsService._productsDD" formControlName="parentProductId"
                                        filter="true" virtualScroll="true" [itemSize]="30" appendTo="body" (onChange)="selectParentProduct($event)"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g" *ngIf="selectedProduct.hasParentProduct">
                        <div class="ui-g-3"><label for="prodMaterialCost">{{ 'products.materialFactor' | translate }}</label></div>
                        <div class="ui-g-9">
                            <div class="ui-g-9">
                                <p-spinner formControlName="calculationFactor" thousandSeparator="." decimalSeparator="," [formatInput]="true"
                                           [step]="0.01" id="calculationFactor"></p-spinner>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="ui-g">
                    <div><b>{{ 'products.priceTypes' | translate }}</b></div>
                    <div *ngIf="selectedProduct.productPrices !== undefined && selectedProduct.productPrices.length > 0 && !selectedProduct.hasParentProduct">
                        <p-table tableStyleClass="djc-table" [value]="selectedProduct.productPrices" [scrollable]="false" selectionMode="single" dataKey="optionName">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 20em">{{ 'products.optionName' | translate }}</th>
                                    <th style="width: 10em">Code</th>
                                    <th style="width: 15em">{{ 'products.materialCost' | translate }}</th>
                                    <th style="width: 15em">{{ 'products.purchasePrice' | translate }}</th>
                                    <th style="width: 5em"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-productPrice let-editing="editing" let-ri="rowIndex">
                                <tr [pContextMenuRow]="productPrice" [pSelectableRow]="productPrice">
                                    <td style="width: 60em"><input pInputText [(ngModel)]="productPrice.optionName" (blur)="addPriceLine($event, productPrice)"
                                                                   (focus)="productPrice.oldOptionName = productPrice.optionName" (ngModelChange)="productPrice.optionCode = ''"/></td>
                                    <td style="width: 60em"><input pInputText [(ngModel)]="productPrice.optionCode" (ngModelChange)="checkValidity(productPrice)"/></td>
                                    <td style="width: 60em"><p-spinner [(ngModel)]="productPrice.materialCost" thousandSeparator="." decimalSeparator=","
                                                                       [formatInput]="true" [step]="0.01" id="prodMaterialCost" (ngModelChange)="checkValidity(productPrice)"></p-spinner></td>
                                    <td style="width: 60em"><p-spinner [(ngModel)]="productPrice.purchasePrice" thousandSeparator="." decimalSeparator=","
                                                                       [formatInput]="true" [step]="0.01" id="prodPurchasePrice"></p-spinner></td>
                                    <td><button *ngIf="productPrice.id !== '' || productPrice.id !== undefined" pButton type="button" icon="fa fa-times"
                                                class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                                (click)="deleteProductPrice(productPrice.id, ri)"></button></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div *ngIf="selectedProduct.productPrices.length > 0 && selectedProduct.hasParentProduct">
                        <p-table tableStyleClass="djc-table" [value]="selectedProduct.productPrices" [scrollable]="false" selectionMode="single" dataKey="optionName">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 20em">{{ 'products.optionName' | translate }}</th>
                                    <th style="width: 10em">Code</th>
                                    <th style="width: 15em">{{ 'products.materialCost' | translate }}</th>
                                    <th style="width: 15em">{{ 'products.purchasePrice' | translate }}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-productPrice let-editing="editing" let-ri="rowIndex">
                                <tr [pContextMenuRow]="productPrice" [pSelectableRow]="productPrice">
                                    <td style="width: 60em"><input pInputText [(ngModel)]="productPrice.optionName" disabled="disabled"/></td>
                                    <td style="width: 60em"><input pInputText [(ngModel)]="productPrice.optionCode" disabled="disabled"/></td>
                                    <td style="width: 60em"><p-spinner [(ngModel)]="productPrice.materialCost" thousandSeparator="." decimalSeparator="," [formatInput]="true"
                                                                       [step]="0.01" id="prodMaterialCostRO" disabled="disabled"></p-spinner></td>
                                    <td style="width: 60em"><p-spinner [(ngModel)]="productPrice.purchasePrice" thousandSeparator="." decimalSeparator="," [formatInput]="true"
                                                                       [step]="0.01" id="prodPurchasePriceRO" disabled="disabled"></p-spinner></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <p-footer>
                    <button class="pButton" type="button" pButton icon="fa fa-check" (click)="preSaveProductForm()"
                            label="{{ 'base.save' | translate }}" [disabled]="(!(_productForm.valid) || disableSaveButton)"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancelProductDialog()"
                            label="{{ 'base.cancel' | translate }}" [disabled]="disableSaveButton"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-question-circle" (click)="_st.getFormValidationErrors(_productForm)"
                            label="Controle" [hidden]="(_productForm.valid)"></button>
                </p-footer>
            </p-dialog>
            <csvImport [(visible)]="_showImport"></csvImport>
            <ng-keyboard-shortcuts [shortcuts]="kbShortCuts"></ng-keyboard-shortcuts>
            <ng-keyboard-shortcuts-help [key]="'f1'" [closeKey]="'esc'" [title]="'Help'"></ng-keyboard-shortcuts-help>
        </div>
    </div>
    <div *ngIf="_showProductGroupChange">
        <p-dialog [header]="selectedProduct.itemCode + ' - ' + selectedProduct.name" [(visible)]="_showProductGroupChange" (onHide)="cancelProductGroupChangeDialog()"
                  [modal]="true" [responsive]="true" styleClass="ui-g-9 pDialog">
            <div class="ui-g">
                <div class="ui-g-3">Huidige productgroep</div>
                <div class="ui-g-9">{{ selectedProduct.productGroup.description }}</div>
            </div>
            <div class="ui-g">
                <div class="ui-g-3">Nieuwe productgroep</div>
                <div class="ui-g-9"><p-dropdown [options]="selectProductGroups" [(ngModel)]="selectedProduct.productGroupId" [appendTo]="'body'"></p-dropdown></div>
            </div>
            <p-footer>
                <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveProduct(true)"
                        label="{{ 'base.save' | translate }}"></button>
                <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancelProductGroupChangeDialog()"
                        label="{{ 'base.cancel' | translate }}"></button>
            </p-footer>
        </p-dialog>
    </div>
</div>
