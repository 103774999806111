import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {SelectItem} from "primeng/api";
import {AuthService} from "../auth.service";
import {Product, ProductGroup} from "./products.model";

@Injectable()
export class ProductsService {

    public _productsDD: SelectItem[] = [];
    private _oldProductsDD: SelectItem[] = [];
    public _products: Product[] = [];
    private _oldProducts: Product[] = [];
    private _productGroups: ProductGroup[] = [];
    private _oldProductGroups: ProductGroup[] = [];
    private _noProductGroup: any = [];
    private _loaded: boolean = false;
    private _loading: boolean = false;

    constructor(private _apiService: ApiService, private _auth: AuthService) {

    }

    public getProductsDD(uisVersion = 3) {
        if (this._loaded) {
            if (uisVersion >= 3) {
                return this._productsDD;
            } else {
                return this._oldProductsDD;
            }
        }
        return [{value: '', label: 'Loading....'}];
    }

    public getProducts(uisVersion = 3) {
        if (this._loaded) {
            if (uisVersion >= 3) {
                return this._products;
            } else {
                return this._oldProducts;
            }
        }
        return [];
    }

    public loadProductGroups(loadProducts: boolean = false) {
        this._apiService.setActionUrl('products/groups');
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                response.data.records.forEach(function (productGroup: ProductGroup) {
                    if (productGroup.productType.uisVersion >= 3) {
                        this._productGroups.push(productGroup);
                    } else {
                        this._oldProductGroups.push(productGroup);
                    }
                }, this);
                this._auth.highestModObject['prodService_groups'] = {modSeq: response.data.highModSeq, function: 'loadProductGroups', url: 'products/groups', functionScope: this};
                if (loadProducts)
                    this.loadProducts();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    public loadProducts(offset: number = 0, useSelectValue: string = "Y") {
        this._loading = true;
        let limit: number = 500;
        this._apiService.setActionUrl('products/products');
        this._apiService.setFilter({field: 'available', operator: 'eq', value: '1'});
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.setLimit({records: limit, offset: offset});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                if (useSelectValue == 'Y') {
                    this._productsDD.push({value: '', label: 'Maak een keuze'});
                    this._oldProductsDD.push({value: '', label: 'Maak een keuze'});
                }
                if (response.data.records.length > 0) {
                    this.extractProducts(response.data.records);
                }
                if (limit <= response.data.records.length) {
                    this.loadProducts(offset + limit, 'N');
                } else {
                    if (this._noProductGroup.length > 0) {
                        let noPGRecords = { ... this._noProductGroup};
                        this._noProductGroup = [];
                        this.extractProducts(noPGRecords);
                    }
                    this._loaded = true;
                    this._loading = false;
                    this._auth.highestModObject['prodService_products'] = {modSeq: response.data.highModSeq, function: 'loadProducts', url: 'products/products', functionScope: this};
                }
            }
        });
    }

    private extractProducts(records) {
        records.forEach(function (record: Product) {
            if (record.productType.uisVersion >= 3) {
                let pg = this._productGroups.find(pg => {
                    if (pg.id === record.productGroupId) {
                        return pg;
                    }
                });
                if (typeof pg === 'undefined') {
                    this._noProductGroup.push(record);
                } else {
                    record.productGroup = pg;
                    this._productsDD.push({value: record.id, label: record.name });
                    this._products.push(record);
                }
            } else {
                let pg = this._oldProductGroups.find(pg => {
                    if (pg.id === record.productGroupId) {
                        return pg;
                    }
                });
                if (typeof pg === 'undefined') {
                    this._noProductGroup.push(record);
                } else {
                    record.productGroup = pg;
                    this._oldProductsDD.push({value: record.id, label: record.name });
                    this._oldProducts.push(record);
                }
            }
        }, this);
    }

    public isLoaded() {
        return this._loaded;
    }

    public isLoading() {
        return this._loading;
    }

    public updateProduct(product: Product): void {
        let curProductIndex = this._products.findIndex(x => x.id === product.id);
        if (curProductIndex !== -1) {
            this._products[curProductIndex] = product;
        } else {
            this._products.push(product);
        }

        let curProductDDIndex = this._productsDD.findIndex(x => x.value === product.id);
        if (curProductDDIndex !== -1) {
            this._productsDD[curProductDDIndex] = {value: product.id, label: product.name};
        } else {
            this._productsDD.push({value: product.id, label: product.name});
        }
    }

}
