<p-dialog [(visible)]="_visible" header="{{ 'products.importPriceList' | translate }}" (onHide)="cancelCSV()">
    <div *ngIf="csvImport">
        <div class="ui-g">
            <div class="ui-g-4"><label for="supplier">{{ 'base.supplier' | translate }}</label></div>
            <div class="ui-g-8"><p-dropdown id=supplier [options]="suppliersDD" [(ngModel)]="import.supplierId" appendTo="body" [filter]="true"></p-dropdown></div>
            <div class="ui-g-4" *ngIf="sheets.length > 1"><label for="sheet">{{ 'products.sheet' | translate }}</label></div>
            <div class="ui-g-8" *ngIf="sheets.length > 1"><p-dropdown id="sheet" [options]="sheets" [(ngModel)]="import.sheetName"
                                                                      (onChange)="changeSheet($event)" appendTo="body" [filter]="true"></p-dropdown></div>
            <div class="ui-g-4" *ngIf="headerFields.length === 0"><label for="file">{{ 'base.file' | translate }}</label></div>
            <div class="ui-g-8" *ngIf="headerFields.length === 0"><p-fileUpload id="file" name="file" accept=".csv,.xls,.xlsx" multiple="false"
                                                                                showUploadButton="true" showCancelButton="true" url="api/products/import/import"
                                                                                (onBeforeUpload)="beforeUpload($event)" (onUpload)="afterUpload($event)"></p-fileUpload></div>
        </div>
        <div *ngIf="headerFields.length > 0">
            <div *ngFor="let connectedField of connectedFields" >
                <div class="ui-g">
                    <div class="ui-g-4">{{ connectedField.displayName }}</div>
                    <div class="ui-g-8"><p-dropdown [options]="headerFields" [(ngModel)]="connectedField.column" appendTo="body"></p-dropdown></div>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-4">{{ 'products.productType' | translate }}</div>
                <div class="ui-g-8"><p-dropdown [options]="productTypes" [(ngModel)]="defaultProductType" appendTo="body"></p-dropdown></div>
            </div>
        </div>
        <div>{{ 'products.priceTypes' | translate }}</div>
        <div *ngIf="connectedPriceFields.length > 1">
            <p-table tableStyleClass="djc-table" [value]="priceTypes" [scrollable]="false" selectionMode="single" dataKey="optionName" (onRowSelect)="selectPriceType($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'products.optionName' | translate }}</th>
                        <th>Code</th>
                        <th>{{ connectedPriceFields[0].displayName }}</th>
                        <th>{{ connectedPriceFields[1].displayName }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-priceType let-editing="editing" let-ri="rowIndex">
                    <tr [pContextMenuRow]="priceType" [pSelectableRow]="priceType" [ngStyle]="_st.getRowStyle(priceType.optionName, selectedPriceType.optionName, ri)">
                        <td><input pInputText [(ngModel)]="priceType.optionName" (ngModelChange)="priceType.optionCode = '' "/></td>
                        <td><input pInputText [(ngModel)]="priceType.optionCode" /></td>
                        <td><p-dropdown [options]="headerFields" [(ngModel)]="priceType.materialColumn" appendTo="body" (onChange)="addPriceType($event, ri)"></p-dropdown></td>
                        <td><p-dropdown [options]="headerFields" [(ngModel)]="priceType.purchaseColumn" appendTo="body"></p-dropdown></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf="headerFields.length > 0">
            <p-button (onClick)="importFile()" label="Importeer" [disabled]="checkColumns()"></p-button>
        </div>
    </div>
    <div *ngIf="!csvImport">
        <p-table tableStyleClass="djc-table" [value]="notFoundItems" [rows]="25" [paginator]="true" editMode="row" dataKey="itemCode">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 20em;">Artikelcode</th>
                    <th style="width: 30em;">Korte omschrijving</th>
                    <th>ProductType</th>
                    <th>ProductGroep</th>
                    <th rowspan="2" style="width: 3em;"><span class="fa fa-save"></span></th>
                    <th rowspan="2" style="width: 3em;"><span class="fa fa-trash"></span></th>
                </tr>
                <tr>
                    <th colspan="4">Offertetekst</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-notFoundItem let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="notFoundItem" [pSelectableRow]="notFoundItem" [ngStyle]="{'display':  notFoundItem.visible ? '' : 'none'}">
                    <td style="width: 20em;">{{ notFoundItem.itemCode }}</td>
                    <td style="width: 30em;">{{ notFoundItem.name }}</td>
                    <td><p-dropdown [options]="productTypes" (onChange)="selectProductType($event)" [filter]="true" [(ngModel)]="notFoundItem.productTypeId"></p-dropdown></td>
                    <td><p-dropdown [options]="productGroups" [filter]="true" [(ngModel)]="notFoundItem.productGroupId" [disabled]="productGroups.length == 0"></p-dropdown></td>
                    <td rowspan="2" style="width: 3em;"><p-checkbox [(ngModel)]="notFoundItem.import" (onChange)="saveProduct($event, notFoundItem, ri)" [disabled]="enableSaveProduct(notFoundItem)"></p-checkbox></td>
                    <td rowspan="2" style="width: 3em;"><p-checkbox (onChange)="removeProduct($event, notFoundItem, ri)" [disabled]="!enableSaveProduct(notFoundItem)"></p-checkbox></td>
                </tr>
                <tr [ngStyle]="{'display':  notFoundItem.visible ? '' : 'none'}">
                    <td colspan="4"><textarea pInputTextarea [(ngModel)]="notFoundItem.description" cols="150" rows="3" autoResize="true"></textarea></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
