import {HttpHeaders} from "@angular/common/http";
import {Injectable, Component, Input, NgModule, forwardRef, Directive, OnInit, ElementRef, HostListener, ChangeDetectionStrategy} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormControl, FormGroup, ValidatorFn, ValidationErrors, FormsModule, Validators, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Table, TableModule} from "primeng/table";
import {TranslateModule} from "@ngx-translate/core";
import {RadioButtonModule} from 'primeng/radiobutton';
import {ButtonModule} from "primeng/button";
import {trigger,state,style,transition,animate} from '@angular/animations';
import { Spinner } from 'primeng/spinner';
import { AutoComplete } from 'primeng/autocomplete';
import { DomHandler } from "primeng/dom";
import { SelectItem } from 'primeng/api';


export const contentHeaders = new HttpHeaders();
contentHeaders.append('Accept', 'application/json');
contentHeaders.append('Content-Type', 'application/json');

@Injectable()
export class SupportTools {
    public cloneRecord(oldRecord, newRecord) {
        for (let prop in oldRecord) {
            newRecord[prop] = oldRecord[prop];
        }
        return newRecord;
    }

    public ucFirst(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    public generateRandomString(length: number): string {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*()";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    public equalValueValidator(targetKey: string, toMatchKey: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            const target = group.controls[targetKey];
            const toMatch = group.controls[toMatchKey];
            if (target.touched && toMatch.touched) {
                const isMatch = target.value === toMatch.value;
                // set equal value error on dirty controls
                if (!isMatch && target.valid && toMatch.valid) {
                    toMatch.setErrors({equalValue: targetKey});
                    const message = targetKey + ' != ' + toMatchKey;
                    return {'equalValue': message};
                }
                if (isMatch && toMatch.hasError('equalValue')) {
                    toMatch.setErrors(null);
                }
            }

            return null;
        };
    }

    public hundredPercentValidator(fields: any): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            let total: number = 0;
            fields.forEach(field => {
                const calcField = group.controls[field];
                calcField.setErrors(null);
                total = total + calcField.value;
            });
            if (total != 100) {
                fields.forEach(field => {
                    const calcField = group.controls[field];
                    calcField.setErrors({hundredPercent: total});
                })
                return {'hundredPercent': total };
            }
            return null;
        }
    }

    public checkPassword(field: FormControl) {
        let passwordPattern = new RegExp("^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$");
        if (field.value == null || field.value.length === 0 || field.value.length >= 8 || passwordPattern.test(field.value)) {
            return null;
        } else {
            return {
                checkPassword: {
                    valid: false
                }
            }
        }
    }

    public secondMailfieldValidator(field: FormControl) {
        if (field.value == null || field.value === '') {
            return null;
        }
        return Validators.email(field);
    }

    public getFormValidationErrors(form: FormGroup) {
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    async sleep(milliSeconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliSeconds));
    }

    public getDateColor(date: number, red: number, orange: number = 0): string {
        let secondsRed = red * (24 * 60 * 60);
        let color = 'black';
        let nowSeconds = Math.floor(new Date().getTime() / 1000);
        if (orange > 0) {
            let secondsOrange = orange * (24 * 60 * 60);
            if (date + secondsOrange < nowSeconds) {
                color = 'orange';
            }
        }
        if (date + secondsRed < nowSeconds) {
            color = 'red';
        }
        return color;
    }

    public priceFormat(price: string) {
        let first = price.substr(0, 1);
        let last = price.substr(1);
        return first + ' ' + last;
    }

    public getRowStyle(rowId: string, selectedId: string, row: number, editing: boolean = false) {
        if (editing) {
            return {'background-color' : 'blue', 'color': 'black'};
        }
        if (rowId == selectedId) {
            return {'background-color': 'green', 'color': 'white'};
        } else {
            if (row % 2) {
                return {'background-color': 'white', 'color': 'black'};
            } else {
                return {'background-color': 'lightgrey', 'color': 'black'};
            }
        }
    }

    public toDataUrl(url, callback, scope) {
        var canvas = <HTMLCanvasElement>document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'),
            img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var dataURL;
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL();
            callback(scope, dataURL);
            canvas = null;
        };
        img.src = url;
    }

    public filterAutoComplete(event, options: SelectItem[]) {
        let filtered = [];
        options.forEach(function(option) {
            if (option.label.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                filtered.push(option);
            }
        });
        return filtered;
    }

    private _finishToCopy: string = '';

    public copyFinish(finishId: string) {
        this._finishToCopy = finishId;
    }

    public mergeFinish() {
        return this._finishToCopy;
    }

    findNextCell(cell) {
        while (cell && !DomHandler.hasClass(cell, 'p-editing-cell')) {
            cell = cell.parentElement;
        }

        if (!cell) {
            return null
        }

        let row = cell.parentElement;
        let nextCell = cell.nextElementSibling;
        if (!nextCell) {
            nextCell = row.firstElementChild;
        }
        while (nextCell && !DomHandler.hasClass(nextCell, 'p-editable-column')) {
            cell = nextCell;
            nextCell = cell.nextElementSibling;
            if (!nextCell) {
                row = cell.parentElement.nextElementSibling;
                nextCell = row.firstElementChild;
            }
        }

        return nextCell;

    }

    openAssistant(uri: string) {

        var myWindow = window.open('', '', 'width=0,height=0');

        myWindow.document.write("<iframe src='" + uri + "'></iframe>");

        setTimeout(function () {
            try {
                myWindow.location.href;
                myWindow.setTimeout("window.close()", 10000);
            } catch (e) {
                myWindow.close();
            }
        }, 1000);
    }

    selectInputContent(event) {
        event.target.select();
    }

    getTextItems(): SelectItem[] {
        let textItems: SelectItem[] = [];
        textItems.push({value: 1, label: 'Standaard tekst 1'});
        textItems.push({value: 2, label: 'Standaard tekst 2'});
        textItems.push({value: 3, label: 'Standaard tekst 3'});
        textItems.push({value: 10, label: 'Preventief onderhoud'});

        return textItems;
    }

}

@Component({
    selector: 'djc-date',
    template: '{{ (timeInput * 1000) | date: format}}'
})
export class DateTemplate {
    @Input() timeInput: number = 0;

    @Input() format: string = 'dd-MM';

    constructor() {

    }

}

export const DJC_SPINNER_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DjcSpinner),
    multi: true
};

@Component({
    selector: 'djc-spinner',
    template: `
        <span class="ui-spinner ui-widget ui-corner-all">
            <input #inputfield type="ẗext" [attr.id]="inputId" [value]="formattedValue" [attr.name]="name"
                   [attr.size]="size" [attr.maxlength]="maxlength" [attr.tabindex]="tabindex" [attr.placeholder]="placeholder" [disabled]="disabled"
                   [attr.readonly]="readonly" [attr.required]="required"
                   (keydown)="onInputKeydown($event)" (keyup)="onInputKeyup($event)"
                   (blur)="onInputBlur($event)" (change)="onInputChange($event)" (focus)="onInputFocus($event)"
                   [ngStyle]="inputStyle" [class]="inputStyleClass" [ngClass]="'ui-spinner-input ui-inputtext ui-widget ui-state-default ui-corner-all'">
            <button type="button" [ngClass]="{'ui-spinner-button ui-spinner-up ui-corner-tr ui-button ui-widget ui-state-default':true,'ui-state-disabled':disabled}" [disabled]="disabled" [attr.readonly]="readonly"
                (mouseleave)="onUpButtonMouseleave($event)" (mousedown)="onUpButtonMousedown($event)" (mouseup)="onUpButtonMouseup($event)">
                <span class="ui-spinner-button-icon fa fa-caret-up ui-clickable"></span>
            </button>
            <button type="button" [ngClass]="{'ui-spinner-button ui-spinner-down ui-corner-br ui-button ui-widget ui-state-default':true,'ui-state-disabled':disabled}" [disabled]="disabled" [attr.readonly]="readonly"
                (mouseleave)="onDownButtonMouseleave($event)" (mousedown)="onDownButtonMousedown($event)" (mouseup)="onDownButtonMouseup($event)">
                <span class="ui-spinner-button-icon fa fa-caret-down ui-clickable"></span>
            </button>
        </span>
    `,
    host: {
        '[class.ui-inputwrapper-filled]': 'filled',
        '[class.ui-inputwrapper-focus]': 'focus'
    },
    providers: [DJC_SPINNER_VALUE_ACCESSOR]
})
export class DjcSpinner extends Spinner {

    onInputKeyup(event: KeyboardEvent) {
        let inputValue = (<HTMLInputElement> event.target).value;
        if (event.key !== this.decimalSeparator && event.key !== this.thousandSeparator && event.key !== this.negativeSeparator) {
            this.value = this.parseValue(inputValue);
            this.formatValue();
        }
        this.onModelChange(this.value);
        this.updateFilledState();
    }

    onInputBlur(event) {
        this.focus = false;
        //this.restrictValue();
        this.formatValue();
        this.onModelTouched();
        this.onBlur.emit(event);
    }


}

export const DJC_AUTOCOMPLETE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DjcAutoComplete),
    multi: true
};

@Component({
    selector: 'djc-autoComplete',
    template: `
        <span [ngClass]="{'ui-autocomplete ui-widget':true,'ui-autocomplete-dd':dropdown,'ui-autocomplete-multiple':multiple}" [ngStyle]="style" [class]="styleClass">
            <input *ngIf="!multiple" #in [attr.type]="type" [attr.id]="inputId" [ngStyle]="inputStyle" [class]="inputStyleClass" [autocomplete]="autocomplete" [attr.required]="required" [attr.name]="name"
            [ngClass]="'ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input'" [value]="inputFieldValue" aria-autocomplete="list" [attr.aria-controls]="listId" role="searchbox" [attr.aria-expanded]="overlayVisible" aria-haspopup="true" [attr.aria-activedescendant]="'p-highlighted-option'"
            (click)="onInputClick($event)" (input)="onInput($event)" (keydown)="onKeydown($event)" (keyup)="onKeyup($event)" [attr.autofocus]="autofocus" (focus)="onInputFocus($event)" (blur)="onInputBlur($event)" (change)="onInputChange($event)" (paste)="onInputPaste($event)"
            [attr.placeholder]="placeholder" [attr.size]="size" [attr.maxlength]="maxlength" [attr.tabindex]="tabindex" [readonly]="readonly" [disabled]="disabled" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-required]="required"
            ><ul *ngIf="multiple" #multiContainer class="ui-autocomplete-multiple-container ui-widget ui-inputtext ui-state-default ui-corner-all" [ngClass]="{'ui-state-disabled':disabled,'ui-state-focus':focus}" (click)="multiIn.focus()">
                <li #token *ngFor="let val of value" class="ui-autocomplete-token ui-state-highlight ui-corner-all">
                    <span class="ui-autocomplete-token-icon pi pi-fw pi-times" (click)="removeItem(token)" *ngIf="!disabled"></span>
                    <span *ngIf="!selectedItemTemplate" class="ui-autocomplete-token-label">{{resolveFieldData(val)}}</span>
                    <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: val}"></ng-container>
                </li>
                <li class="ui-autocomplete-input-token">
                    <input #multiIn [attr.type]="type" [attr.id]="inputId" [disabled]="disabled" [attr.placeholder]="(value&&value.length ? null : placeholder)" [attr.tabindex]="tabindex" [attr.maxlength]="maxlength" (input)="onInput($event)"  (click)="onInputClick($event)"
                            (keydown)="onKeydown($event)" [readonly]="readonly" (keyup)="onKeyup($event)" [attr.autofocus]="autofocus" (focus)="onInputFocus($event)" (blur)="onInputBlur($event)" (change)="onInputChange($event)" (paste)="onInputPaste($event)" [autocomplete]="autocomplete"
                            [ngStyle]="inputStyle" [class]="inputStyleClass" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-required]="required"
                            aria-autocomplete="list" [attr.aria-controls]="listId" role="searchbox" [attr.aria-expanded]="overlayVisible" aria-haspopup="true" [attr.aria-activedescendant]="'p-highlighted-option'">
                </li>
            </ul>
            <i *ngIf="loading" class="ui-autocomplete-loader pi pi-spinner pi-spin"></i><button #ddBtn type="button" pButton [icon]="dropdownIcon" class="ui-autocomplete-dropdown" [disabled]="disabled"
                (click)="handleDropdownClick($event)" *ngIf="dropdown" [attr.tabindex]="tabindex"></button>
            <div #panel *ngIf="overlayVisible" [ngClass]="['ui-autocomplete-panel ui-widget ui-widget-content ui-corner-all ui-shadow']" [style.max-height]="scrollHeight" [ngStyle]="panelStyle" [class]="panelStyleClass"
                [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}" (@overlayAnimation.start)="onOverlayAnimationStart($event)" (@overlayAnimation.done)="onOverlayAnimationDone($event)" >
                <ul role="listbox" [attr.id]="listId" class="ui-autocomplete-items ui-autocomplete-list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
                    <li role="option"  *ngFor="let option of suggestions; let idx = index" [ngClass]="{'ui-autocomplete-list-item ui-corner-all':true,'ui-state-highlight':(highlightOption==option)}"
                        (mouseenter)="highlightOption=option" (mouseleave)="highlightOption=null" (mouseover)="mouseOver($event, option)" [id]="highlightOption == option ? 'p-highlighted-option':''" (click)="selectItem(option)">
                        <span *ngIf="!itemTemplate">{{resolveFieldData(option)}}</span>
                        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: option, index: idx}"></ng-container>
                    </li>
                    <li *ngIf="noResults && emptyMessage" class="ui-autocomplete-emptymessage ui-autocomplete-list-item ui-corner-all">{{emptyMessage}}</li>
                </ul>
            </div>
        </span>
    `,
    animations: [
        trigger('overlayAnimation', [
            state('void', style({
                transform: 'translateY(5%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('void => visible', animate('{{showTransitionParams}}')),
            transition('visible => void', animate('{{hideTransitionParams}}'))
        ])
    ],
    host: {
        '[class.ui-inputwrapper-filled]': 'filled',
        '[class.ui-inputwrapper-focus]': 'focus && !disabled'
    },
    providers: [DJC_AUTOCOMPLETE_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DjcAutoComplete extends AutoComplete {

    @Input() mouseOverField: string = '';

    mouseOver(event, option) {
        if (this.mouseOverField !== '') {
            event.srcElement.title = option[this.mouseOverField];
        }
    }

    onKeydown(event) {

        if (this.overlayVisible) {
            let highlightItemIndex = this.findOptionIndex(this.highlightOption);
            switch(event.which) {
                // pgDown
                case 34:
                    if (highlightItemIndex != -1) {
                        var nextItemIndex = highlightItemIndex + 10;
                        if (nextItemIndex >= this.suggestions.length) {
                            nextItemIndex = this.suggestions.length - 1;
                        }
                        if (nextItemIndex != (this.suggestions.length)) {
                            this.highlightOption = this.suggestions[nextItemIndex];
                            this.highlightOptionChanged = true;
                        }
                    }
                    else {
                        var nextItemIndex = 10;
                        if (nextItemIndex >= this.suggestions.length) {
                            nextItemIndex = this.suggestions.length - 1;
                        }
                        this.highlightOption = this.suggestions[nextItemIndex];
                    }

                    event.preventDefault();
                    break;
                //down
                case 40:
                    if (highlightItemIndex != -1) {
                        var nextItemIndex = highlightItemIndex + 1;
                        if (nextItemIndex != (this.suggestions.length)) {
                            this.highlightOption = this.suggestions[nextItemIndex];
                            this.highlightOptionChanged = true;
                        }
                    }
                    else {
                        this.highlightOption = this.suggestions[0];
                    }

                    event.preventDefault();
                    break;
                //pageUp
                case 33:
                    if (highlightItemIndex > 0) {
                        let prevItemIndex = highlightItemIndex - 10;
                        if (prevItemIndex < 0) {
                            prevItemIndex = 0;
                        }
                        this.highlightOption = this.suggestions[prevItemIndex];
                        this.highlightOptionChanged = true;
                    }

                    event.preventDefault();
                    break;
                //up
                case 38:
                    if (highlightItemIndex > 0) {
                        let prevItemIndex = highlightItemIndex - 1;
                        this.highlightOption = this.suggestions[prevItemIndex];
                        this.highlightOptionChanged = true;
                    }

                    event.preventDefault();
                    break;

                //enter
                case 13:
                    if (this.highlightOption) {
                        this.selectItem(this.highlightOption);
                        this.hide();
                    }
                    event.preventDefault();
                    break;

                //escape
                case 27:
                    this.hide();
                    event.preventDefault();
                    break;


                //tab
                case 9:
                    if (this.highlightOption) {
                        this.selectItem(this.highlightOption);
                    }
                    this.hide();
                    break;
            }
        } else {
            if (event.which === 40 && this.suggestions) {
                this.search(event,event.target.value);
            }
        }

        if (this.multiple) {
            switch(event.which) {
                //backspace
                case 8:
                    if (this.value && this.value.length && !this.multiInputEL.nativeElement.value) {
                        this.value = [...this.value];
                        const removedValue = this.value.pop();
                        this.onModelChange(this.value);
                        this.updateFilledState();
                        this.onUnselect.emit(removedValue);
                    }
                    break;
            }
        }

        this.inputKeyDown = true;
    }

}

@Directive({
    selector: '[DjcTableKeysDirective]'
})
export class DjcTableKeysDirective implements OnInit {

    constructor(private table: Table, private el: ElementRef<HTMLElement>) {}

    ngOnInit() {
        this.el.nativeElement.tabIndex = 1;
    }

    @HostListener('keydown.ArrowUp', ['$event']) ArrowUp(event: KeyboardEvent) {
        this.table.selection = this.navigateItem(-1);
        this.table.onRowSelect.emit({originalEvent: event, data: this.table.selection, type: 'row'});
        event.preventDefault();
    }

    @HostListener('keydown.ArrowDown', ['$event']) ArrowDown(event: KeyboardEvent) {
        let currentCell = this.table.editingCell;
        let row = currentCell.parentElement;
        this.table.selection = this.navigateItem(1);
        this.table.onRowSelect.emit({originalEvent: event, data: this.table.selection, type: 'row'});
        event.preventDefault();
    }

    navigateItem(num) {
        if (!this.table.selection) { return; }
        const i = this.table.value.indexOf(this.table.selection);
        const len = this.table.value.length;
        if (num > 0) {
            return this.table.value[(i + num) % len];
        }
        return this.table.value[(i + len + num) % len];
    }

}

@NgModule({
    declarations: [
        DateTemplate,
        DjcSpinner,
        DjcAutoComplete,
        DjcTableKeysDirective
    ],
    imports: [
        TranslateModule,
        RadioButtonModule,
        ButtonModule,
        FormsModule,
        CommonModule,
        TableModule
    ],
    exports: [
        DateTemplate,
        DjcSpinner,
        DjcAutoComplete,
        DjcTableKeysDirective
    ],
    providers: [
        SupportTools,
        DjcSpinner,
        DjcAutoComplete
    ]
})
export class ToolsModule {
}


