import {AfterViewInit, Component, OnInit} from "@angular/core";
import {Order, OrderCustomer, OrderPart} from "./orders.models";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, MenuItem, SelectItem} from "primeng/api";
import {ShortcutInput} from "ng-keyboard-shortcuts";
import {ProductsService} from "../products/products.service";

@Component({
    selector: 'orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, AfterViewInit {

    public orders: Order[] = [];
    public orderVersions: Order[] = [];
    public selectedOrder: Order = new Order();
    public selectedVersion: Order = new Order();
    public selectedOrderPart: OrderPart = new OrderPart();
    public selectedYear: number = new Date().getFullYear();
    public orderDirty: boolean = false;
    public years: SelectItem[] = [];
    public warranties: SelectItem[] = [];
    public paymentTerms: SelectItem[] = [];
    public newTotal: number = 0;

    public ordersLoading: boolean = false;
    public kbShortCuts: ShortcutInput[] = [];
    public numberOfOrderCustomers: number = 0;
    public maxSortOrder: string = '';

    private _contextMenuOrders: MenuItem[] = [];
    public _showOrderDialog: boolean = false;
    public _showVersion: boolean = false;
    public _showFinishes: boolean = false;
    public _showDocOptions: boolean = false;
    public _showNewContact: boolean = false;
    public _showOpenBudgetOptions: boolean = false;
    private _editOrder: boolean = false;
    public _orderForm: FormGroup;
    private _canEdit: boolean;
    private _canDelete: boolean;
    private _canAdd: boolean;
    private _canManage: boolean;
    public _employeeDropDown: SelectItem[] = [];
    public _customerDropDown: SelectItem[] = [];
    public _contactDropDown: SelectItem[] = [];
    public _defaultText01: SelectItem[] = [];
    public _defaultText02: SelectItem[] = [];
    public _defaultText03: SelectItem[] = [];
    public _maintenanceText: SelectItem[] = [];
    public _reloadOrderParts: boolean = false;
    public _includeVAT: boolean = false;
    public _subTotals: boolean = false;
    public _ocLoaded: boolean = false;
    public _addition: number = 0;
    public _openBudgetOptions: any = {additionMaterial: 0, additionLabour: 0, customerId: 0};
    public _orderCustomers: SelectItem[] = [];
    public _selectedCustomerId: string = '';
    public _orderCustomer: OrderCustomer = new OrderCustomer();
    public _orderSaving: boolean = false;
    public _newVersionInfo: any = {emptyOrder: false, updatePrices: true};
    public _showNewVersionInfo: boolean = false;
    public productTypes: SelectItem[] = [];
    public suppliers: SelectItem[] = [];
    public tabIndex: number = 0;

    constructor(public _auth: AuthService, public _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder, private _confirmationService: ConfirmationService, private _productsService: ProductsService) {
        this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        this._canAdd = (this._auth.modules['orders'].authLevel >= 30);
        this._canDelete = (this._auth.modules['orders'].authLevel >= 40);
        this._canManage = (this._auth.modules['orders'].authLevel > 40);
    }

    ngOnInit(): void {
        this.ordersLoading = true;
        this.loadYears();
        this.loadWarranties();
        this.loadPaymentTerms();
        this.loadOrders(0);
        this._editOrder = (this._auth.modules['orders'].authLevel >= 20);
        this._orderForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'orderNumber': new FormControl({value: '', disabled: !this._canManage}),
            'versionNumber': new FormControl({value: '', disabled: !this._canManage}),
            'employeeId': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'employee': new FormControl({value: '', disabled: !this._canEdit}),
            'responsibleId': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'responsible': new FormControl({value: '', disabled: !this._canEdit}),
            'contactId': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'customerId': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'customer': new FormControl({value: '', disabled: true}),
            'warrantyId': new FormControl({value: '', disabled: !this._canEdit}),
            'warranty': new FormControl({value: '', disabled: true}),
            'shippingMethodId': new FormControl({value: '', disabled: true}),
            'shippingMethod': new FormControl({value: '', disabled: true}),
            'paymentTermId': new FormControl({value: '', disabled: !this._canEdit}),
            'paymentTerm': new FormControl({value: '', disabled: true}),
            'projectTitle': new FormControl({value: '', disabled: !this._canEdit}),
            'projectDescription': new FormControl({value: '', disabled: !this._canEdit}),
            'isConstruction': new FormControl({value: '', disabled: !this._canEdit}),
            'isRenovation': new FormControl({value: '', disabled: !this._canEdit}),
            'remarks': new FormControl({value: '', disabled: true}),
            'orderDate': new FormControl({value: '', disabled: true}),
            'orderDateDate': new FormControl({value: '', disabled: !this._canEdit}),
            'sendDate': new FormControl({value: '', disabled: true}),
            'sendDateDate': new FormControl({value: '', disabled: !this._canEdit}),
            'labourHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'labourCost': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'travelHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'travelCost': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'craneHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'craneCost': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'transportHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'safetyHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'calculationHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'calculationCost': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'preparationHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'inspectionHours': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'parkingCost': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'profitAndRisk': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'commonCosts': new FormControl({value: '', disabled: !this._canEdit}),
            'discount': new FormControl({value: '', disabled: true}),
            'bonusContractPrice': new FormControl({value: '', disabled: !this._canEdit}),
            'totalPrice': new FormControl({value: 0, disabled: !this._canEdit}),
            'vatLabour': new FormControl({value: 21, disabled: !this._canEdit}),
            'vatMaterials': new FormControl({value: 21, disabled: !this._canEdit}),
            'standardText01': new FormControl({value: '', disabled: !this._canEdit}),
            'standardText02': new FormControl({value: '', disabled: !this._canEdit}),
            'standardText03': new FormControl({value: '', disabled: !this._canEdit}),
            'standardText04': new FormControl({value: '', disabled: !this._canEdit}),
            'standardText05': new FormControl({value: '', disabled: true}),
            'standardConditions': new FormControl({value: '', disabled: true}),
            'standardInvoiceDescription': new FormControl({value: '', disabled: true}),
            'order': new FormControl({value: '', disabled: !this._canEdit}),
            'addQuotation': new FormControl({value: '', disabled: !this._canEdit}),
            'windLoad': new FormControl({value: '', disabled: !this._canEdit}),
            'constructionPhysicalCalculation': new FormControl({value: '', disabled: !this._canEdit}),
            'cadDrawings': new FormControl({value: '', disabled: !this._canEdit}),
            'photos': new FormControl({value: '', disabled: !this._canEdit}),
            'otherInfo': new FormControl({value: '', disabled: !this._canEdit}),
            'privateAssignment': new FormControl({value: '', disabled: !this._canEdit}),
            'newTotal': new FormControl({value: '', disabled: !this._canEdit}),
            'year': new FormControl({value: '', disabled: true}),
            'readOnly': new FormControl({value: '', disabled: true}),
            'goProjectId': new FormControl({value: '', disabled: true}),
            'orderCustomers': new FormControl({value: '', disabled: true}),
            'orderCustomerId': new FormControl({value: '', disabled: true}),
            'parentId': new FormControl({value: '', disabled: true}),
            'displayName': new FormControl({value: '', disabled: true}),
            'displayTitle': new FormControl({value: '', disabled: true}),
            'facilities': new FormControl({value: '', disabled: true}),
            'facilityGroups': new FormControl({value: '', disabled: true}),
            'preferredSuppliers': new FormControl({value: '', disabled: true}),
            'uisVersion': new FormControl({value: '', disabled: true}),
            'workAddress': new FormControl({value: '', disabled: !this._canEdit}),
            'workCity': new FormControl({value: '', disabled: !this._canEdit}),
            'totals': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true})
        });
        // this._defaultText01.push({value: '', label: 'Maak uw keuze...'});
        // this._defaultText01.push({value: 'Met dank voor uw aanvraag, hebben wij het genoegen u geheel vrijblijvend te doen toekomen, onze prijsopgave voor bovengenoemd werk met een oppervlakte van ca. ?? m².', label: '1. Alleen oppervlakte'});
        // this._defaultText01.push({value: 'Met dank voor uw aanvraag, hebben wij het genoegen u geheel vrijblijvend te doen toekomen, onze prijsopgave voor bovengenoemd werk met een oppervlakte van ca. ?? m² en ca. ?? m¹ gootafwerkingen.', label: '2. Met gootafwerkingen'});
        // this._defaultText01.push({value: 'Met dank voor uw aanvraag, hebben wij het genoegen u geheel vrijblijvend te doen toekomen, onze prijsopgave voor bovengenoemd werk met ca. ?? m¹ gootafwerkingen.', label: '3. Alleen gootafwerkingen'});
        // this._defaultText02.push({value: '', label: 'Maak uw keuze...'});
        // this._defaultText02.push({value: 'Wij hebben de hoeveelheden en afmetingen berekend aan de hand van de gegevens uit uw mail d.d. ??', label: '1. Aanvraag per mail'});
        // this._defaultText02.push({value: 'Wij hebben de hoeveelheden en afmetingen berekend aan de hand van onze opname ter plaatse d.d. ??', label: '2. Eigen opname'});
        // this._defaultText02.push({value: 'Wij hebben de hoeveelheden en afmetingen berekend aan de hand van de in ons bezit zijnde gegevens: \nBestekbladen\t:\nBestektekeningen\t:', label: '3. Bestek'});
        // this._defaultText03.push({value: '', label: 'Maak uw keuze...'});
        // this._defaultText03.push({value: 'Onze werkzaamheden en leveranties bestaan uit:', label: '1. Algemeen'});

        this.loadEmployees();
        this.loadCustomers();
        this.loadProductTypes();
        this.loadTexts();
    }

    handleTabChange(event) {
        this.tabIndex = event.index;
        console.info(this.selectedVersion);
    }
    ngAfterViewInit(): void {
        this.kbShortCuts.push(
            {
                key: 'ctrl + s',
                preventDefault: true,
                description: "Opslaan",
                label: "Commando's",
                command: event => this.preSaveOrder(false)
            }, {
                key: 'ctrl + e',
                preventDefault: true,
                description: "Bewerken",
                label: "Commando's",
                command: event => this.loadOrderForm()
            }, {
                key: 'ctrl + v',
                preventDefault: true,
                description: "Open versies",
                label: "Commando's",
                command: event => this.loadVersions()
            }, {
                key: 'ctrl + x',
                preventDefault: true,
                description: "Sluit versies",
                label: "Commando's",
                command: event => this.closeVersions()
            }, {
                key: 'up',
                preventDefault: true,
                description: 'Omhoog',
                label: 'Navigatie',
                throttleTime: 100,
                command: event => this.rowUp()
            }, {
                key: 'down',
                preventDefault: true,
                description: 'Omlaag',
                label: 'Navigatie',
                throttleTime: 100,
                command: event => this.rowDown()
            }
        );
    }

    closeVersions() {
        this.selectedVersion = new Order();
        this._showVersion = false;
    }

    rowUp() {
        if (this._showVersion) {
            let row = this.orderVersions.indexOf(this.selectedVersion);
            if (row > 0) {
                this.selectedVersion = this.orderVersions[row - 1];
            }
        } else {
            let row = this.orders.indexOf(this.selectedOrder);
            if (row > 0) {
                this.selectedOrder = this.orders[row - 1];
            }
        }
    }

    rowDown() {
        let row = 0;
        if (this._showVersion) {
            row = this.orderVersions.indexOf(this.selectedVersion);
            if (row < this.orderVersions.length - 1) {
                this.selectedVersion = this.orderVersions[row + 1];
            }
        } else {
            row = this.orders.indexOf(this.selectedOrder);
            if (row < this.orders.length - 1) {
                this.selectedOrder = this.orders[row + 1];
            }
        }
    }

    loadYears() {
        this.years.push({label: new Date().getFullYear().toString(), value: new Date().getFullYear()});
        this._apiService.setActionUrl('orders/orders');
        this._apiService.specialPostAction(JSON.stringify({}), 'getOldestYear').subscribe(response => {
            if (response.success) {
                let oldestYear = response.data.data;
                this.years = [];
                for (let year = new Date().getFullYear(); year >= oldestYear; year--) {
                    this.years.push({label: year.toString(), value: year});
                }
            }
        });
    }

    loadWarranties() {
        this._apiService.setActionUrl('masterdata/warranties');
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(response => {
            if (response.success) {
                this.warranties = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadPaymentTerms() {
        this._apiService.setActionUrl('masterdata/paymentterms');
        this._apiService.getDropDown(JSON.stringify(['paymentTerm'])).subscribe(response => {
            if (response.success) {
                this.paymentTerms = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadOrders(offset: number) {
        if (offset === 0) {
            this.ordersLoading = true;
            this.orders = [];
        }
        this._apiService.setActionUrl('orders/orders');
        this._apiService.setSort({field: 'orderNumber', direction: 'DESC'});
        this._apiService.setSort({field: 'versionNumber', direction: 'DESC'});
        this._apiService.setFilter({field: 'year', operator: 'eq', value: this.selectedYear});
        this._apiService.setLimit({records: this._auth.getUserSettings().defaultRows, offset: offset});
        this._apiService.setDistinct('orderNumber');
        this._apiService.setListFields(['id', 'orderNumber', 'orderDate', 'customer', 'displayTitle', 'versionNumber', 'year', 'parentId', 'orderCustomers', 'displayName']);
        this._apiService.getStore().subscribe(response => {
            this.ordersLoading = false;
            if (response.success) {
                let curOrders = this.orders;
                this.orders = curOrders.concat(response.data.records);
                if (this._auth.getUserSettings().defaultRows == response.data.records.length) {
                    this.loadOrders(offset + this._auth.getUserSettings().defaultRows);
                } else {
                    this._auth.highestModObject['ordersGrid'] = {modSeq: response.data.highModSeq, url: 'orders/orders', functionScope: this, function: 'loadOrders', params: 0};
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadEmployees() {
        this._apiService.setActionUrl('users/users');
        this._apiService.getDropDown(JSON.stringify(['fullName'])).subscribe(response => {
            if (response.success) {
                this._employeeDropDown = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadCustomers() {
        this._apiService.loadGoRelations('customer').subscribe(response => {
            if (response.success) {
                this._customerDropDown.push({value: '', label: this._transService.instant('base.useSelectValue')});
                response.data.records.forEach(function(customer) {
                    this._customerDropDown.push({value: customer.id, label: customer.name});
                }, this)
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this._apiService.loadGoRelations('supplier').subscribe(response => {
            if (response.success) {
                this.suppliers.push({value: '', label: this._transService.instant('base.useSelectValue')});
                response.data.records.forEach(function(supplier) {
                    this.suppliers.push({value: supplier.id, label: supplier.name});
                }, this)
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadProductTypes() {
        this._apiService.setActionUrl('products/types');
        this._apiService.setFilter({field: 'uisVersion', operator: 'ge', value: 3});
        this._apiService.getDropDown(JSON.stringify(['description', 'code'])).subscribe(response => {
            if (response.success) {
                this.productTypes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadTexts() {
        this._apiService.setActionUrl('masterdata/texts');
        this._apiService.setSort({field: 'label', direction: 'ASC'});
        this._apiService.setFilter({field: 'textItemId', operator: 'eq', value: 1});
        this._apiService.getDropDown(JSON.stringify(['label']), 'text').subscribe(response => {
            if (response.success) {
                this._defaultText01 = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this._apiService.resetFilter();
        this._apiService.setFilter({field: 'textItemId', operator: 'eq', value: 2});
        this._apiService.getDropDown(JSON.stringify(['label']), 'text').subscribe(response => {
            if (response.success) {
                this._defaultText02 = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this._apiService.resetFilter();
        this._apiService.setFilter({field: 'textItemId', operator: 'eq', value: 3});
        this._apiService.getDropDown(JSON.stringify(['label']), 'text').subscribe(response => {
            if (response.success) {
                this._defaultText03 = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this._apiService.resetFilter();
        this._apiService.setFilter({field: 'textItemId', operator: 'eq', value: 10});
        this._apiService.getDropDown(JSON.stringify(['label']), 'text').subscribe(response => {
            if (response.success) {
                this._maintenanceText = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });


    }

    preLoadContacts(event) {
        let companyId = event.value;
        this.loadContacts(companyId);
    }

    newContact() {
        this._orderCustomer = new OrderCustomer();
        this._showNewContact = true;
    }

    loadContacts(companyId) {
        this._contactDropDown = [];
        this._contactDropDown.push({value: '', label: this._transService.instant('base.loading')});
        this._apiService.setActionUrl('base/go');
        let filter = {type: 'customerContact', companyId: companyId};
        this._apiService.specialPostAction(JSON.stringify(filter), 'loadrelations').subscribe(response => {
            if (response.success) {
                this._contactDropDown = [];
                this._contactDropDown.push({value: '', label: this._transService.instant('base.useSelectValue')});
                response.data.records.forEach(function(contact) {
                    this._contactDropDown.push({value: contact.id, label: contact.name});
                }, this);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    preLoadOrderForm() {
        if (this.selectedVersion.readOnly) {
            if (this._canManage) {
                this._confirmationService.confirm({
                    message: 'Order is gemarkeerd als alleen lezen, wilt u deze toch openen?',
                    header: 'Opgelet',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.loadOrderForm();
                        this.selectedVersion.readOnly = false;
                    },
                    reject: () => {
                        this._showVersion = false;
                        this.selectedOrder = new Order();
                        this.selectedVersion = new Order();
                    }
                });
            } else {
                this._confirmationService.confirm({
                    message: 'Order is gemarkeerd als alleen lezen. Deze kan niet gewijzigd worden.',
                    header: 'Opgelet',
                    icon: 'pi pi-exclamation-triangle',
                    rejectVisible: false,
                    accept: () => {
                        this._canEdit = false;
                        this.loadOrderForm();
                    }
                });
            }
        } else {
            this.loadOrderForm();
        }
    }

    loadOrderForm() {
        //this._showOrderDialog = true;
        this._apiService.setActionUrl('orders/orderfacilities');
        this._apiService.setFilter({field: 'orderId', operator: 'eq', value: this.selectedVersion.id});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this.selectedVersion.orderDateDate =  new Date(this.selectedVersion.orderDate * 1000);
                this.selectedVersion.sendDateDate = new Date(this.selectedVersion.sendDate * 1000);
                if (this.selectedVersion.newTotal === null) {
                    this.selectedVersion.newTotal = 0;
                }
                this.selectedVersion.preferredSuppliers.push({productTypeId: '', supplierId: ''});
                this.selectedVersion.facilities = response.data.records;
                this._showOrderDialog = true;
                //this.selectedVersion.readOnly = true;
                this._orderForm.setValue(this.selectedVersion as any);
                this._orderForm.markAsPristine();
                this.loadOrderCustomers();
                this.loadContacts(this.selectedVersion.customerId);
                this._apiService.setActionUrl('orders/orders');
                this._apiService.specialPostAction(JSON.stringify({orderId: this.selectedVersion.id, readOnlyStatus: true}), 'markAsReadOnly').subscribe(response => {
                    if (!response.success) {
                        this._auth.addError(response.errorMsg);
                    }
                });
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    loadOrderCustomers() {
        this._apiService.setActionUrl('orders/ordercustomers');
        this._apiService.setFilter({field: 'orderId', operator: 'eq', value: this.selectedVersion.parentId});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this._ocLoaded = true;
                this.selectedVersion.orderCustomers = [];
                this.selectedVersion.orderCustomers = response.data.records;
                this.numberOfOrderCustomers = response.data.records.length;
                if (this.numberOfOrderCustomers > 0) {
                    this.maxSortOrder = response.data.records[this.numberOfOrderCustomers - 1].sortOrder;
                } else {
                    this.maxSortOrder = '';
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadVersions() {
        if (this._showOrderDialog) {
            return;
        }
        this._apiService.setActionUrl('orders/orders');
        this._apiService.setSort({field: 'versionNumber', direction: 'DESC'});
        this._apiService.setFilter({field: 'orderNumber', value: this.selectedOrder.orderNumber, operator: 'eq'})
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this._showVersion = true;
                this.orderVersions = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    createOrder() {
        this._apiService.setActionUrl('orders/orders');
        this._apiService.specialPostAction('', 'newOrder').subscribe(response => {
            if (response.success) {
                this.selectedOrder = response.data.records[0];
                this.selectedVersion = response.data.records[0];
                this.loadOrderForm();
            }
        });
    }

    preCancelOrderDialog() {
        if (this._orderForm.dirty) {
            this.confirmCancelDialog();
        } else {
            this.cancelOrderDialog();
        }
        this.tabIndex = 0;
    }

    cancelOrderDialog() {
        this._showOrderDialog = false;
        this._ocLoaded = false;
        this.selectedVersion.readOnly = false;
        this._apiService.setActionUrl('orders/orders');
        this._apiService.specialPostAction(JSON.stringify({orderId: this.selectedVersion.id, readOnlyStatus: false}), 'markAsReadOnly').subscribe(response => {
            if (!response.success) {
                this._auth.addError(response.errorMsg);
            }
        });
        this._showVersion = false;
        this.selectedOrder = new Order();
        this.selectedVersion = new Order();
    }

    confirmCancelDialog() {
        this._confirmationService.confirm({
            message: 'Order is aangepast, wilt u deze aanpassingen annuleren?',
            header: 'Opgelet',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.cancelOrderDialog();
            }
        });
    }

    preNewOrderVersion() {
        this._showNewVersionInfo = true;
    }

    newOrderVersion() {
        this._showNewVersionInfo = false;
        this._apiService.setActionUrl('orders/orders');
        this._apiService.specialPostAction(JSON.stringify({
            orderId: this.selectedVersion.id,
            updatePrices: this._newVersionInfo.updatePrices,
            emptyOrder: this._newVersionInfo.emptyOrder
        }), 'newVersion').subscribe(response => {
            if (response.success) {
                this.cancelOrderDialog();
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    deleteOrder() {
        this._apiService.setActionUrl('orders/orders');
        this._apiService.deleteRecord(this.selectedVersion.id).subscribe(response => {
            if (response.success) {
                this.cancelOrderDialog();
                this.reloadOrders();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    preSaveOrder(hide: boolean = true) {
        let preferredSuppliers = this.selectedVersion.preferredSuppliers;
        let indexesToRemove = [];
        preferredSuppliers.forEach(function(preferredSupplier, index) {
            if (preferredSupplier.supplierId === '' || preferredSupplier.productTypeId === '') {
                // remove line
                indexesToRemove.push(index);
            }
        });
        indexesToRemove.sort(function (a, b) {
            return (b - a);
        });
        indexesToRemove.forEach(function (index) {
            preferredSuppliers.splice(index, 1);
        });

        this.selectedVersion = this._orderForm.getRawValue();
        this.selectedVersion.preferredSuppliers = preferredSuppliers;
        this.selectedVersion.orderDate = this.selectedVersion.orderDateDate.getTime() / 1000;
        this.selectedVersion.sendDate = this.selectedVersion.sendDateDate.getTime() / 1000;
        this.saveOrder(hide);
    }

    saveOrder(hide: boolean = true) {
        this._orderSaving = true;
        this._apiService.setActionUrl('orders/orders');
        this._apiService.saveRecord(this.selectedVersion).subscribe(response => {
            this._orderSaving = false;
            if (response.success) {
                this.calculateTotal('endPrice');
                this._orderForm.markAsPristine();
                this.calculatePartsPrice();
                this.saveContact();
                if (hide) {
                    this.cancelOrderDialog();
                    this.reloadOrders();
                } else {
                    this.selectedVersion = response.data.records[0];
                    this.selectedVersion.preferredSuppliers.push({productTypeId: '', supplierId: ''});
                    this.loadOrderForm();
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    saveContact() {
        let record = new OrderCustomer();
        record.contactId = this.selectedVersion.contactId;
        record.customerId = this.selectedVersion.customerId;
        record.id = this.selectedVersion.orderCustomerId;
        record.orderId = this.selectedVersion.parentId;
        record.sortOrder = 'a';

        this._apiService.setActionUrl('orders/ordercustomers');
        if (record.id == '') {
            this._apiService.createRecord(JSON.stringify(record)).subscribe(response => {
                this.handleSaveContactResponse(response);
            });
        } else {
            this._apiService.updateRecord(JSON.stringify(record)).subscribe(response => {
                this.handleSaveContactResponse(response);
            });
        }
    }

    handleSaveContactResponse(response) {
        if (response.success) {
            this.selectedVersion.orderCustomerId = response.data.records[0].id;
            this._orderForm.controls['orderCustomerId'].setValue(response.data.records[0].id);
            this.loadOrderCustomers();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    editOrderCustomer(orderCustomer: OrderCustomer) {
        this._orderCustomer = orderCustomer;
        this._showNewContact = true;
    }

    reloadOrders() {
        this.orders = [];
        this.ordersLoading = true;
        this.loadOrders(0);
    }

    createGkProject(event: Event) {
        this._apiService.setActionUrl('orders/orders');
        this._apiService.specialPostAction(JSON.stringify({orderId: this.selectedVersion.id}), 'createProject').subscribe( response => {
            if (response.success) {
                this.selectedVersion.goProjectId = response.data.records[0].goProjectId;
                this._orderForm.controls['goProjectId'].patchValue(response.data.records[0].goProjectId);
                this.orderDirty = true;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    deleteGkProject(event: Event) {
        this.selectedVersion.goProjectId = 0;
        this._orderForm.controls['goProjectId'].patchValue(0);
        this.orderDirty = true;
    }

    preCreateDoc() {
        this._includeVAT = this.selectedVersion.privateAssignment;
        this._subTotals = false;
        this.prepareMultipleCustomers();
        this._showDocOptions = true;
    }

    prepareMultipleCustomers() {
        this._selectedCustomerId = '';
        this._orderCustomers = [{label: this._transService.instant('base.useSelectValue'), value: ''}];
        this.selectedVersion.orderCustomers.forEach(function (customer) {
            this._orderCustomers.push({label: customer.customer.name, value: customer.id});
        }, this);
        if (this.selectedVersion.orderCustomers.length == 1) {
            this._selectedCustomerId = this.selectedVersion.orderCustomers[0].id;
            this._openBudgetOptions.customerId = this.selectedVersion.orderCustomers[0].id;
        }
    }

    checkSubTotals() {
        if (this._includeVAT) {
            this._subTotals = false;
        }
    }

    createDoc(subTotals: boolean = true, includingVAT: boolean = false, customerId: string = '') {
        this._showDocOptions = false;
        this._includeVAT = false;
        this._subTotals = false;
        this._selectedCustomerId = '';
        this._apiService.setActionUrl('orders/reports');
        this._apiService.specialPostAction(JSON.stringify({orderId: this.selectedVersion.id, printSubTotals: subTotals, includingVAT: includingVAT, customerId: customerId}), 'generateDoc').subscribe( response => {
            if (response.success) {
                this._auth.addInfo('De offerte is opgeslagen in Groeps-kantoor');
                let userName = this._auth.getUser().userName;
                this._st.openAssistant(response.data.data.toString().replace('admin@', userName + '@'));
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }


    createDetail() {
        this._apiService.setActionUrl('orders/reports');
        this._apiService.specialPostAction(JSON.stringify({
            orderId: this.selectedVersion.id,
            openBudgetOptions: {additionLabour: 0, additionMaterial: 0, customerId: 0},
            type: 'details'
        }), 'orderDetails').subscribe( response => {
            if (response.success) {
                this._auth.addInfo('De calculatie is opgeslagen in Groeps-kantoor');
                let userName = this._auth.getUser().userName;
                this._st.openAssistant(response.data.data.toString().replace('admin@', userName + '@'));
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    preCreateOpenBudget() {
        this.prepareMultipleCustomers();
        this._showOpenBudgetOptions = true;
    }

    createOpenBudget() {
        this._showOpenBudgetOptions = false;
        this._apiService.setActionUrl('orders/reports');
        this._apiService.specialPostAction(JSON.stringify({
            orderId: this.selectedVersion.id,
            openBudgetOptions: this._openBudgetOptions,
            type: 'openBudget'
        }), 'orderDetails').subscribe( response => {
            if (response.success) {
                this._auth.addInfo('De open begroting is opgeslagen in Groeps-kantoor');
                let userName = this._auth.getUser().userName;
                this._st.openAssistant(response.data.data.toString().replace('admin@', userName + '@'));
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }



    b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    };

    calculatePartsPrice() {
        this._apiService.setActionUrl('orders/orders');
        this._apiService.specialPostAction(JSON.stringify({orderId: this.selectedVersion.id}), 'calculatePartsPrice').subscribe(response =>{
            if (response.success) {
                this._reloadOrderParts = true;
                this._orderForm.controls['totalPrice'].patchValue(response.data.records[0].totalPrice);
            }
        });
    }

    mergeText(field: string, value: string) {
        this.selectedVersion[field] = value;
        this._orderForm.controls[field].patchValue(value);
    }

    changeWarranty(event) {
        this.selectedVersion.warrantyId = event.value;
        this._apiService.setActionUrl('masterdata/warranties');
        this._apiService.setFilter({field: 'id', operator: 'eq', value: event.value});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this.selectedVersion.bonusContractPrice = response.data.records[0].percentage;
                this._orderForm.controls['bonusContractPrice'].patchValue(response.data.records[0].percentage);
            }
        })
    }
    calculateTotal(field: string = 'endPrice') {
        this._apiService.setActionUrl('orders/orders');
        this._apiService.specialPostAction(JSON.stringify({orderId: this.selectedVersion.id, endPrice: this._orderForm.getRawValue().newTotal, profitAndRisk: this._orderForm.getRawValue().profitAndRisk, field: field}), 'calculateProfitAndRisk').subscribe(response =>{
            if (response.success) {
                this._orderForm.controls['profitAndRisk'].patchValue(response.data.records[0].profitAndRisk);
                this._orderForm.controls['newTotal'].patchValue(response.data.records[0].newTotal);
            }
        });
    }

    constructionRenovationChange(changedField, modifyField) {
        let changeValue = this._orderForm.getRawValue()[changedField];
        if (this._orderForm.getRawValue()[modifyField] == changeValue) {
            this._orderForm.controls[modifyField].patchValue(!changeValue);
        }
    }

    preferredSupplierChange(event, index) {
        this._orderForm.markAsDirty();
        if (index === this.selectedVersion.preferredSuppliers.length - 1) {
            this.selectedVersion.preferredSuppliers.push({productTypeId: '', supplierId: ''});
        }
    }

    getTitle() {
        let addText = '';
        if (this.selectedOrder.id.length === 0) {
            return 'Geen order geselecteerd';
        }
        if (this.selectedOrder.id.length > 0 && !(this.selectedVersion.id.length > 0)) {
            return this.selectedOrder.orderNumber;
        } else {
            if (this.selectedVersion.isConstruction) {
                addText = ' (Nieuwbouw)';
            } else if (this.selectedVersion.isRenovation) {
                addText = ' (Renovatie)';
            } else {
                addText = ' (Nog kiezen)';
            }
            return this.selectedVersion.orderNumber + '-' + this.selectedVersion.versionNumber + addText;
        }
    }
}
