import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {OrderDetailRecipe} from "./orders.models";

@Component({
    selector: 'orderDetailRecipes',
    templateUrl: './recipes.component.html',
    styleUrls: ['./orders.component.css']
})
export class RecipesComponent {

    @Input('recipes') detailRecipes: OrderDetailRecipe[];

    @Output('recipeSelected') selectedChange: EventEmitter<OrderDetailRecipe> = new EventEmitter<OrderDetailRecipe>();

    selectRecipe(event) {
        this.selectedChange.emit(event.data);
    }

}
