import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {ModulesComponent} from "./modules.component";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        ModulesComponent
    ]
})
export class ModulesModule {

}