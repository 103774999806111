import {Component, OnInit} from "@angular/core";
import {Group} from "./groups.models";
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AclService} from "../acl.service";
import {MenuItem, SelectItem} from "primeng/api";

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

    public showTable: boolean = true;
    public displayDialog: boolean = false;
    public groups: Group[];
    public selectedGroup: Group;
    public newGroup: boolean = false;
    public aclModules: any = [];
    private _phantomRecord: Group;
    private _recordTitle: string;
    public _groupForm: FormGroup;
    private _users: SelectItem[] = [];
    public _contextMenu: MenuItem[] = [];

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
            private _fb: FormBuilder, private _aclService: AclService) {}

    ngOnInit() {
        this.loadGroups();
        this.loadUsers();
        this._groupForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'name': new FormControl('', Validators.required),
            'createdAt': new FormControl(''),
            'modifiedAt': new FormControl(''),
            'ownerId': new FormControl('', Validators.required),
            'owner': new FormControl({value: '', disabled: true}),
            'aclItemId': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this._transService.get(['base.edit', 'base.delete']).subscribe(
            response => {
                if (this._auth.modules['groups'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editGroup()});
                }
                if (this._auth.modules['groups'].authLevel >= 40) {
                    this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteGroup()});
                }
            }
        )
    }

    loadUsers() {
        this._apiService.setActionUrl('users/users');
        this._apiService.getDropDown(JSON.stringify(['fullName'])).subscribe(
            response => {
                if (response.success) {
                    this._users = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    loadGroups() {
        this._apiService.setActionUrl('groups/groups');
        this._apiService.getStore().subscribe(
            resp => {
                if (resp.success) {
                    this.groups = resp.data.records;
                } else {
                    this._auth.addError(resp.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    createGroup() {
        this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('groups.group'));
        this.selectedGroup = new Group();
        this._groupForm.reset();
        this.displayDialog = true;
        this.newGroup = true;
    }

    editGroup() {
        this.displayDialog = true;
        this._aclService.getAcl(this.selectedGroup.aclItemId);
        this._recordTitle = this.selectedGroup.name;
        let importGroup = this.selectedGroup;
        this.loadGroupRights(importGroup.id);
        this._groupForm.setValue(importGroup);
    }

    saveGroup() {
        this._apiService.setActionUrl(('groups/groups'));
        this.selectedGroup = this._groupForm.getRawValue();
        if (this.newGroup) {
            this._apiService.createRecord(JSON.stringify(this.selectedGroup)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedGroup)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
            this._apiService.setActionUrl('base/acl');
            this._apiService.specialPostAction(JSON.stringify({aclLevels: this.aclModules, groupId: this.selectedGroup.id, model: 'group'}),
                'savemodulerights').subscribe(
                resp => {
                    if (!resp.success) {
                        this._auth.addError(resp.errorMsg);
                    }
                },
                err => {
                    this._auth.addError(err.error.text);
                }
            );
        }
    }

    private _afterSave(response) {
        if (response.success) {
            this.selectedGroup = null;
            this._phantomRecord = null;
            this.displayDialog = false;
            this.newGroup = false;
            this.loadGroups();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    deleteGroup() {
        this._apiService.setActionUrl('groups/groups');
        this._apiService.deleteRecord(this.selectedGroup.id).subscribe(
            response => {
                if (response.success) {
                    this.selectedGroup = null;
                    this.loadGroups();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    loadGroupRights(groupId) {
        this._apiService.setActionUrl('base/acl');
        this._apiService.specialPostAction(JSON.stringify({model: 'group', userId: '', groupId: groupId}), 'modulerights').subscribe(
            response => {
                if (response.success) {
                    this.aclModules = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );

    }

    cancel() {
        this.displayDialog = false;
        this.selectedGroup = null;
        this._phantomRecord = null;
        this.newGroup = false;
    }

    selectGroup(event) {
        this.selectedGroup = this._st.cloneRecord(event.data, new Group());
    }

    checkErrors() {
        /** Button for errorchecking
         *
         <button class="pButton" type="button" pButton icon="fa fa-question" (click)="checkErrors()"
         label="{{ 'base.check' | translate }}"></button>
         */
        this._st.getFormValidationErrors(this._groupForm);
    }

    onRowDblclickGroup(event, group) {
        this.selectedGroup = group;
        this.editGroup();
    }

}
