import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ProductProcess} from "./products.model";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";

@Component({
    selector: 'pp',
    templateUrl: './pp.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductProcessComponent {

    public _pp: ProductProcess;
    public _event: any;

    @Input() set ppData(event: any) {
        this._pp = event.newPP;
        this._event = event.event;
    }

    @Output() ppSaved: EventEmitter<any> = new EventEmitter<any>();

    constructor(public auth: AuthService, public _st: SupportTools, private _apiService: ApiService) {
    }

    savePP() {
        this._apiService.setActionUrl('products/productProcesses');
        this._apiService.saveRecord(this._pp).subscribe(response => {
            if (response.success) {
                this._pp = response.data.records[0];
                this.ppSaved.emit({pp: this._pp, event: this._event, cancelled: false})
            } else {
                this.auth.addError(response.errorMsg);
            }
        });
    }

    cancelPP() {
        if (this._event.mustComplete) {
            this.auth.addInfo('Deze actie kan niet geannuleerd worden');
        } else {
            this.ppSaved.emit({cancelled: true});
        }
    }

}
