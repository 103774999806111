import {BaseModel} from "../base/base.model";

export class Group extends BaseModel {
    name: string;
    managerFullName: string;
    ownerId: string;
    aclItemId: string;
}

export class Member {
    groupId: string;
    groupDescription: string;
    userId: string;
    userFullName: string
}

export class GroupDropDown {
    label: string;
    value: string;
}

