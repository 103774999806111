/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import {Injectable} from '@angular/core';

import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";
import {UserDropDown} from "./users.models";

@Injectable()
export class UsersService {

    public usersDropDown: UserDropDown[];

    constructor(private _apiService: ApiService, private _authService: AuthService) {
    }

    public getDropDown() {
        this._apiService.setActionUrl('users/users');
        this._apiService.getDropDown(JSON.stringify(['fullName']))
            .subscribe((resp) => {
                    if (resp.success) {
                        this.usersDropDown = resp.data.records;
                    } else {
                        this._authService.addError(resp.errorMsg);
                    }

                }, error => {
                    this._authService.addError(error.error.text);
                }
            );
    }
}


