import {NgModule} from '@angular/core';
import {UsersComponent} from "./users.component";
import {SharedModule} from "../shared.module";

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
    ],
    declarations: [
        UsersComponent
    ]
})
export class UsersModule {
}
