<p-table tableStyleClass="djc-table" [value]="selectedFacilityGroups" [paginator]="false" selectionMode="single"
         (onRowSelect)="changeFacilityGroup($event)" styleClass="table">
    <ng-template pTemplate="header">
        <tr>
            <th width="10em" align="left"><p-checkbox [(ngModel)]="allSelected" [binary]="true" (ngModelChange)="selectAllFacilityGroups($event)"></p-checkbox></th>
            <th width="95%">{{ 'base.strDescription' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-facilityGroup let-ri="rowIndex">
        <tr [pSelectableRow]="facilityGroup" [ngStyle]="_st.getRowStyle(facilityGroup.productGroupId, selectedFacilityGroup.productGroupId, ri)">
            <td width="10em"><p-checkbox [(ngModel)]="facilityGroup.selected" [binary]="true" (ngModelChange)="selectFacilityGroup(facilityGroup, true)"></p-checkbox></td>
            <td width="95%">{{ facilityGroup.description }}</td>
        </tr>
        <tr *ngIf="facilityGroup.productGroupId == selectedFacilityGroup.productGroupId && facilityGroup.selected">
            <td></td>
            <td>
                <p-table tableStyleClass="djc-table" [value]="orderFacilities" [rows]="_auth.defaultRows" [paginator]="false"
                         [scrollable]="false" scrollHeight="flex" styleClass="table"
                         selectionMode="single" editMode="row" (onRowSelect)="selectFacility($event)" [sortField]="'facilityProduct.name'">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{ 'orders.facility' | translate }}</th>
                            <th>Opmerkingen</th>
                            <th style="width: 15%;">{{ 'base.amount' | translate }}</th>
                            <th style="width: 15%;">{{ 'orders.multiplier' | translate }}</th>
                            <th>{{ 'orders.cost' | translate }}</th>
                            <th>{{ 'orders.totalCost' | translate }}</th>
                            <th style="width: 5em;"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-facility let-ri="rowIndex" let-editing="edit">
                        <tr [pEditableRow]="facility" [pSelectableRow]="facility" [ngStyle]="_st.getRowStyle(facility.id, selectedFacility.id, ri)">
                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="_facilityProductsDD" [(ngModel)]="facility.facilityProductId"
                                                    (onChange)="changeFacility($event, facility)" appendTo="body"
                                                    (onFocus)="_st.selectInputContent($event)"></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ facility.facilityProduct.name }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText [(ngModel)]="facility.remarks" (ngModelChange)="_dirtyRow = true"/>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ facility.remarks }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="width: 15%;" pEditableColumn [ngClass]="'right'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <djc-spinner [(ngModel)]="facility.quantity" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                     [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [step]="0.1" (onChange)="_dirtyRow = true"
                                                     (onFocus)="_st.selectInputContent($event)"></djc-spinner>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span>{{ facility.quantity | number : '1.1-1' }}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="width: 15%;" pEditableColumn [ngClass]="'right'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <djc-spinner [(ngModel)]="facility.multiplier" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                     [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [step]="1" [min]="1" (onChange)="_dirtyRow = true"
                                                     (onFocus)="_st.selectInputContent($event)"></djc-spinner>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span>{{ facility.multiplier | number : '1.0-0' }}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn [ngClass]="'right'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <djc-spinner [(ngModel)]="facility.costPerUnit" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                     [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [step]="0.01" (onChange)="_dirtyRow = true"
                                                     (onFocus)="_st.selectInputContent($event)"></djc-spinner>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="price">{{ facility.costPerUnit | currency: 'EUR' : 'symbol': '1.2-2': 'nl' }}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td [ngClass]="'right'">
                                <span class="price">{{ facility.costPerUnit * facility.quantity * facility.multiplier | currency : 'EUR' : 'symbol': '1.2-2' : 'nl' }}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-table tableStyleClass="djc-table" [value]="_selectedVersion.facilities" [paginator]="false">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ 'orders.facility' | translate }}</th>
            <th>Opmerkingen</th>
            <th style="width: 15%;">{{ 'base.amount' | translate }}</th>
            <th style="width: 15%;">{{ 'orders.multiplier' | translate }}</th>
            <th>{{ 'orders.cost' | translate }}</th>
            <th>{{ 'orders.totalCost' | translate }}</th>
            <th *ngIf="_canEdit" style="width:10em;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-facility let-ri="rowIndex">
        <tr *ngIf="rowVisible(facility)">
            <td>{{ facility.facilityProduct.name }}</td>
            <td>{{ facility.remarks }}</td>
            <td style="width: 15%;" class="right">{{ facility.quantity | number : '1.1-1' }}</td>
            <td style="width: 15%;" class="right">{{ facility.multiplier | number : '1.0-0' }}</td>
            <td class="price">{{ facility.costPerUnit | currency: 'EUR' : 'symbol': '1.2-2': 'nl' }}</td>
            <td class="price">{{ facility.costPerUnit * facility.quantity * facility.multiplier | currency : 'EUR' : 'symbol': '1.2-2' : 'nl' }}</td>
            <td style="width:10em;" *ngIf="_canEdit">
                <button *ngIf="_canDelete" pButton type="button" icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                        (click)="deleteFacility(facility, ri)"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="4">{{ 'base.total' | translate }}</td>
            <td class="price">{{ facilityTotalCost() | currency : 'EUR' : 'symbol': '1.2-2' : 'nl' }}</td>
        </tr>
    </ng-template>
</p-table>
