import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ProductGroupFinish} from "./products.model";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";

@Component({
    selector: 'pgf',
    templateUrl: './pgf.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductGroupFinishComponent {

    public _pgf: ProductGroupFinish;
    public _event: any;

    @Input() set pgfData(event: any) {
        this._pgf = event.newPGF;
        this._event = event.event;
    }

    @Output() pgfSaved: EventEmitter<any> = new EventEmitter<any>();

    constructor(public auth: AuthService, public _st: SupportTools, private _apiService: ApiService) {
    }

    savePGF() {
        this._apiService.setActionUrl('products/productGroupFinishes');
        this._apiService.saveRecord(this._pgf).subscribe(response => {
            if (response.success) {
                this._pgf = response.data.records[0];
                this.pgfSaved.emit({pgf: this._pgf, event: this._event, cancelled: false})
            } else {
                this.auth.addError(response.errorMsg);
            }
        });
    }

    cancelPGF() {
        if (this._event.mustComplete) {
            this.auth.addInfo('Deze actie kan niet geannuleerd worden');
        } else {
            this.pgfSaved.emit({cancelled: true});
        }
    }

}
