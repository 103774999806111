<div style="height: calc(50% - 60px)" *ngIf="_productsService.isLoaded()">
    {{ 'orders.details' | translate }} || {{ this.finish.sortOrderNumber }}: {{ this.finish.finishText }}
    <p-table *ngIf="!_detailsLoading" #orderDetailsTable tableStyleClass="djc-table" [loading]="_detailsLoading" [value]="orderDetails"
             [rows]="10" [paginator]="false" [pageLinks]="5"
             [scrollable]="true" scrollHeight="flex"
             selectionMode="single" dataKey="id" sortMode="single" [sortField]="'sortOrderNumber'" [sortOrder]="1" (onRowSelect)="selectDetail($event)">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:2em">#</th>
                <th style="width:6em">{{ 'orders.quantity' | translate }}</th>
                <th style="width:30em">{{ 'orders.product' | translate }}</th>
                <th>{{ 'orders.materialCost' | translate }}</th>
                <th>{{ 'orders.materialFactor' | translate }}</th>
                <th>{{ 'orders.materialLoss' | translate }}</th>
                <th>{{ 'products.piecesPerHour' | translate }}</th>
                <th>{{ 'orders.labourCost' | translate }}</th>
                <th>{{ 'orders.labourFactor' | translate }}</th>
                <th>{{ 'orders.materialNeeded' | translate }}</th>
                <th>{{ 'orders.materialTotal' | translate }}</th>
                <th>{{ 'orders.labourTotal' | translate }}</th>
                <th>Bewerkingscode</th>
                <th>Prijscode</th>
                <th *ngIf="_canEdit" style="width:10em;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-orderDetail let-ri="rowIndex">
            <tr [pSelectableRow]="orderDetail" [ngStyle]="_st.getRowStyle(orderDetail.id, selectedOrderDetail.id, ri)">
                <td style="width:2em">{{ orderDetail.sortOrderNumber }}</td>
                <td style="width:6em" *ngIf="!_canEdit">{{ orderDetail.quantity }}</td>
                <td style="width:6em" *ngIf="_canEdit" pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderDetail.quantity"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator"
                                       [step]="0.001" [formatInput]="false" (onBlur)="orderDetailsSave(orderDetail, false)" (onFocus)="selectInputContent($event)">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderDetail.quantity }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width:30em;" *ngIf="!_canEdit || selectedOrderDetail.id !== orderDetail.id" pTooltip="{{ orderDetail.detailText }}">{{ orderDetail.product.name }}</td>
                <td class="table-button" style="width:30em;" *ngIf="_canEdit && selectedOrderDetail.id === orderDetail.id && _order.uisVersion >= 3"
                    [ngStyle]="{'background-color': orderDetail.productId.length > 0 ? '' : 'red'}"
                    [id]="'product_' + orderDetail.sortOrderNumber" pEditableColumn [title]="orderDetail.detailText">
                    <button type="button" pButton (click)="openProductSelector(ri)"
                            [ngStyle]="{
                                'background-color': orderDetail.productId.length > 0 ? 'green !important' : 'red !important',
                                'width': '100% !important',
                                'height': '100% !important'
                                }" label="{{ orderDetail.product.name }}"></button>
                </td>
                <td style="width:30em;" *ngIf="_canEdit && selectedOrderDetail.id === orderDetail.id && _order.uisVersion < 3" [ngStyle]="{'background-color': orderDetail.productId.length > 0 ? '' : 'red'}"
                    [id]="'product_' + orderDetail.sortOrderNumber" pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <djc-autoComplete [ngClass]="'djcAutoComplete'" appendTo="body" [ngModel]="{label: orderDetail.product.name}"
                                              [ngModelOptions]="{standalone: true}" field="label" [suggestions]="_productsFiltered"
                                              [dropdown]="true" [dropdownMode]="'current'"
                                              (completeMethod)="filterProducts($event)" (onClear)="allProducts()" [scrollHeight]="'300px'"
                                              [panelStyle]="{'width':'80em'}" (onSelect)="changeProduct($event, ri, orderDetail)"
                                              (onBlur)="gotoNextCell($event, orderDetail, ri)" (onFocus)="selectInputContent($event)">
                            </djc-autoComplete>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderDetail.product.name }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="!_canEdit" class="price">{{ orderDetail.materialCost |currency:'EUR':'symbol':'1.3-3':'nl' }}</td>
                <td *ngIf="_canEdit" pEditableColumn class="right" [id]="'materialCost_' + orderDetail.sortOrderNumber">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderDetail.materialCost"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator"
                                       [step]="0.01" [formatInput]="false" (onBlur)="orderDetailsSave(orderDetail, false)" (onFocus)="selectInputContent($event)">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="price right">{{ orderDetail.materialCost |currency :'EUR':'symbol':'1.3-3':'nl' }}</span>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="!_canEdit" class="right">{{ orderDetail.materialFactor | number : '1.2-2' }}</td>
                <td *ngIf="_canEdit" pEditableColumn class="right">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderDetail.materialFactor"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator"
                                       [step]="0.01" [formatInput]="false" (onBlur)="orderDetailsSave(orderDetail, false)" (onFocus)="selectInputContent($event)">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderDetail.materialFactor | number : '1.2-2' }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="!_canEdit">{{ orderDetail.materialLoss | number : '1.2-2' }}%</td>
                <td *ngIf="_canEdit" pEditableColumn class="right">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderDetail.materialLoss"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator"
                                       [step]="0.01" [formatInput]="false" (onBlur)="orderDetailsSave(orderDetail, false)" (onFocus)="selectInputContent($event)">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderDetail.materialLoss | number : '1.2-2' }}%
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="right">{{ calcPiecesPerHour(orderDetail) | number : '1.2-2':'nl' }}</td>
                <td class="price">{{ (orderDetail.labourCost * orderDetail.labourFactor) | currency : 'EUR':'symbol':'1.2-2':'nl' }}</td>
                <td *ngIf="!_canEdit" class="right">{{ orderDetail.labourFactor | number : '1.2-2' }}</td>
                <td *ngIf="_canEdit" pEditableColumn class="right">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderDetail.labourFactor"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator"
                                       [step]="0.01" [formatInput]="false" (onBlur)="orderDetailsSave(orderDetail, false)" (onFocus)="selectInputContent($event)">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderDetail.labourFactor | number : '1.2-2' }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="right">{{ calcMaterial(orderDetail.quantity, orderDetail.materialLoss) | number : '1.3-3' }}</td>
                <td class="price">{{ (calcMaterial(orderDetail.quantity, orderDetail.materialLoss) * orderDetail.materialCost * orderDetail.materialFactor) | currency : 'EUR':'symbol':'1.2-2':'nl' }}</td>
                <td class="price">{{ (orderDetail.quantity * orderDetail.labourCost * orderDetail.labourFactor) | currency : 'EUR':'symbol':'1.2-2':'nl' }}</td>
                <td class="right">{{ orderDetail.process.code }}</td>
                <td class="right">{{ orderDetail.productPrice.optionCode }}</td>
                <td style="width:10em;" *ngIf="_canEdit">
                    <button *ngIf="_canDelete" pButton type="button" icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderDetailDelete(orderDetail, ri)"></button>
                    <button *ngIf="ri < orderDetails.length - 2" pButton type="button" icon="fa fa-arrow-down" class="ui-button" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderDetailMove(orderDetail, ri + 1)"></button>
                    <button *ngIf="ri == orderDetails.length - 2" pButton type="button" icon="fa fa-arrow-down" class="ui-button"
                            style="margin-right: .3em;margin-left: .2em;background-color: lightgray; border-color: lightgray"></button>
                    <button *ngIf="ri > 0 && ri !== orderDetails.length - 1" pButton type="button" icon="fa fa-arrow-up" class="ui-button" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderDetailMove(orderDetail, ri - 1)"></button>
                    <button *ngIf="ri == 0 && ri !== orderDetails.length - 1" pButton type="button" icon="fa fa-arrow-up" class="ui-button"
                            style="margin-right: .3em;margin-left: .2em;background-color: lightgray; border-color: lightgray"></button>
                    <button *ngIf="orderDetail.product.id !== '' && selectedOrderDetail.id === orderDetail.id" pButton type="button" icon="fa fa-pencil" class="ui-button-warning" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderDetailEditQuotationText(orderDetail, ri)"></button>
                    <button *ngIf="orderDetail.product.id !== '' && selectedOrderDetail.id !== orderDetail.id" pButton type="button" icon="fa fa-pencil" class="ui-button-warning"
                            style="margin-right: .3em;margin-left: .2em;background-color: lightgray; border-color: lightgray"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div *ngIf="_showPGF">
    <pgf *ngIf="_showPGF" [pgfData]="_sendToPGF" (pgfSaved)="savedPGFForm($event)"></pgf>
</div>
<div *ngIf="_showPP">
    <pp *ngIf="_showPP" [ppData]="_sendToPP" (ppSaved)="savedPPForm($event)"></pp>
</div>
<div *ngIf="_showProductPrices">
    <p-dialog appendTo="body" [closable]="false" styleClass="ui-g-6 pDialog" [modal]="true" visible="true" (onHide)="_showProductPrices = false">
        <p-table tableStyleClass="djc-table" [value]="productPrices" selectionMode="single" (onRowSelect)="changeProductPrice($event)" [(selection)]="selectedProductPrice">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 10em">{{ 'products.optionName' | translate }}</th>
                    <th style="width: 10em">{{ 'products.materialCost' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-productPrice let-editing="editing" let-ri="rowIndex">
                <tr [pSelectableRow]="productPrice">
                    <td>{{ productPrice.optionName }}</td>
                    <td>{{ productPrice.materialCost | currency:'EUR':'symbol':'1.3-3':'nl'}}</td>
                </tr>
            </ng-template>
        </p-table>
        <p-footer>
            <button type="button" pButton icon="pi pi-times" (click)="_showProductPrices=false" label="Cancel"></button>
        </p-footer>
    </p-dialog>
</div>
<div *ngIf="_productSelector">
    <p-dialog appendTo="body" [closable]="false" styleClass="ui-g-8 pDialog" [modal]="true" visible="true" (onHide)="_productSelector = false">
        <div class="ui-g">
            <div class="ui-g-3"><label for="productType">{{ 'products.productType' | translate }}</label></div>
            <div class="ui-g-9">
                <p-dropdown id="productType" [options]="_productTypes" (onChange)="loadProductGroups($event)"
                            [ngModel]="productSelection.productTypeId" appendTo="body"></p-dropdown>
            </div>
        </div>
        <div class="ui-g" *ngIf="_productGroups.length > 0">
            <div class="ui-g-3"><label for="productGroup">{{ 'products.productGroup' | translate }}</label></div>
            <div class="ui-g-9">
                <p-dropdown id="productGroup" [options]="_productGroups" (onChange)="loadProducts($event)"
                            [ngModel]="productSelection.productGroupId" appendTo="body"></p-dropdown>
            </div>
        </div>
        <div class="ui-g" *ngIf="_productTypeProcesses.length > 0">
            <div class="ui-g-3"><label for="process">{{ 'masterdata.processes' | translate }}</label></div>
            <div class="ui-g-9">
                <p-dropdown id="process" [options]="_productTypeProcesses" (onChange)="changeProcess($event)"
                            [(ngModel)]="selectedOrderDetail.processId" appendTo="body"></p-dropdown>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="product">{{ 'products.product' | translate }}</label></div>
            <div class="ui-g-6" *ngIf="_products.length > 0">
                <djc-autoComplete [ngClass]="'djcAutoComplete'" appendTo="body" [ngModel]="{label: selectedOrderDetail.product.name}"
                                  [dropdown]="true" [dropdownMode]="'current'"
                                  [ngModelOptions]="{standalone: true}" field="label" [suggestions]="_productsFiltered"
                                  (completeMethod)="filterProducts($event)" (onClear)="allProducts()" [scrollHeight]="'300px'"
                                  [style]="{'width':'100%'}" (onSelect)="changeProductSelector($event)" id="product">
                </djc-autoComplete>
            </div>
            <div class="ui-g-2">
                {{ 'orders.allSuppliers' | translate }}
            </div>
            <div class="ui-g-1">
                <p-checkbox [(ngModel)]="_allSuppliers" binary="true" (onChange)="loadProducts(_productTypeEvent)"></p-checkbox>
            </div>
        </div>
        <div class="ui-g" *ngIf="selectedOrderDetail.product.description.indexOf('{{opmerkingen}}') > -1">
            <div class="ui-g-3"><label for="remarks">{{ 'products.remarks' | translate }}</label></div>
            <div class="ui-g-9"><input id="remarks" type="text" pInputText [(ngModel)]="selectedOrderDetail.explanation" /></div>
        </div>
        <p-footer>
            <button type="button" pButton icon="pi pi-save" (click)="saveProductSelector()" label="{{ 'base.save' | translate }}" [disabled]="productSelection.productId === ''"></button>
            <button type="button" pButton icon="pi pi-times" (click)="_productSelector=false" label="Cancel"></button>
        </p-footer>
    </p-dialog>
</div>
<div *ngIf="_showDetailTextPopup">
    <p-dialog appendTo="body" [closable]="false" styleClass="ui-g-8 pDialog" [modal]="true" visible="true" (onHide)="_showDetailTextPopup = false">
        <div class="ui-g">
            <textarea pInputTextarea id="detailText" [(ngModel)]="selectedOrderDetail.modifiedText"></textarea>
        </div>
        <p-footer>
            <button type="button" pButton icon="pi pi-save" (click)="saveDetailText()" label="{{ 'base.save' | translate }}"></button>
            <button type="button" pButton icon="pi pi-times" (click)="_showDetailTextPopup=false" label="Cancel"></button>
        </p-footer>
    </p-dialog>
</div>
