import {Component, OnInit} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService, responseModel} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {Finish, PaymenTerm, Process, RoofingType, ShippingMethod, Text, Warranty} from "./masterdata.models";
import {MenuItem, SelectItem} from "primeng/api";
import {Setting} from "../base/base.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-masterdata',
    templateUrl: './masterdata.component.html',
    styleUrls: ['./masterdata.component.css']
})
export class MasterDataComponent implements OnInit {

    public finishesLoading: boolean = false;
    public warrantiesLoading: boolean = false;
    public paymentTermsLoading: boolean = false;
    public shippingMethodsLoading: boolean = false;
    public settingsLoading: boolean = false;
    public roofingTypesLoading: boolean = false;
    public processesLoading: boolean = false;
    public textsLoading: boolean = false;
    public selectedItem: string;
    public finishes: Finish[] = [];
    public warranties: Warranty[] = [];
    public paymentTerms: PaymenTerm[] = [];
    public shippingMethods: ShippingMethod[] = [];
    public settings: Setting[] = [];
    public roofingTypes: RoofingType[] = [];
    public processes: Process[] = [];
    public texts: Text[] = [];
    public selectedFinish: Finish = new Finish();
    public selectedWarranty: Warranty = new Warranty();
    public selectedPaymentTerm: PaymenTerm = new PaymenTerm();
    public selectedShippingMethod: ShippingMethod = new ShippingMethod();
    public selectedSetting: Setting = new Setting();
    public selectedRoofingType: RoofingType = new RoofingType();
    public selectedProcess: Process = new Process();
    public selectedProcessRow: number = -1;
    public selectedText: Text = new Text();
    public cm: MenuItem[] = [];
    public processForm: FormGroup;

    public _showFinishes: boolean = false;
    public _showWarranties: boolean = false;
    public _showShippingMethods: boolean = false;
    public _showSettings: boolean = false;
    public _showRoofingTypes: boolean = false;
    public _showBeforeDelete: boolean = false;
    public _showProcesses: boolean = false;
    public _showProcessEdit: boolean = false;
    public _showTexts: boolean = false;
    public _showPaymentTerms: boolean = false;
    private _tmpFinishes: Finish[] = [];
    private _tmpWarranties: Warranty[] = [];
    private _tmpPaymentTerms: PaymenTerm[] = [];
    private _tmpShippingMethods: ShippingMethod[] = [];
    private _tmpSettings: Setting[] = [];
    private _tmpRoofingTypes: RoofingType[] = [];
    private _tmpProcesses: Process[] = [];
    private _tmpTexts: Text[] = [];
    public _options: SelectItem[] = [];
    public _textItemOptions: SelectItem[] = [];
    public _selectedOption: string;
    public _optionSelected: string;
    public uisVersion: number = 3;

    constructor(public _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder) {

    }

    ngOnInit(): void {
        this.loadFinishes();
        this.loadWarranties();
        this.loadShippingMethods();
        this.loadPaymentTerms();
        this.loadSettings();
        this.loadRoofingTypes();
        this.loadProcesses();
        this.loadTexts();
        this._showFinishes = true;
        this._transService.get(['masterdata.finishes']).subscribe(
            response => {
                this.selectedItem = response['masterdata.finishes'];
            }
        );

        this._selectedOption = 'FIN';

        this._transService.get(['masterdata.finishes', 'masterdata.warranties', 'masterdata.shippingmethods',
            'base.settings', 'masterdata.roofingtypes', 'masterdata.processes', 'masterdata.texts', 'masterdata.paymentterms']).subscribe(
            response => {
                this._options.push({label: response['masterdata.finishes'], value: 'FIN'});
                this._options.push({label: response['masterdata.warranties'], value: 'WAR'});
                this._options.push({label: response['masterdata.paymentterms'], value: 'PAY'});
                //this._options.push({label: response['masterdata.shippingmethods'], value: 'SHM'});
                this._options.push({label: response['masterdata.roofingtypes'], value: 'RFT'});
                this._options.push({label: response['masterdata.processes'], value: 'PRO'});
                this._options.push({label: response['masterdata.texts'], value: 'TXT'});
                this._options.push({label: response['base.settings'], value: 'SET'});
            }
        );

        this._transService.get(['base.edit', 'base.delete', 'base.details', 'base.close']).subscribe(
            response => {
                if (this._auth.modules['masterdata'].authLevel >= 20) {
                    //this.cm.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editRecord()});
                }
                if (this._auth.modules['masterdata'].authLevel >= 40) {
                    this.cm.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.beforeDeleteRecord()});
                    this.cm.push({label: response['base.close'], icon: 'fa fa-close', command: (event) => {this.selectedFinish = new Finish()}});
                }
            }
        );
        this._textItemOptions = this._st.getTextItems();
        this.processForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'description': new FormControl({value: '', disabled: false}, Validators.required),
            'quotationText': new FormControl({value: '', disabled: false}),
            'piecesPerHourConstruct': new FormControl({}),
            'piecesPerHourRenovation': new FormControl({}),
            'materialLossConstruct': new FormControl({}),
            'materialLossRenovation': new FormControl({}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAtDate': new FormControl({value: '', disabled: true}),
            'modifiedAtDate': new FormControl({value: '', disabled: true}),
        });
    }

    editRecord() {

    }

    beforeDeleteRecord() {
        this._showBeforeDelete = true;
    }

    cancelDelete() {
        this._showBeforeDelete = false;
    }

    deleteRecord() {
        this._showBeforeDelete = false;
        let recordId: string = '';
        if (this._showFinishes) {
            this._apiService.setActionUrl('masterdata/finishes');
            recordId = this.selectedFinish.id;
        }
        if (this._showWarranties) {
            this._apiService.setActionUrl('masterdata/warranties');
            recordId = this.selectedWarranty.id;
        }
        if (this._showShippingMethods) {
            this._apiService.setActionUrl('masterdata/shippingmethods');
            recordId = this.selectedShippingMethod.id;
        }
        if (this._showTexts) {
            this._apiService.setActionUrl('masterdata/texts');
            recordId = this.selectedText.id;
        }
        if (this._showRoofingTypes) {
            this._apiService.setActionUrl('masterdata/roofingtypes');
            recordId = this.selectedRoofingType.id;
        }
        if (this._showProcesses) {
            this._apiService.setActionUrl('masterdata/processes');
            recordId = this.selectedProcess.id;
        }
        this._apiService.deleteRecord(recordId).subscribe(response => {
            if (response.success) {
                this.loadData();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    selectItem(event) {
        this._showFinishes = false;
        this._showWarranties = false;
        this._showShippingMethods = false;
        this._showSettings = false;
        this._showRoofingTypes = false;
        this._showProcesses = false;
        this._showTexts = false;
        this._showPaymentTerms = false;
        this._selectedOption = event.value;
        switch (this._selectedOption) {
            case 'FIN':
                this.selectedItem = this._transService.instant('masterdata.finishes');
                this._showFinishes = true;
                break;
            case 'WAR':
                this.selectedItem = this._transService.instant('masterdata.warranties');
                this._showWarranties = true;
                break;
            case 'PAY':
                this.selectedItem = this._transService.instant('masterdata.paymentterms');
                this._showPaymentTerms = true;
                break;
            case 'SHM':
                this.selectedItem = this._transService.instant('masterdata.shippingmethods');
                this._showShippingMethods = true;
                break;
            case 'RFT':
                this.selectedItem = this._transService.instant('masterdata.roofingtypes');
                this._showRoofingTypes = true;
                break;
            case 'PRO':
                this.selectedItem = this._transService.instant('masterdata.processes');
                this._showProcesses = true;
                break;
            case 'SET':
                this.selectedItem = this._transService.instant('base.settings');
                this._showSettings = true;
                break;
            case 'TXT':
                this.selectedItem = this._transService.instant('masterdata.texts');
                this._showTexts = true;
                break;
        }
        this.loadItems();
    }

    loadItems() {
        switch (this._selectedOption) {
            case 'FIN':
                this.loadFinishes();
                break;
            case 'WAR':
                this.loadWarranties();
                break;
            case 'SHM':
                this.loadShippingMethods();
                break;
            case 'SET':
                this.loadSettings();
                break;
            case 'RFT':
                this.loadRoofingTypes();
                break;
            case 'PRO':
                this.loadProcesses();
                break;
            case 'TXT':
                this.loadTexts();
                break;
        }
    }

    getTextItem(textItemId) {
        let returnString = 'NIET';
        this._textItemOptions.forEach(function(textItem) {
            if (textItem.value === textItemId) {
                returnString = textItem.label;
            }
        });
        return returnString;
    }

    createItem() {
        switch (this._selectedOption) {
            case 'FIN':
                let finish = new Finish();
                finish.id = '';
                this.finishes.unshift(finish);
                break;
            case 'WAR':
                let war = new Warranty();
                war.id = '';
                this.warranties.unshift(war);
                break;
            case 'PAY':
                let pay = new PaymenTerm();
                pay.id = '';
                this.paymentTerms.unshift(pay);
                break;
            case 'SHM':
                let shm = new ShippingMethod();
                shm.id = '';
                this.shippingMethods.unshift(shm);
                break;
            case 'RFT':
                let rft = new RoofingType();
                rft.availableFromDate = new Date();
                rft.availableFromDate.setHours(0,0,0,0);
                rft.availableFrom = rft.availableFromDate.getTime() / 1000;
                rft.availableTillDate = new Date();
                rft.availableTillDate.setFullYear(rft.availableTillDate.getFullYear() + 50, 11, 31);
                rft.availableTillDate.setHours(23,59,59,0);
                rft.availableTill = rft.availableTillDate.getTime() / 1000;
                this.roofingTypes.unshift(rft);
                break;
            case 'PRO':
                let pro = new Process();
                pro.id = '';
                this.processes.unshift(pro);
                break;
            case 'SET':
                let set = new Setting();
                set.id = '';
                this.settings.unshift(set);
                break;
            case 'TXT':
                let txt = new Text();
                this.texts.unshift(txt);
                break;
        }
    }

    loadFinishes() {
        this.finishesLoading = true;
        this._apiService.setActionUrl('masterdata/finishes');
        this._apiService.setFilter({field: 'uisVersion', operator: 'eq', value: this.uisVersion});
        this._apiService.getStore().subscribe(response => {
            this.finishesLoading = false;
            if (response.success) {
                this.finishes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadWarranties() {
        this.warrantiesLoading = true;
        this._apiService.setActionUrl('masterdata/warranties');
        this._apiService.getStore().subscribe(response => {
            this.warrantiesLoading = false;
            if (response.success) {
                this.warranties = response.data.records
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadPaymentTerms() {
        this.paymentTermsLoading = true;
        this._apiService.setActionUrl('masterdata/paymentterms');
        this._apiService.getStore().subscribe(response => {
            this.paymentTermsLoading = false;
            if (response.success) {
                this.paymentTerms = response.data.records
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadShippingMethods() {
        this.shippingMethodsLoading = true;
        this._apiService.setActionUrl('masterdata/shippingmethods');
        this._apiService.getStore().subscribe(response => {
            this.shippingMethodsLoading = false;
            if (response.success) {
                this.shippingMethods = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadSettings() {
        this.settingsLoading = true;
        this._apiService.setActionUrl('base/settings');
        this._apiService.getStore().subscribe(response => {
            this.settingsLoading = false;
            if (response.success) {
                this.settings = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    loadRoofingTypes() {
        this.roofingTypesLoading = true;
        this._apiService.setActionUrl('masterdata/roofingtypes');
        this._apiService.getStore().subscribe(response => {
            this.roofingTypesLoading = false;
            if (response.success) {
                this.roofingTypes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadProcesses() {
        this.processesLoading = true;
        this._apiService.setActionUrl('masterdata/processes');
        this._apiService.getStore().subscribe(response => {
            this.processesLoading = false;
            if (response.success) {
                this.processes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadTexts() {
        this.textsLoading = true;
        this._apiService.setActionUrl('masterdata/texts');
        this._apiService.getStore().subscribe(response => {
            this.textsLoading = false;
            if (response.success) {
                this.texts = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadData() {
        if (this._showFinishes) {
            this.loadFinishes();
        }
        if (this._showWarranties) {
            this.loadWarranties();
        }
        if (this._showPaymentTerms) {
            this.loadPaymentTerms();
        }
        if (this._showShippingMethods) {
            this.loadShippingMethods();
        }
        if (this._showSettings) {
            this.loadSettings();
        }
        if (this._showRoofingTypes) {
            this.loadRoofingTypes();
        }
        if (this._showProcesses) {
            this.loadProcesses();
        }
        if (this._showTexts) {
            this.loadTexts();
        }
    }

    finishEditInit(finish: Finish) {
        this._tmpFinishes[finish.id] = { ... finish };
    }

    finishEditSave(finish: Finish) {
        if (finish.code.length > 0) {
            this._apiService.setActionUrl('masterdata/finishes');
            this._apiService.saveRecord(finish).subscribe(response => {
                if (response.success) {
                    this.selectedFinish = new Finish();
                    this.loadFinishes();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        }
    }

    finishEditCancel(finish: Finish, row: number) {
        this.finishes[row] = this._tmpFinishes[finish.id];
        this._tmpFinishes[finish.id] = null;
    }

    warrantyEditInit(warranty: Warranty) {
        this._tmpWarranties[warranty.id] = { ... warranty };
    }

    warrantyEditSave(warranty: Warranty) {
        if (warranty.description.length > 0) {
            this._apiService.setActionUrl('masterdata/warranties');
            this._apiService.saveRecord(warranty).subscribe(response => {
                if (response.success) {
                    this.selectedWarranty = new Warranty();
                    this.loadWarranties();
                }
            });
        }
    }

    warrantyEditCancel(warranty: Warranty, row: number) {
        this.warranties[row] = this._tmpWarranties[warranty.id];
        this._tmpWarranties[warranty.id] = null;
    }

    shipEditInit(shipMethod: ShippingMethod) {
        this._tmpShippingMethods[shipMethod.id] = { ... shipMethod };
    }

    shipEditSave(shipMethod: ShippingMethod) {
        if (shipMethod.shippingMethod.length > 0) {
            this._apiService.setActionUrl('masterdata/shippingmethods');
            this._apiService.saveRecord(shipMethod).subscribe(response => {
                if (response.success) {
                    this.selectedShippingMethod = new ShippingMethod();
                    this.loadShippingMethods();
                }
            });
        }
    }

    shipEditCancel(shipMethod: ShippingMethod, row: number) {
        this.shippingMethods[row] = this._tmpShippingMethods[shipMethod.id];
        this._tmpShippingMethods[shipMethod.id] = null;
    }


    paymentTermEditInit(paymenTerm: PaymenTerm) {
        this._tmpPaymentTerms[paymenTerm.id] = { ... PaymenTerm };
    }

    paymentTermEditSave(paymentTerm: PaymenTerm) {
        if (paymentTerm.paymentTerm.length > 0) {
            this._apiService.setActionUrl('masterdata/paymentterms');
            this._apiService.saveRecord(paymentTerm).subscribe(response => {
                if (response.success) {
                    this.selectedPaymentTerm = new PaymenTerm();
                    this.loadPaymentTerms();
                }
            });
        }
    }

    paymentTermEditCancel(paymentTerm: PaymenTerm, row: number) {
        this.paymentTerms[row] = this._tmpPaymentTerms[paymentTerm.id];
        this._tmpPaymentTerms[paymentTerm.id] = null;
    }

    textEditInit(text: Text) {
        this._tmpTexts[text.id] = { ... text };
    }

    textEditSave(text: Text) {
        if (text.text.length > 0) {
            this._apiService.setActionUrl('masterdata/texts');
            this._apiService.saveRecord(text).subscribe(response => {
                if (response.success) {
                    this.selectedText = new Text();
                    this.loadTexts();
                }
            });
        }
    }

    textEditCancel(text: Text, row: number) {
        this.texts[row] = this._tmpTexts[text.id];
        this._tmpTexts[text.id] = null;
    }

    settingEditInit(setting: Setting) {
        this._tmpSettings[setting.id] = { ... setting };
    }

    settingEditSave(setting: Setting) {
        this._apiService.setActionUrl('base/settings');
        this._apiService.saveRecord(setting).subscribe(response => {
            if (response.success) {
                this.selectedSetting = new Setting();
                this.loadSettings();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    settingEditCancel(setting: Setting, row: number) {
        this.shippingMethods[row] = this._tmpShippingMethods[setting.id];
        this._tmpShippingMethods[setting.id] = null;
    }

    uploadFile(event, setting: Setting) {
        event.formData.append('type', setting.type);
        event.formData.append('id', setting.id);
    }

    uploadReady(event) {
        let response:responseModel = JSON.parse(event.xhr.response);
        if (!response.success) {
            this._auth.addError(response.errorMsg);
        }
    }

    roofingTypeEditInit(roofingType: RoofingType) {
        this._tmpRoofingTypes[roofingType.id] = { ... roofingType };
        roofingType.availableFromDate = new Date(roofingType.availableFrom * 1000);
        roofingType.availableTillDate = new Date(roofingType.availableTill * 1000);
    }

    roofingTypeEditSave(roofingType: RoofingType) {
        this._apiService.setActionUrl('masterdata/roofingtypes');
        roofingType.availableTill = roofingType.availableTillDate.getTime() / 1000;
        roofingType.availableFrom = roofingType.availableFromDate.getTime() / 1000;
        this._apiService.saveRecord(roofingType).subscribe(response => {
            if (response.success) {
                this.selectedRoofingType = new RoofingType();
                this.loadRoofingTypes();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });

    }

    roofingTypeEditCancel(roofingType: RoofingType, row: number) {
        this.roofingTypes[row] = this._tmpRoofingTypes[roofingType.id];
        this._tmpRoofingTypes[roofingType.id] = null;
    }

    processEditInit(process: Process, row: number) {
        this._tmpProcesses[process.id] = { ... process };
        this.selectedProcess = { ... process };
        this.selectedProcessRow = row;
        this._showProcessEdit = true;
    }

    processEditSave() {
        this._apiService.setActionUrl('masterdata/processes');
        this._apiService.saveRecord(this.selectedProcess).subscribe(response => {
            if (response.success) {
                this.selectedProcess = new Process();
                this.loadProcesses();
                this._showProcessEdit = false;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });

    }

    processEditCancel() {
        this.processes[this.selectedProcessRow] = this._tmpProcesses[this.selectedProcess.id];
        this._tmpProcesses[this.selectedProcess.id] = null;
        this._showProcessEdit = false;
        this.selectedProcess = new Process();
    }

    switchVersion() {
        this.uisVersion++;
        if (this.uisVersion > 3)
            this.uisVersion = 2;

        this.loadFinishes();
    }


}
