<p-table [value]="choices" sortField="sortOrderNumber" sortOrder="DESC" tableStyleClass="djc-table"
         (onRowSelect)="selectChoice($event)" selectionMode="single" styleClass="table">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 4em;">#</th>
            <th>{{ 'base.strName' | translate }}</th>
            <th>{{ 'base.strValue' | translate }}</th>
            <th style="width: 3em;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-choice let-ri="rowIndex" let-editing="edit">
        <tr [pSelectableRow]="choice" [ngStyle]="_st.getRowStyle(choice.sortOrderNumber.toString(), selectedChoice.sortOrderNumber.toString(), ri)">
            <td style="width: 4em">{{ choice.sortOrderNumber }}</td>
            <td *ngIf="!_canEdit">{{ choice.choiceName }}</td>
            <td *ngIf="_canEdit" pEditableColumn>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <djc-autoComplete [ngClass]="'djcAutoComplete'" appendTo="body" [suggestions]="_filteredChoices" (completeMethod)="filterChoices($event)"
                                          (onClear)="resetChoices()" field="label" (onSelect)="changeChoice($event,ri, choice)"
                                          (onFocus)="selectInputChoice($event)" scrollHeight="300px" [panelStyle]="{'width':'100em'}"
                                          [ngModelOptions]="{standalone: true}" [ngModel]="{label: choice.choiceName}"
                                          [dropdown]="true" [dropdownMode]="'current'">
                        </djc-autoComplete>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{ choice.choiceName }}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td *ngIf="!_canEdit">
                <span *ngIf="!(choice.fieldType == 'DAT' || choice.fieldType == 'CB' || choice.fieldType == 'NUM')">{{ choice.fieldValue }}</span>
                <djc-spinner *ngIf="choice.fieldType == 'NUM'" [(ngModel)]="choice.fieldValue"
                             [thousandSeparator]="auth.getUserSettings().thousandSeparator"
                             [decimalSeparator]="auth.getUserSettings().decimalSeparator" [step]="0.01" [disabled]="true"></djc-spinner>
                <p-checkbox *ngIf="choice.fieldType == 'CB'" [(ngModel)]="choice.fieldValue" [binary]="true" [disabled]="true"></p-checkbox>
                <djc-date *ngIf="choice.fieldType == 'DAT'" [timeInput]="calculateTime(choice.fieldValue)"[format]="auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td *ngIf="_canEdit" pEditableColumn>
                <p-cellEditor>
                    <ng-template pTemplate="output">
                        <span *ngIf="!(choice.fieldType == 'DAT' || choice.fieldType == 'CB' || choice.fieldType == 'NUM')">{{ choice.fieldValue }}</span>
                        <span *ngIf="choice.fieldType == 'NUM'" class="right">{{ choice.fieldValue | number : '1.2-2' }}</span>
                        <p-checkbox *ngIf="choice.fieldType == 'CB'" [(ngModel)]="choice.fieldValue" [binary]="true" [disabled]="true"></p-checkbox>
                        <djc-date *ngIf="choice.fieldType == 'DAT'" [timeInput]="calculateTime(choice.fieldValue)" [format]="auth.getUserSettings().dateFormat"></djc-date>
                    </ng-template>
                    <ng-template pTemplate="input">
                        <djc-spinner *ngIf="choice.fieldType == 'FLO'" [(ngModel)]="choice.fieldValue"
                                     [thousandSeparator]="auth.getUserSettings().thousandSeparator"
                                     [decimalSeparator]="auth.getUserSettings().decimalSeparator" [step]="0.01" (ngModelChange)="changeValue($event)"></djc-spinner>
                        <djc-spinner *ngIf="choice.fieldType == 'NUM'" [(ngModel)]="choice.fieldValue"
                                     [thousandSeparator]="auth.getUserSettings().thousandSeparator"
                                     [decimalSeparator]="auth.getUserSettings().decimalSeparator" [step]="1" (ngModelChange)="changeValue($event)"></djc-spinner>
                        <p-checkbox *ngIf="choice.fieldType == 'CB'" [(ngModel)]="choice.fieldValue" [binary]="true" (ngModelChange)="changeValue($event)"></p-checkbox>
                        <input *ngIf="choice.fieldType == 'TXT'" pInputText type="text" [(ngModel)]="choice.fieldValue" (ngModelChange)="changeValue($event)">
                        <p-calendar *ngIf="choice.fieldType == 'DAT'" [ngClass]="'pCalendar'" [(ngModel)]="choice.fieldValue" [showIcon]="true"
                                    [dateFormat]="'dd-mm-yy'" appendTo="body" (ngModelChange)="changeValue($event)"></p-calendar>
                        <p-dropdown *ngIf="choice.fieldType == 'OPT'" [options]="getOptions(choice.choiceId)" (onBlur)="changeValue($event)" [(ngModel)]="choice.fieldValue"></p-dropdown>
                        <span *ngIf="!(choice.fieldType == 'FLO' ||
                                        choice.fieldType == 'NUM' ||
                                        choice.fieldType == 'CB' ||
                                        choice.fieldType == 'TXT' ||
                                        choice.fieldType == 'DAT' ||
                                        choice.fieldType == 'OPT'
                                       )">{{ choice.fieldValue }}</span>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td *ngIf="!_canEdit" style="width: 3em;"></td>
            <td *ngIf="_canEdit" style="width: 3em;">
                <button *ngIf="_canDelete" pButton type="button" icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                        (click)="deleteChoice(choice.sortOrderNumber)"></button>
            </td>
        </tr>
        <tr *ngIf="choice.sortOrderNumber == selectedChoice.sortOrderNumber && choice.choiceId !== '--' && choice.fieldType === ''">
            <td></td>
            <td colspan="3"><finishChoice [parentChoiceId]="choice.choiceId" [(choices)]="choice.subChoices"
                                          [level]="_level + 1" (choicesChange)="emitChangeChoice($event)"></finishChoice></td>
        </tr>
    </ng-template>
</p-table>
<div *ngIf="_addChoice">
    <choices [(visible)]="_addChoice" [addChoice]="true" [parentId]="_parentChoiceId" (reloadChoices)="reloadChoices($event)" [choice]="_newChoice" [description]="_parentDescription"></choices>
</div>
