import {Finish, Process} from "../masterdata/masterdata.models";
import {BaseModel, BaseModelShared} from "../base/base.model";

export class ProductType extends BaseModelShared {
    code: string;
    description: string;
    uisVersion: number = 3;
    processes: any = [];
}

export class ProductGroup extends BaseModelShared {
    productTypeId: string;
    productType: ProductType;
    description: string;
    popupAlert: boolean;
}

export class ProductProcess extends BaseModel {
    productId: string;
    product: Product = new Product();
    processId: string;
    process: Process = new Process();
}

export class ProductPrice {
    id: string = '';
    optionName: string;
    optionCode: string;
    oldOptionName: string;
    productId: string;
    materialCost: number = 0;
    purchasePrice: number = 0;
}

export class Product extends BaseModel {
    productGroupId: string;
    productGroup: ProductGroup = new ProductGroup();
    parentProductId: string;
    parentProduct: Product;
    supplierId: number;
    supplier: any;
    name: string = '';
    description: string = '';
    itemCode: string;
    calculationFactor: number;
    hasParentProduct: boolean;
    productType: ProductType = new ProductType();
    processes: ProductProcess[] = [];
    productPrices: ProductPrice[] = [];
    /**
     * @deprecated available for compatibility with version 2
     */
    materialLoss: number;
    /**
     * @deprecated available for compatibility with version 2
     */
    labourHours: number;
    /**
     * @deprecated available for compatibility with version 2
     */
    piecesPerHour: number;
    available: boolean = true;
}

export class SelectedFacilityGroup {
    selected: boolean;
    productGroupId: string;
    description: string;
}

export class ProductGroupFinish extends BaseModelShared {
    productGroupId: string;
    productGroup: ProductGroup = new ProductGroup();
    finishId: string;
    finish: Finish = new Finish();
}

export class ProductImport extends BaseModelShared {
    supplierId: number;
    description: string;
    importDefinition: any;
}

export class ProductImports extends  BaseModelShared {
    importDate: number = 0;
    importDateDate: Date = new Date();
    supplier: any;
    supplierId: number;
    description: string;
}
