import {Component, DoCheck, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "./auth.service";
import {ROUTES, MenuType} from "../routes";
import {Router} from "@angular/router";
import {User, UserSettings} from "./users/users.models";
import {name, version} from "../../package.json";
import {ProductsService} from "./products/products.service";
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, DoCheck {

    public title: string = name;
    public version: string = version;
    public endYear: number;
    public menuItems: any = [];

    private _user: User;
    private _userSettings: UserSettings;
    public _mobileMenuActive: boolean = false;

    constructor(translate: TranslateService, public _auth: AuthService, private _router: Router, public productsService: ProductsService) {
        if (sessionStorage.getItem('authentication') !== null) {
            var response = JSON.parse(sessionStorage.getItem('authentication'));
            _auth.doLogin(response);
            this._user = _auth.getUser();
            this._userSettings = _auth.getUserSettings();
            translate.use(this._userSettings.language);
        } else {
            var browserLang = navigator.language.split('-')[0]; // use navigator lang if available
            translate.use(browserLang);
        }

        var now = new Date();
        this.endYear = now.getFullYear();
    }

    ngOnInit() {
        while (this._auth.loginPending) {

        }
        this.buildMenu();

        if (!this._auth.isAuthenticated()) {
            this.title = 'Login';
            this._router.navigate(['/login']);
        } else {
            this._user = this._auth.getUser();
            this._userSettings = this._auth.getUserSettings();
        }
    }

    ngDoCheck() {
        if (this._auth.isAuthenticated()) {
            this.title = name;
            if (!this._auth.getMenuBuild()) {
                this.buildMenu();
            }
        }
    }

    toggleMenu(e) {
        this._mobileMenuActive = !this._mobileMenuActive;
        e.preventDefault();
    }

    inMenu(path: string) {
        switch (path) {
            case 'login':
                return !this._auth.isAuthenticated();
            case 'logout':
                return this._auth.isAuthenticated();
            case 'test':
                if (this._auth.isAuthenticated()) {
                    var user = this._auth.getUser();
                    if (user.userName === 'admin') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            default:
                if (this._auth.isAuthenticated()) {
                    if ((path in this._auth.modules)) {
                        return (this._auth.modules[path].authLevel > 0);
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
        }
    }

    public buildMenu() {
        this.menuItems = [];
        this.menuItems[0] = [];
        this.menuItems[0]['path'] = '';
        this.menuItems[0]['title'] = 'Home';
        for (let ROUTE of ROUTES) {
            if (ROUTE.menuType !== MenuType.INVISIBLE) {
                if (this.inMenu(ROUTE.path) || !ROUTE.acl) {
                    let module = this._auth.modules[ROUTE.path];
                    if (typeof module !== 'undefined') {
                        if (typeof (this.menuItems[module.sortOrder]) === 'undefined') {
                            this.menuItems[module.sortOrder] = [];
                            this.menuItems[module.sortOrder]['path'] = ROUTE.path;
                            this.menuItems[module.sortOrder]['title'] = module.name + '.name';
                            this.menuItems[module.sortOrder]['description'] = module.name + '.description';
                        }
                    }
                }
            }
        }
        if (!this.productsService.isLoading() && this._auth.isAuthenticated()) {
            this.productsService.loadProductGroups(true);
            // this.productsService.loadProducts();
        }
        this._auth.setMenuBuild();
    }

    get userName() {
        let user = this._auth.getUser();
        return user.fullName;
    }

}
