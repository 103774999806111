import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Choice} from "./masterdata.models";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { SelectItem } from 'primeng/api/selectitem';

@Component({
    selector: 'choices',
    templateUrl: 'choices.component.html',
    styleUrls: ['masterdata.component.css']
})
export class ChoicesComponent implements OnInit {

    public canEdit: boolean;
    public canAdd: boolean;
    public canDelete: boolean;
    public choices: Choice[] = [];
    public choicesForm: FormGroup;
    public choicesLoading: boolean = false;
    public choiceEdit: boolean = false;
    public _parentId: string;
    public _visible: boolean = false;
    public selectedChoice: Choice = new Choice();
    public _isFieldFieldSet: boolean = false;
    public _isOptionList: boolean = false;
    public fieldTypes: SelectItem[] = [];
    public fieldTags: SelectItem[] = [];

    @Input() get parentId(): string {
        return this._parentId;
    }

    set parentId(parentId) {
        this._parentId = parentId;
        if (!this.addChoice) {
            this.loadChoices();
        } else {
            let choice = new Choice();
            choice.fieldType = 'TXT';
            choice.parentId = parentId;
            this.editChoice(choice);
        }
    }

    @Input() get choice(): Choice {
        return this.selectedChoice;
    }

    set choice(choice) {
        this.selectedChoice = choice;
    }

    @Input() set description(description: string) {
        this.fieldTags = [];
        this.fieldTags.push({value: '', label: 'Maak uw keuze...'});
        let pos = 0
        let endpos = 0;
        let strLength = 0;
        let tag = '';
        while ((pos = description.indexOf('{{')) !== -1) {
            endpos = description.indexOf('}}');
            strLength = endpos - pos - 2;
            tag = description.substr(pos + 2, strLength);
            description = description.substr(endpos + 2);
            this.fieldTags.push({value: tag, label: tag});
        }
    }

    @Input() level: number;

    @Input() addChoice: boolean;

    @Input() get visible(): boolean {
        return this._visible;
    }
    set visible(visible) {
        this._visible = visible;
    }

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() reloadChoices: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _transService: TranslateService,
                private _fb: FormBuilder) {
        this.canEdit = (this._auth.modules['masterdata'].authLevel >= 20);
        this.canAdd = (this._auth.modules['masterdata'].authLevel >= 30);
        this.canDelete = (this._auth.modules['masterdata'].authLevel >= 40);
        this.buildChoicesForm();
        this.loadFieldTypes();
    }

    ngOnInit() {
        if (this.addChoice) {
            this.choicesForm.setValue(this.selectedChoice as any);
        }
    }

    loadChoices() {
        this.choicesLoading = true;
        this.choices = [];
        this._apiService.setActionUrl('masterdata/choices');
        this._apiService.setFilter({field: 'parentId', operator: 'eq', value: this._parentId});
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.getStore().subscribe(response => {
            this.choicesLoading = false;
            if (response.success) {
                this.choices = response.data.records;
                let newChoice = new Choice();
                newChoice.parentId = this._parentId;
                newChoice.createdAt = 0;
                newChoice.modifiedAt = 0;
                newChoice.softDeleted = false;
                this.choices.push(newChoice);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    buildChoicesForm() {
        this.choicesForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'parentId': new FormControl({value: '', disabled: true}),
            'name': new FormControl({value: '', disabled: !this.canEdit}, Validators.required),
            'hasFields': new FormControl({value: '', disabled: !this.canEdit}),
            'isField': new FormControl({value: '', disabled: !this.canEdit}),
            'fieldType': new FormControl({value: '', disabled: !this.canEdit}),
            'replacesTag': new FormControl({value: '', disabled: !this.canEdit}),
            'quotationText': new FormControl({value: '', disabled: !this.canEdit}),
            'optionList': new FormControl({value: '', disabled: !this.canEdit}),
            'children': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAtDate': new FormControl({value: '', disabled: true}),
            'modifiedAtDate': new FormControl({value: '', disabled: true}),
        });
    }

    loadFieldTypes() {
        this.fieldTypes.push({value: '', label: 'Kies veldtype...'});
        this.fieldTypes.push({value: 'TXT', label: 'Tekst'});
        this.fieldTypes.push({value: 'NUM', label: 'Geheel getal'});
        this.fieldTypes.push({value: 'FLO', label: 'Decimaal getal'});
        this.fieldTypes.push({value: 'OPT', label: 'Optielijst'});
        this.fieldTypes.push({value: 'CB', label: 'Vinkje'});
        this.fieldTypes.push({value: 'DAT', label: 'Datum'});
    }

    saveChoice() {
        let choice = this.choicesForm.getRawValue();
        choice.parentId = this._parentId;
        if (choice.hasFields == '') {
            choice.hasFields = 0;
        }
        if (choice.isField == '') {
            choice.isField = 0;
        }
        this._apiService.setActionUrl('masterdata/choices');
        this._apiService.saveRecord(choice).subscribe(response => {
            if (response.success) {
                this.loadChoices();
                this.choiceEdit = false;
                this.reloadChoices.emit(true);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    deleteChoice(id: string) {
        this._apiService.setActionUrl('masterdata/choices');
        this._apiService.deleteRecord(id).subscribe(response => {
            if (response.success) {
                this.reloadChoices.emit(true);
                this.visibleChange.emit(false);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    cancelEditChoice() {
        this.visibleChange.emit(false);
    }

    selectChoice(event) {
        this.selectedChoice = event.data;
    }

    editChoice(data: Choice) {
        this.selectedChoice = data;
        this.selectedChoice.parentId = this._parentId;
        this.selectedChoice.createdAtDate = new Date(data.createdAt);
        this.selectedChoice.modifiedAtDate = new Date(data.modifiedAt);
        this.choiceEdit = true;
        this.choicesForm.controls.isField.enable();
        this.choicesForm.controls.hasFields.enable();
        if (this.selectedChoice.isField) {
            this._isFieldFieldSet = true;
            this.choicesForm.controls.hasFields.disable();
        } else {
            this._isFieldFieldSet = false;
        }
        if (this.selectedChoice.hasFields) {
            this.choicesForm.controls.isField.disable();
        }
        this.choicesForm.setValue(this.selectedChoice as any);
    }

    reload(event) {
        if (event.value) {
            this.choiceEdit = false;
        }
    }

    hasFieldsChange(event) {
        if (event.checked) {
            this.choicesForm.controls.isField.disable();
        } else {
            this.choicesForm.controls.isField.enable();
        }
    }

    isFieldChange(event) {
        if (event.checked) {
            this.choicesForm.controls.hasFields.disable();
            this._isFieldFieldSet = true;
        } else {
            this.choicesForm.controls.hasFields.enable();
            this._isFieldFieldSet = false;
        }
    }

    fieldTypeChange(event) {
        if (event.value === 'OPT') {
            this._isOptionList = true
        }
    }
}
