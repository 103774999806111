<div>
    <div id="layout-topbar">
        <a href="#" class="menu-button" (click)="toggleMenu($event)">
            <i class="fa fa-bars"></i>
        </a>
        <a href="#" class="logo" [routerLink]="['/']">
            <img id="logoContent" alt="logo" src="../assets/images/UdoLogo.jpg"/>
        </a>
        <ul class="topbar-menu">
            <div *ngIf="_auth.authenticated" style="margin-top: 15px">
                <button pButton type="text" class="pButton floatRight" icon="fa fa-sign-out" [style]="{'height': '100%'}" (click)="_auth.doLogout()" label=" {{userName}}"></button>
            </div>
        </ul>
    </div>
    <div id="layout-sidebar" [ngClass]="{'active': _mobileMenuActive}">
        <div style="text-align: center">{{ _auth.getSettings().appName }}</div>
        <div class="layout-menu" *ngIf="_auth.authenticated">
            <ng-template ngFor let-menuItem [ngForOf]="menuItems">
                <a class="menuButton" *ngIf="menuItem" [routerLink]="[menuItem.path]" (click)="toggleMenu($event)">{{ menuItem.title | translate }}</a>
            </ng-template>
        </div>
    </div>
    <div id="layout-content">
        <div class="content-section">
            <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
            <p-dialog *ngIf="_auth.messages.length > 0" appendTo="body" [visible]="true" header="{{ 'base.messages' | translate }}" [closable]="false">
                <p-messages [(value)]="_auth.messages"></p-messages>
            </p-dialog>
            <router-outlet></router-outlet>
        </div>
    </div>
    <div id="layout-footer">
        <div class="clearfix">
            <span class="floatLeft"><a href="https://www.dj-consultancy.nl" target="_blank">DJ Consultancy</a></span>
            <span class="floatRight">Versie: {{ version }} - Copyright &copy; 2019 - {{ endYear }}</span>
        </div>
    </div>
</div>
