import {User} from "../users/users.models";
import {SelectItem} from 'primeng/api/selectitem';


export class BaseModelShared {
    id: string = '';
    createdAt: number = 0;
    createdAtDate: Date = new Date();
    modifiedAt: number = 0;
    modifiedAtDate: Date = new Date();
    softDeleted: boolean = false;
}

export class BaseModel extends BaseModelShared {
    creatorId: string;
    creator: User;
    modifierId: string;
    modifier: User;
}

export class Country {
    id: string;
    country: string;
}

export class NameModel extends BaseModel {
    name: string;
}

export class Setting {
    id: string;
    key: string;
    value: any;
    displayValue: any;
    type: string;
    module: string;
    options: SelectItem[] = [];
}
