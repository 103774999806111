/**
 * This file is part of the <<projectName>> project
 * This project is licensed under the license what can be found on https://www.dj-consultancy.nl/licenses/eolmodules.pdf
 *
 * Id: $id$
 * File: groups
 * Author: Pieter van de Ven
 * Copyright: Pieter van de Ven / DJ Consultancy
 * Date:
 * License: <<fill in>>
 */

import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";
import {Group, GroupDropDown} from "./groups.models";

@Injectable()
export class GroupsService {
    public groups: Group[];
    public groupsDropDown: GroupDropDown[];

    constructor(private _apiService: ApiService, private _authService: AuthService) { }

    public getStore(): void {
        this._apiService.setActionUrl('groups/groups');
        this._apiService.getStore()
            .subscribe((resp) => {
                    if (resp.success) {
                        this.groups = resp.data.records;
                    } else {
                        this._authService.addError(resp.errorMsg);
                    }

            }, error => {
                this._authService.addError(error.error.text);
            });
    }

    public getDropDown() {
        this._apiService.setActionUrl('groups/groups');
        this._apiService.getDropDown(JSON.stringify(['name']))
            .subscribe((resp) => {
                    if (resp.success) {
                        this.groupsDropDown = resp.data.records;
                    } else {
                        this._authService.addError(resp.errorMsg);
                    }

            }, error => {
                this._authService.addError(error.error.text);
            });
    }

}
