import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder} from "@angular/forms";
import {Order, OrderDetailRecipe, OrderPart, OrderPartsFinish, OrderPartsFinishChoice} from "./orders.models";
import {Choice, Finish} from "../masterdata/masterdata.models";
import {ConfirmationService, MenuItem, SelectItem} from "primeng/api";
import {ShortcutInput} from "ng-keyboard-shortcuts";

@Component({
    selector: 'orderFinishes',
    templateUrl: './finishes.component.html',
    styleUrls: ['./orders.component.css'],
    providers: [ConfirmationService]
})
export class FinishesComponent implements OnInit, AfterViewInit {

    public orderPartsFinishes: OrderPartsFinish[] = [];
    public selectedFinish: OrderPartsFinish = new OrderPartsFinish();
    public selectedRecipe: OrderDetailRecipe = new OrderDetailRecipe();

    private _orderPartId: string;
    public _finishesLoading: boolean = false;
    private _orderPart: OrderPart;
    private _columns: number = 3;
    public _canEdit: boolean;
    public _canDelete: boolean;
    public _canAdd: boolean;
    public _visible: boolean = false;
    public _saveDetails: boolean = false;
    public _firstLoad: boolean = false;
    public _loadDetails: boolean = false;
    public _displayChoices: boolean = false;
    public _finishMenu: MenuItem[] = [];
    public _kbShortCuts: ShortcutInput[] = [];

    public _finishes: SelectItem[] = [];
    public _finishesFiltered: SelectItem[] = [];
    public _showPartsTree: boolean = false;
    public _choices: OrderPartsFinishChoice[] = [];
    public _allChoices: Choice[] = [];
    public maxCols: number = 0;
    public recipes: OrderDetailRecipe[] = [];
    public newRecipe: boolean = false;
    public showRecipeDialog: boolean = false;
    public recipeFormItems: any = {};
    public choicesLevelOne: any[] = [];

    @Input() get part(): OrderPart {
        return this._orderPart;
    }

    set part(part: OrderPart) {
        this._orderPart = part;
        this._orderPartId = part.id;
        if (this._orderPart.roofingType == null) {
            this._showPartsTree = true;
        }
        this._firstLoad = true;
        this.loadFinishes();
    }

    //@Input() labourCost: number;

    @Input() order: Order;

    @Input() set orderReadOnly(readOnly: boolean) {
        if (readOnly) {
            this._canEdit = false;
        } else {
            this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        }
    }

    @Input() get visible(): boolean {
        return this._visible;
    }

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    set visible(visible: boolean) {
        this._visible = visible;
    }

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder) {
        this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        this._canAdd = (this._auth.modules['orders'].authLevel >= 30);
        this._canDelete = (this._auth.modules['orders'].authLevel >= 40);
        if (this._canDelete) {
            this._columns = this._columns + 1;
        }
        this._finishMenu = [
            {label: 'Kopiëer afwerkingen', icon: 'fa fa-copy', command: (event) => this.copyFinish()},
            {label: 'Plak afwerkingen', icon: 'fa fa-paste', command: (event) => this.pasteFinish()}
        ]

    }

    ngOnInit(): void {
        this.loadFinishesDD();
        this._saveDetails = false;
        this._firstLoad = true;
        if (this._canEdit) {
            this.maxCols = 5;
        } else {
            this.maxCols = 4;
        }
        this.loadRecipes([]);
    }

    ngAfterViewInit(): void {
        this._kbShortCuts.push({
            key: 'ctrl + c',
            preventDefault: true,
            description: 'Kopiëer afwerkingen',
            label: "Commando's",
            command: event => this.copyFinish()
        }, {
            key: 'ctrl + v',
            preventDefault: true,
            description: 'Plak aftwerkingen',
            label: "Commando's",
            command: event => this.pasteFinish()
        });
    }

    selectInputContent(event) {
        event.target.select();
    }

    loadFinishes() {
        this._finishesLoading = true;
        this._apiService.setActionUrl('orders/orderpartfinishes');
        this._apiService.setFilter({field: 'orderPartId', value: this._orderPartId, operator: 'eq'});
        this._apiService.setSort({field: 'sortOrderNumber', direction: 'ASC'});
        this._apiService.getStore().subscribe(response => {
            this._finishesLoading = false;
            if (response.success) {
                this.orderPartsFinishes = response.data.records;
                this.addRow();
                if (this._firstLoad) {
                    this.selectFinish({data: this.orderPartsFinishes[0]});
                    this._firstLoad = false;
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadFinishesDD() {
        this._apiService.setActionUrl('masterdata/finishes');
        this._apiService.setSort({field: 'code', direction: 'ASC'});
        let operator: 'eq' | 'gt' | 'lt' | 'ne' | 'ge' | 'le' | 'IN' | 'LIKE' = 'ge';
        if (this.order.uisVersion < 3) {
            operator = 'le'
        }
        this._apiService.setFilter({field: 'uisVersion', operator: operator, value: this.order.uisVersion})
        this._apiService.getDropDown(JSON.stringify(['code', 'description']), 'id', 'N').subscribe(response => {
            if (response.success) {
                this._finishes = this._finishesFiltered = response.data.records;
                //this.allFinishes();
            }
        });
    }

    selectFinish(event) {
        this._displayChoices = false;
        this.selectedFinish = event.data;
        this.choicesLevelOne = [];
        this.selectedFinish.choices.forEach(function(choice) {
            this.choicesLevelOne.push(choice.choiceId);
        }, this);
        this.loadRecipes(this.choicesLevelOne);
        this.newRecipe= false;
        this.orderPartsFinishes.forEach(function(finish, index) {
            if (finish.id === this.selectedFinish.id) {
                this.orderPartsFinishes[index] = this.selectedFinish;
            }
        }, this);
    }

    changeFinish(event, row: number, finish: OrderPartsFinish) {
        finish.finishId = event.value;
        this._apiService.setActionUrl('masterdata/finishes');
        this._apiService.setFilter({field: 'id', value: event.value, operator: 'eq'});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                finish.finish = response.data.records[0];
                finish.description = finish.finish.description.replace("??", finish.size.toString());
                this.orderPartFinishSave(finish);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    orderPartFinishSave(partsFinish: OrderPartsFinish, reload: boolean = false) {
        this._loadDetails = false;
        if (partsFinish.finishId.length == 0) {
            return;
        }
        if (this.order.uisVersion >= 3) {
            partsFinish.choices = this.saveChoices(partsFinish.choices);
        }
        partsFinish.orderPartId = this._orderPartId;
        this._apiService.setActionUrl('orders/orderpartfinishes');
        this._apiService.saveRecord(partsFinish).subscribe(response => {
            if (response.success) {
                partsFinish.id = response.data.records[0].id;
                partsFinish.finishText = response.data.records[0].finishText;
                this._loadDetails = true;
                if (reload) {
                    this.loadFinishes();
                } else {
                    this.selectedFinish = {...partsFinish};
                    if (this.orderPartsFinishes[this.orderPartsFinishes.length - 1].id !== '') {
                        this.addRow();
                    }
                    this.orderPartsFinishes.forEach(function(op, index) {
                        if (op.id === this.selectedFinish.id) {
                            this.orderPartsFinishes[index].finishText = this.selectedFinish.finishText;
                        }
                    }, this);
                }
                this.loadRecipes(this.choicesLevelOne);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    orderPartFinishDelete(partsFinish: OrderPartsFinish, row: number) {
        this._apiService.setActionUrl('orders/orderpartfinishes');
        if (partsFinish.id == '') {
            return;
        }
        this._apiService.deleteRecord(partsFinish.id).subscribe(response => {
            if (response.success) {
                this.loadFinishes();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    orderPartFinishMove(partsFinish: OrderPartsFinish, newRow: number) {
        let newSortOrderNumber = partsFinish.sortOrderNumber;
        partsFinish.sortOrderNumber = this.orderPartsFinishes[newRow].sortOrderNumber;
        this.orderPartsFinishes[newRow].sortOrderNumber = newSortOrderNumber;
        this.orderPartFinishSave(this.orderPartsFinishes[newRow]);
        this.orderPartFinishSave(partsFinish, true);
    }

    hideDialog() {
        this._visible = false;
        this.visibleChange.emit(this._visible);
    }

    addRow() {
        if (this._canAdd) {
            let newFinish = new OrderPartsFinish();
            newFinish.orderPartId = this._orderPartId;
            newFinish.size = this._orderPart.size;
            newFinish.finishId = '';
            newFinish.choices = [];
            let lastNumber = 0;
            if (this.orderPartsFinishes.length > 0) {
                lastNumber = this.orderPartsFinishes[this.orderPartsFinishes.length - 1].sortOrderNumber;
            }
            newFinish.sortOrderNumber = lastNumber + 1;
            newFinish.finish = new Finish();
            this.orderPartsFinishes.push(newFinish);
        }
    }

    filterFinishes(event) {
        this._finishesFiltered = this._st.filterAutoComplete(event, this._finishes);
    }

    allFinishes() {
        this._finishesFiltered = this._finishes;
    }

    copyFinish() {
        this._st.copyFinish(this.selectedFinish.id);
    }

    pasteFinish() {
        let orgFinishId = this._st.mergeFinish();
        this._apiService.setActionUrl('orders/orderpartfinishes');
        this._apiService.specialPostAction(JSON.stringify({orgFinishId: orgFinishId, newFinishId: this.selectedFinish.id}), 'mergeFinish')
            .subscribe(response => {
                this.selectedFinish = new OrderPartsFinish();
                if (response.success) {
                    this.selectedFinish = response.data.records[0];
                }
            });
    }

    saveChoices(choices: OrderPartsFinishChoice[]): OrderPartsFinishChoice[] {
        let retChoices: OrderPartsFinishChoice[] = [];
        choices.forEach(function (choice) {
            if (choice.choiceId !== '--') {
                if (choice.subChoices.length > 0) {
                    choice.subChoices = this.saveChoices(choice.subChoices);
                }
                retChoices.push(choice);
            }
        }, this)
        return retChoices;
    }

    displayChoices(orderPartFinishId) {
        if (orderPartFinishId == this.selectedFinish.id)
            this._displayChoices = !this._displayChoices;
    }

    emitChoiceChange(event) {
        this.selectedFinish.choices = event;
        this.orderPartFinishSave(this.selectedFinish);

        this.choicesLevelOne = [];
        event.forEach(function(choice) {
            this.choicesLevelOne.push(choice.choiceId);
        }, this);
        this.loadRecipes(this.choicesLevelOne);
    }

    loadRecipes(choicesLevelOne: any = []) {
        this._apiService.setActionUrl('orders/orderdetailrecipes');
        this._apiService.specialPostAction(JSON.stringify({choices: choicesLevelOne, finishId: this.selectedFinish.finishId, roofingTypeId: this._orderPart.roofingTypeId, orderType: this.order.isConstruction ? 'C' : 'R'}), 'filterRecipes').subscribe(response => {
            if (response.success) {
                this.recipes = response.data.records;
                if (this.selectedFinish.recipeId !== '') {
                    this.selectedRecipe = this.recipes.find(recipe => recipe.id == this.selectedFinish.recipeId);
                    if (this.selectedRecipe == undefined) {
                        this.selectedRecipe = new OrderDetailRecipe();
                    }
                } else {
                    this.selectedRecipe = new OrderDetailRecipe();
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    recipeSelected(recipe: OrderDetailRecipe) {
        this._apiService.setActionUrl('orders/orderpartfinishes');
        let sendObject: any = {};
        sendObject.recipeId = recipe.id;
        sendObject.orderPartFinishId = this.selectedFinish.id;
        sendObject.processId = '';

        if (this.selectedFinish.recipeId !== '') {
            console.info('Recipe already selected');
            // Set alert warning to delete the details of the current recipe, added lines will stay in tact

        }

        if (this.selectedFinish.details.length > 0) {
            console.info('Already details');
        }

        this._apiService.specialPostAction(JSON.stringify(sendObject), 'selectRecipe').subscribe(response => {
            if (response.success) {
                // reload details and finishes
                this._loadDetails = true;
                this.selectFinish({data: response.data.records[0]});
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    preSaveRecipe() {
        if (this.selectedFinish.choices.length == 0) {
            this._auth.addInfo('Afwerkingen zonder keuzes kunnen niet als samenstelling opgeslagen worden.');
            return;
        }
        if (this.selectedFinish.details.length == 0) {
            this._auth.addInfo('Afwerkingen zonder details kunnen niet als samenstelling opgeslagen worden.');
            return;
        }
        this.newRecipe = (this.selectedFinish.recipeId === '');
        this.showRecipeDialog = true;
        this.recipeFormItems.name = this.selectedRecipe.name;
        this.recipeFormItems.description = this.selectedRecipe.description;
    }

    saveRecipe() {
        let sendObject: any = {};
        sendObject.orderPartFinishId = this.selectedFinish.id;
        sendObject.recipeId = this.selectedRecipe.id;
        sendObject.newRecipe = this.newRecipe;
        sendObject.recipeFormItems = this.recipeFormItems;
        sendObject.orderType = this.order.isConstruction ? 'C' : 'R';
        sendObject.roofingTypeId = this._orderPart.roofingTypeId;
        this._apiService.setActionUrl('orders/orderdetailrecipes');
        this._apiService.specialPostAction(JSON.stringify(sendObject), 'saveRecipe').subscribe(response => {
            if (response.success) {
                this.selectedFinish.choices = response.data.records[0].choices;
                this.showRecipeDialog = false;
                this.loadRecipes(this.choicesLevelOne);
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    changeProcess(event, row: number, finish: OrderPartsFinish) {
        finish.processId = event.value;
        this._apiService.setActionUrl('masterdata/processes');
        this._apiService.setFilter({field: 'id', value: event.value, operator: 'eq'});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                finish.process = response.data.records[0];
                this.orderPartFinishSave(finish);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    addDetail(details) {
        this.selectedFinish.details = details;
    }
}
