import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {GroupsComponent} from "./groups.component";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        GroupsComponent
    ]
})
export class GroupsModule {}