import {Component, OnInit} from '@angular/core';
import {User, UserSettings} from "./users.models";
import {ApiService, responseModel} from "../api.service";
import {AuthService} from "../auth.service";
import {TranslateService} from "@ngx-translate/core";
import {SupportTools} from "../support.tools";
import {ConfirmationService, MenuItem, SelectItem} from "primeng/api";
import {DatePipe} from "@angular/common";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Group} from "../groups/groups.models";
import {Setting} from "../base/base.model";

@Component({
    selector: 'app-user',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

    public showTable: boolean = true;
    public displayDialog: boolean = false;
    public displayPwd: boolean = false;
    public selectedUser: User;
    public users: User[];
    public groups: Group[];
    public selectedGroups: string[];
    public loading: boolean;
    public newUser: boolean = false;
    public aclModules: any = [];
    public _recordTitle: string = '';
    public _dateFormats: SelectItem[] = [];
    public _timeFormats: SelectItem[] = [];
    public _langs: SelectItem[] = [];
    public _rows: SelectItem[] = [];
    public _expiresSeconds: SelectItem[] = [];
    public _separators: SelectItem[] = [];
    public _userForm: FormGroup;
    public _settingsForm: FormGroup;
    private _phantomRecord: User;
    private _userSaved = false;
    public _contextMenu: MenuItem[] = [];

    constructor(private _apiService: ApiService, public _auth: AuthService, private _transService: TranslateService, private _st: SupportTools,
                private _confService: ConfirmationService, private _datePipe: DatePipe, private _fb: FormBuilder) {
    }

    ngOnInit() {
        this.loadUsers();
        this.loadGroups();
        // create FormGroups
        this._userForm = this._fb.group({
                'id': new FormControl({value: '', disabled: true}),
                'fullName': new FormControl('', Validators.required),
                'userName': new FormControl('', Validators.required),
                'password': new FormControl('', this._st.checkPassword),
                'password2': new FormControl(''),
                'email': new FormControl('', Validators.email),
                'lastLogin': new FormControl(''),
                'createdAt': new FormControl(''),
                'modifiedAt': new FormControl(''),
                'loginEnabled': new FormControl(''),
                'reference': new FormControl(''),
                'forcePasswordChange': new FormControl(''),
                'softDeleted': new FormControl({value: '', disabled: true}),
                'creatorId': new FormControl({value: '', disabled: true}),
                'modifierId': new FormControl({value: '', disabled: true}),
                'creator': new FormControl({value: '', disabled: true}),
                'modifier': new FormControl({value: '', disabled: true})
            }, {validator: this._st.equalValueValidator('password', 'password2')}
        );
        this._settingsForm = this._fb.group({
            'dateFormat': new FormControl('', Validators.required),
            'timeFormat': new FormControl('', Validators.required),
            'dateTimeFormat': new FormControl({value: '', disabled: true}),
            'language': new FormControl('', Validators.required),
            'defaultRows': new FormControl('', Validators.required),
            'expiresSeconds': new FormControl('', Validators.required),
            'thousandSeparator': new FormControl('', Validators.required),
            'decimalSeparator': new FormControl('', Validators.required)
        });
        let currentTime = new Date().getTime();
        this._dateFormats.push({value: 'dd-MM-yyyy', label: this._datePipe.transform(currentTime, 'dd-MM-yyyy')});
        this._dateFormats.push({value: 'dd/MM/yyyy', label: this._datePipe.transform(currentTime, 'dd/MM/yyyy')});
        this._dateFormats.push({value: 'MM/dd/yyyy', label: this._datePipe.transform(currentTime, 'MM/dd/yyyy')});
        this._dateFormats.push({value: 'MM-dd-yyyy', label: this._datePipe.transform(currentTime, 'MM-dd-yyyy')});
        this._timeFormats.push({value: 'HH:mm', label: '24h'});
        this._timeFormats.push({value: 'hh:mm', label: '12h'});
        this._timeFormats.push({value: 'hh:mm a', label: '12h AM/PM'});
        this._timeFormats.push({value: 'HH:mm:ss', label: '24h + sec'});
        this._timeFormats.push({value: 'hh:mm:ss', label: '12h + sec'});
        this._timeFormats.push({value: 'hh:mm:ss a', label: '12h + sec AM/PM'});
        this._langs.push({value: 'nl', label: 'Nederlands'});
        this._langs.push({value: 'en', label: 'English/American'});
        this._rows.push({value: 5, label: '5'});
        this._rows.push({value: 10, label: '10'});
        this._rows.push({value: 25, label: '25'});
        this._rows.push({value: 50, label: '50'});
        this._rows.push({value: 100, label: '100'});
        this._expiresSeconds.push({value: 0, label: this._transService.instant('base.never')});
        this._expiresSeconds.push({value: 300, label: '5 min.'});
        this._expiresSeconds.push({value: 600, label: '10 min.'});
        this._expiresSeconds.push({value: 900, label: '15 min.'});
        this._expiresSeconds.push({value: 3600, label: '60 min.'});
        this._separators.push({value: ',', label: ','});
        this._separators.push({value: '.', label: '.'});

        this._transService.get(['base.edit', 'base.delete']).subscribe(
            response => {
                this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editUser()});
                if (this._auth.modules['users'].authLevel >= 20) {
                }
                if (this._auth.modules['users'].authLevel >= 40) {
                    this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteUser()});
                }
            }
        )
    }

    loadUsers() {
        this.loading = true;
        this._apiService.setActionUrl('users/users');
        if (this._auth.modules['users'].authLevel < 100) {
            this._apiService.setFilter({field: 'id', value: this._auth.getUser().id, operator: 'eq' })
        }
        this._apiService.getStore().subscribe(
            resp => {
                if (resp.success) {
                    this.users = resp.data.records;
                } else {
                    this._auth.addError(resp.errorMsg);
                }
                this.loading = false;
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    loadGroups() {
        this._apiService.setActionUrl('groups/groups');
        this._apiService.getStore().subscribe(
            resp => {
                if (resp.success) {
                    this.groups = resp.data.records;
                    this.selectedGroups = [];
                } else {
                    this._auth.addError(resp.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    editUser() {
        this.displayDialog = true;
        this._recordTitle = this.selectedUser.fullName;
        this._settingsForm.setValue(this.selectedUser.settings as any);
        let importUser = this.selectedUser;
        importUser.password = '';
        importUser.password2 = '';
        delete importUser.settings;
        this.loadUserGroups(importUser.id);
        this.loadUserRights(importUser.id);
        this._userForm.setValue(importUser as any);
        this._userForm.patchValue({'forcePasswordChange': this.selectedUser.forcePasswordChange});
        // Disable username field, can never be changed
        this._userForm.get('userName').disable();
    }

    onRowDblclick($event, user) {
        if (this._auth.modules['users'].authLevel >= 20) {
            this.selectedUser = user;
            this.editUser();
        }
    }

    loadUserGroups(userId) {
        this._apiService.setActionUrl('groups/groups');
        this._apiService.specialPostAction(JSON.stringify({userId: userId}), 'member').subscribe(
            resp => {
                if (resp.success) {
                    this.selectedGroups = resp.data.records;
                } else {
                    this._auth.addError(resp.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    loadUserRights(userId) {
        this._apiService.setActionUrl('base/acl');
        this._apiService.specialPostAction(JSON.stringify({model: 'user', userId: userId, groupId: ''}), 'modulerights').subscribe(
            response => {
                if (response.success) {
                    this.aclModules = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );

    }

    createUser() {
        this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('users.user'));
        this.selectedUser = new User();
        this.selectedUser.settings = new UserSettings();
        this._userForm.reset();
        this._settingsForm.reset();
        this._settingsForm.setValue(this.selectedUser.settings as any);
        this.displayDialog = true;
        this.newUser = true;
        // Be sure that username is enabled after editing other user
        this._userForm.get('userName').enable();
    }

    deleteUser() {
        this._apiService.setActionUrl('users/users');
        this._apiService.deleteRecord(this.selectedUser.id).subscribe(
            response => {
                if (response.success) {
                    this.selectedUser = null;
                    this.loadUsers();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    cancel() {
        this.displayDialog = false;
        this.selectedUser = null;
        this._phantomRecord = null;
        this.newUser = false;
        this._userForm.reset();
        this._settingsForm.reset();
    }

    uploadFile(event, user: User) {
        event.formData.append('id', user.id);
    }

    uploadReady(event) {
        let response:responseModel = JSON.parse(event.xhr.response);
        if (!response.success) {
            this._auth.addError(response.errorMsg);
        }
    }

    checkForm() {
        this._st.getFormValidationErrors(this._userForm);
        this._st.getFormValidationErrors(this._settingsForm);
    }

    saveUser() {
        this._apiService.setActionUrl('users/users');
        this.selectedUser = this._userForm.getRawValue();
        this.selectedUser.settings = this._settingsForm.getRawValue();
        if (this.newUser) {
            this._apiService.createRecord(JSON.stringify(this.selectedUser)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedUser)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    private _afterSave(response) {
        if (response.success) {
            let userId: string = '';
            if (this.newUser) {
                userId = response.newId;
            } else {
                userId = this.selectedUser.id;
            }
            if (this.selectedUser.id === this._auth.getUser().id) {
                // Load new settings to session
                let sessionResp = JSON.parse(sessionStorage.getItem('authentication'));
                this.selectedUser.settings.dateTimeFormat = this.selectedUser.settings.dateFormat + ' ' + this.selectedUser.settings.timeFormat;
                sessionResp.user = this.selectedUser;
                sessionResp.userSettings = this.selectedUser.settings;
                this._auth.doLogin(sessionResp);
            }
            this._apiService.specialPostAction(JSON.stringify({groups: this.selectedGroups, userId: userId}), 'saveusergroups').subscribe(
                resp => {
                    if (!resp.success) {
                        this._auth.addError(resp.errorMsg);
                    }
                },
                err => {
                    this._auth.addError(err.error.text);
                }
            );
            this._apiService.setActionUrl('base/acl');
            this._apiService.specialPostAction(JSON.stringify({aclLevels: this.aclModules, userId: userId, model: 'user'}),
                'savemodulerights').subscribe(
                resp => {
                    if (!resp.success) {
                        this._auth.addError(resp.errorMsg);
                    }
                },
                err => {
                    this._auth.addError(err.error.text);
                }
            );
            this.selectedUser = null;
            this._phantomRecord = null;
            this.displayDialog = false;
            this.newUser = false;
            this._userSaved = true;
            this.loadUsers();
        } else {
            this._auth.addError(response.errorMsg);
        }

    }

    generatePassword() {
        let pwd = this._st.generateRandomString(12);
        this._userForm.patchValue({password: pwd, password2: pwd});
        this._confService.confirm({
            message: this._transService.instant('base.password') + ': ' + pwd,
            accept: () => {
                console.info(pwd);
            }
        });
    }

    selectUser(event) {
        this.selectedUser = this._st.cloneRecord(event.data, new User());
    }

}
