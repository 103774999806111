import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RouterModule} from "@angular/router";
import {APP_ROUTER_PROVIDERS} from "../routes";
import {AuthService} from "./auth.service";
import {SharedModule} from "./shared.module";
import {CustomTransLoader} from "./terms.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "./base/base.component";
import {DatePipe, registerLocaleData} from "@angular/common";
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import {LoginModule} from "./login/login.module";
import {UsersModule} from "./users/users.module";
import {ModulesModule} from "./modules/modules.module";
import {GroupsModule} from "./groups/groups.module";
import localeNL from '@angular/common/locales/nl';
import {MasterDataModule} from "./masterdata/masterdata.module";
import {ProductsModule} from "./products/products.module";
import {KeyboardShortcutsModule} from "ng-keyboard-shortcuts";
import {OrdersModule} from "./orders/orders.module";

registerLocaleData(localeNL);

@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        APP_ROUTER_PROVIDERS,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new CustomTransLoader(http),
                deps: [HttpClient]
            }
        }),
        NgIdleKeepaliveModule.forRoot(),
        LoginModule,
        UsersModule,
        ModulesModule,
        GroupsModule,
        MasterDataModule,
        ProductsModule,
        OrdersModule,
        KeyboardShortcutsModule.forRoot(),
        SharedModule
    ],
    providers: [
        AuthService,
        DatePipe
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

}
