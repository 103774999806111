<p-dialog appendTo="body" [closable]="true" (onHide)="cancelPGF()" styleClass="ui-g-8 pDialog" [modal]="true" positionTop="250" [visible]="true">
    <div class="ui-g">
        <div class="ui-g-3"><label for="productGroup">{{ 'productGroup.products' | translate }}</label></div>
        <div class="ui-g-9"><span id="productGroup">{{ _pgf.productGroup.description }}</span></div>
    </div>
    <div class="ui-g">
        <div class="ui-g-3"><label for="finish">{{ 'orders.finish' | translate }}</label></div>
        <div class="ui-g-9"><span id="finish">{{ _pgf.finish.code }} - {{ _pgf.finish.description }}</span></div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="savePGF()" label="{{ 'base.save' | translate }}"></button>
        <button type="button" pButton icon="pi pi-times" (click)="cancelPGF()" label="{{ 'base.cancel' | translate }}" *ngIf="!_event.mustComplete"></button>
    </p-footer>
</p-dialog>
