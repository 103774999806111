<div style="height: calc(50% - 60px)">
    {{ 'orders.recipes' | translate }}<br />
    <p-table tableStyleClass="djc-table" [value]="detailRecipes" (onRowSelect)="selectRecipe($event)" selectionMode="single" [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 40em;">{{ 'base.strName' | translate }}</th>
                <th>{{ 'base.strDescription' | translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-recipe>
            <tr [pSelectableRow]="recipe">
                <td style="width: 40em;">{{ recipe.name }}</td>
                <td [title]="recipe.description">{{ recipe.description }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
