<div id="moduleMenu">
    <span *ngIf="selectedItem" class="itemName floatLeft">{{ selectedItem }}</span>
    <span id="masterdataMenuTitle" class="floatRight">{{ 'masterdata.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button *ngIf="_showPaymentTerms || _showFinishes || _showWarranties || _showShippingMethods || _showSettings || _showRoofingTypes || _showProcesses || _showTexts"
                class="pButton floatLeft" type="button" pButton icon="fa fa-refresh" (click)="loadData()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="_showFinishes" class="pButton floatLeft" type="button" pButton icon="fa fa-refresh" (click)="switchVersion()" label="{{ 'base.switchVersion' | translate }} {{ uisVersion }}"></button>
        <button *ngIf="_auth.modules['masterdata'].authLevel >= 20 && (_showPaymentTerms || _showFinishes || _showWarranties || _showShippingMethods || _showRoofingTypes || _showProcesses || _showTexts)"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createItem()" label="{{ 'base.create' | translate }}"></button>
    </div>
    <div class="ui-g" style="height: 100%">
        <div class="ui-g-2 ui-md-2" style="height: calc(100% - 65px); padding-bottom: 35px;">
            <p-listbox [options]="_options" [optionLabel]="'label'" (onChange)="selectItem($event)" [(ngModel)]="_optionSelected">
                <p-header>
                    <i class="fa fa-filter"></i>
                    {{ 'masterdata.name' | translate }}
                </p-header>
            </p-listbox>
        </div>
        <div class="ui-g-10 ui-md-10">
            <p-table #fin *ngIf="_showFinishes" [loading]="finishesLoading" [value]="finishes" [rows]="_auth.defaultRows" [paginator]="false" [pageLinks]="5"
                     [(contextMenuSelection)]="selectedFinish" [contextMenu]="cmMenu" editMode="row" dataKey="id" [sortField]="'code'" [sortOrder]="1"
                     tableStyleClass="djc-table" [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'code'" style="width:15em;">Code
                            <p-sortIcon [field]="'code'"></p-sortIcon>
                        </th>
                        <th>{{ 'base.strDescription' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData" [pContextMenuRow]="rowData">
                        <td style="width:15em;">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.code" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.code}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td style="width: 100%">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.description" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.description}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em; text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="finishEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="finishEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="finishEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>
                    <tr *ngIf="selectedFinish.id == rowData.id">
                        <td colspan="4">
                            <choices [visible]="true" [parentId]="this.selectedFinish.id" [addChoice]="false" [level]="1" [description]="this.selectedFinish.description"></choices>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-table #war *ngIf="_showWarranties" [loading]="warrantiesLoading" [value]="warranties" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
                     [(contextMenuSelection)]="selectedWarranty" [contextMenu]="cmMenu" editMode="row" dataKey="id" [sortField]="'code'" [sortOrder]="1" tableStyleClass="djc-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="2">{{ 'base.strDescription' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData" [pContextMenuRow]="rowData">
                        <td colspan="2">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.description" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.description}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="warrantyEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="warrantyEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="warrantyEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>
                    <tr *ngIf="editing" [pEditableRow]="rowData" >
                        <td class="fa">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <textarea pInputTextarea type="text" [(ngModel)]="rowData.text" rows="10" cols="100"></textarea>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <djc-spinner [(ngModel)]="rowData.percentage" [min]="0" [max]="100" [step]="0.1" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                 [decimalSeparator]="_auth.getUserSettings().decimalSeparator" ></djc-spinner>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td></td>
                    </tr>
                </ng-template>
            </p-table>
            <p-table #war *ngIf="_showPaymentTerms" [loading]="paymentTermsLoading" [value]="paymentTerms" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
                     [(contextMenuSelection)]="selectedPaymentTerm" [contextMenu]="cmMenu" editMode="row" dataKey="id" [sortField]="'paymentTerm'" [sortOrder]="1" tableStyleClass="djc-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="2">{{ 'base.strDescription' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData" [pContextMenuRow]="rowData">
                        <td colspan="2">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.paymentTerm" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.paymentTerm}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="paymentTermEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="paymentTermEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="paymentTermEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-table #dt *ngIf="_showShippingMethods" [loading]="shippingMethodsLoading" [value]="shippingMethods" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
                     [(contextMenuSelection)]="selectedShippingMethod" [contextMenu]="cmMenu" editMode="row" dataKey="id" [sortField]="'code'" [sortOrder]="1" tableStyleClass="djc-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'base.strDescription' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData" [pContextMenuRow]="rowData">
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.shippingMethod" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.shippingMethod}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="shipEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="shipEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="shipEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-table #dt *ngIf="_showTexts" [loading]="textsLoading" [value]="texts" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
                     [(contextMenuSelection)]="selectedText" [contextMenu]="cmMenu" editMode="row" dataKey="id" [sortField]="'textItemId'" [sortOrder]="1" tableStyleClass="djc-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 20%;">{{ 'masterdata.textItem' | translate }}</th>
                        <th>Label</th>
                        <th>{{ 'base.strDescription' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData" [pContextMenuRow]="rowData">
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-dropdown [options]="_textItemOptions" [(ngModel)]="rowData.textItemId"></p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ getTextItem(rowData.textItemId) }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.label" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.label}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.text" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.text}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="textEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="textEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="textEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-table *ngIf="_showSettings" [loading]="settingsLoading" [value]="settings" [rows]="10" [paginator]="true" [pageLinks]="5"
                     editMode="row" dataKey="id" tableStyleClass="djc-table" [sortField]="'module'" [sortOrder]="1">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 20em">{{ 'base.setting' | translate }}</th>
                        <th>{{ 'base.value' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData">
                        <td>{{ rowData.module + '.' + rowData.key | translate }}</td>
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <textarea *ngIf="rowData.type == 'S'" type="text" [(ngModel)]="rowData.value" rows="10" cols="100"></textarea>
                                    <p-spinner *ngIf="rowData.type == 'N'" [(ngModel)]="rowData.value" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                               [decimalSeparator]="_auth.getUserSettings().decimalSeparator" step="0.01"></p-spinner>
                                    <p-checkbox [value]="rowData.value" binary="true" *ngIf="rowData.type == 'B'"></p-checkbox>
                                    <p-fileUpload *ngIf="rowData.type == 'F'" id="file" name="csvFile[]" accept=".jpg, .png" maxFileSize="5000000" chooseLabel="Browse"
                                                  [customUpload]="false" url="api/base/settings/upload" (onBeforeUpload)="uploadFile($event, rowData)"
                                                  (onUpload)="uploadReady($event)"></p-fileUpload>
                                    <p-dropdown *ngIf="rowData.type == 'D'" [options]="rowData.options" [(ngModel)]="rowData.value"></p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ rowData.displayValue }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="settingEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="settingEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="settingEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-table *ngIf="_showRoofingTypes" [loading]="roofingTypesLoading" [value]="roofingTypes" [rows]="_auth.defaultRows"
                     [paginator]="true" [pageLinks]="5" editMode="row" dataKey="id" tableStyleClass="djc-table" [sortField]="'code'" [sortOrder]="1">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'base.strDescription' | translate }}</th>
                        <th>{{ 'masterdata.availableFrom' | translate }}</th>
                        <th>{{ 'masterdata.availableTill' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="rowData" [pContextMenuRow]="rowData">
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData.description" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.description}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-calendar [ngClass]="'pCalendar'" [(ngModel)]="rowData.availableFromDate" [showIcon]="true"
                                                [dateFormat]="_auth.getUserSettings().dateFormat.substr(0, _auth.getUserSettings().dateFormat.length - 2)"></p-calendar>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <djc-date [timeInput]="rowData.availableFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-calendar [ngClass]="'pCalendar'" [(ngModel)]="rowData.availableTillDate" [showIcon]="true"
                                                [dateFormat]="_auth.getUserSettings().dateFormat.substr(0, _auth.getUserSettings().dateFormat.length - 2)"></p-calendar>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <djc-date [timeInput]="rowData.availableTill" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fa fa-pencil" class="ui-button-info" (click)="roofingTypeEditInit(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fa fa-check" class="ui-button-success" style="margin-right: .3em;margin-left: .2em"
                                    (click)="roofingTypeEditSave(rowData)"></button>
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                                    (click)="roofingTypeEditCancel(rowData, ri)"></button>
                        </td>
                    </tr>

                </ng-template>
            </p-table>
            <p-table *ngIf="_showProcesses" [loading]="processesLoading" [value]="processes" [rows]="_auth.defaultRows"
                     [paginator]="true" [pageLinks]="5" editMode="row" dataKey="id" tableStyleClass="djc-table" [sortField]="'description'" [sortOrder]="1">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'base.strDescription' | translate }}</th>
                        <th *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="width:8em;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                    <tr [pContextMenuRow]="rowData">
                        <td>{{rowData.description}}</td>
                        <td *ngIf="_auth.modules['masterdata'].authLevel >= 20" style="text-align:center">
                            <button pButton type="button" icon="fa fa-pencil" class="ui-button-info" (click)="processEditInit(rowData, ri)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-contextMenu #cmMenu [model]="cm" appendTo="body"></p-contextMenu>
        </div>
    </div>
</div>
<div *ngIf="_showProcessEdit">
    <p-dialog appendTo="body" [(visible)]="_showProcessEdit" styleClass="pDialog ui-g-6" [modal]="true" (onHide)="processEditCancel()">
        <div class="ui-g">
            <div class="ui-g-3"><label for="description">{{ 'base.strDescription' | translate }}</label></div>
            <div class="ui-g-9"><input pInputText type="text" [(ngModel)]="selectedProcess.description" id="description"></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="code">Code</label></div>
            <div class="ui-g-9"><input pInputText type="text" [(ngModel)]="selectedProcess.code" id="code" maxlength="5"></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="quotationText">{{ 'masterdata.quotationText' | translate }}</label></div>
            <div class="ui-g-9">
                <textarea pInputTextarea [(ngModel)]="selectedProcess.quotationText" id="quotationText" rows="5" cols="120"></textarea>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="pphConstruct">{{ 'masterdata.piecesPerHour' | translate }} {{ 'orders.construct' | translate | lowercase }}</label></div>
            <div class="ui-g-9"><djc-spinner id="pphConstruct" min="0" step="0.1" [(ngModel)]="selectedProcess.piecesPerHourConstruct" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                             [decimalSeparator]="_auth.getUserSettings().decimalSeparator" ></djc-spinner></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="pphRenovation">{{ 'masterdata.piecesPerHour' | translate }} {{ 'orders.renovation' | translate | lowercase }}</label></div>
            <div class="ui-g-9"><djc-spinner id="pphRenovation" min="0" step="0.1" [(ngModel)]="selectedProcess.piecesPerHourRenovation" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                             [decimalSeparator]="_auth.getUserSettings().decimalSeparator" ></djc-spinner></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="mlConstruct">{{ 'masterdata.materialLoss' | translate }} {{ 'orders.construct' | translate }}</label></div>
            <div class="ui-g-9"><djc-spinner id="mlConstruct" min="0" step="0.1" [(ngModel)]="selectedProcess.materialLossConstruct" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                             [decimalSeparator]="_auth.getUserSettings().decimalSeparator" ></djc-spinner></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3"><label for="mlRenovation">{{ 'masterdata.materialLoss' | translate }} {{ 'orders.renovation' | translate }}</label></div>
            <div class="ui-g-9"><djc-spinner id="mlRenovation" min="0" step="0.1" [(ngModel)]="selectedProcess.materialLossRenovation" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                             [decimalSeparator]="_auth.getUserSettings().decimalSeparator" ></djc-spinner></div>
        </div>
        <p-footer>
            <button type="button" pButton icon="pi pi-check" (click)="processEditSave()" label="{{ 'base.save' | translate }}"></button>
            <button type="button" pButton icon="pi pi-times" (click)="processEditCancel()" label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>

    </p-dialog>
</div>
<div *ngIf="_showBeforeDelete">
    <p-dialog appendTo="body" [(visible)]="_showBeforeDelete" styleClass="pDialog ui-g-6" [modal]="true" (onHide)="cancelDelete()">
        <h3>Weet je zeker dat je dit wil verwijderen?</h3>
        <p-footer>
            <button type="button" pButton icon="pi pi-check" (click)="deleteRecord()" label="Ja"></button>
            <button type="button" pButton icon="pi pi-times" (click)="cancelDelete()" label="Nee"></button>
        </p-footer>
    </p-dialog>
</div>
