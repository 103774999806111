import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationService, SelectItem} from "primeng/api";
import {Order, OrderFacility} from "./orders.models";
import {Product, ProductGroup, SelectedFacilityGroup} from "../products/products.model";

@Component({
    selector: 'orderFacilities',
    templateUrl: './facilities.component.html',
    styleUrls: ['./orders.component.css']
})
export class FacilitiesComponent implements OnInit {

    public _canEdit: boolean;
    public _canAdd: boolean;
    public _canDelete: boolean;
    public _canManage: boolean;

    public orderFacilities: OrderFacility[] = [];
    public _facilityProductsDD: SelectItem[] = [];
    public _facilityProductGroups: ProductGroup[] = [];
    public selectedFacility: OrderFacility = new OrderFacility();
    public _selectedVersion: Order = new Order();
    public selectedFacilityGroups: SelectedFacilityGroup[] = [];
    public selectedFacilityGroup: SelectedFacilityGroup = new SelectedFacilityGroup();
    public allSelected: boolean = false;
    public _dirtyRow: boolean = false;
    public _showRemark: boolean = false;

    @Input() get selectedVersion(): Order {
        return this._selectedVersion;
    }

    set selectedVersion(version: Order) {
        this._selectedVersion = version;
        this.loadSelectedFacilityGroups();
    }

    @Input() set orderSaving(saving: boolean) {
        if (saving && this._dirtyRow) {
            this.saveFacility(this.selectedFacility);
        }
    }
    @Output() selectedVersionChange: EventEmitter<Order> = new EventEmitter<Order>();

    @Output() saveOrder: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public _auth: AuthService, public _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _confirmationService: ConfirmationService) {
        this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        this._canAdd = (this._auth.modules['orders'].authLevel >= 30);
        this._canDelete = (this._auth.modules['orders'].authLevel >= 40);
        this._canManage = (this._auth.modules['orders'].authLevel > 40);
    }


    ngOnInit() {
        this.loadFacilityGroups();
    }

    loadFacilityGroups() {
        this._apiService.setActionUrl('products/groups');
        this._apiService.setSort({field: 'description', direction: 'ASC'});
        this._apiService.setFilter({field: 'productTypeId', operator: 'eq', value: this._auth.getSettings().facilitiesProductType});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this._facilityProductGroups = response.data.records;
                this.loadSelectedFacilityGroups();
            }
        });
    }

    loadFacilities() {
        this.orderFacilities = [];
        if (this.selectedFacilityGroup.selected) {
            this._apiService.setActionUrl('orders/orderfacilities');
            this._apiService.setFilter({field: 'orderId', operator: 'eq', value: this._selectedVersion.id});
            this._apiService.setFilter({field: 'facilityProductGroupId', operator: 'eq', value: this.selectedFacilityGroup.productGroupId});
            this._apiService.getStore().subscribe(response => {
                if (response.success) {
                    this.orderFacilities = response.data.records;
                    this.orderFacilities.push(this.addRow());
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        }
    }

    loadSelectedFacilityGroups() {
        this.selectedFacilityGroups = [];
        this.allSelected = true;
        let selected: boolean = false;
        this._facilityProductGroups.forEach(function(facilityGroup) {
            if (this._selectedVersion.facilityGroups.indexOf(facilityGroup.id) !== -1) {
                selected = true;
            } else {
                this.allSelected = false;
                selected = false;
            }
            this.selectedFacilityGroups.push({
                selected: selected,
                productGroupId: facilityGroup.id,
                description: facilityGroup.description
            });
        }, this);
    }

    loadProducts() {
        this._apiService.setActionUrl('products/products');
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.setFilter({field: 'productGroupId', value: this.selectedFacilityGroup.productGroupId, operator: 'eq'})
        this._apiService.getDropDown(JSON.stringify(['name']), 'id', 'Y', 0).subscribe(response => {
            if (response.success) {
                this._facilityProductsDD = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    changeFacility(event: any, facility: OrderFacility) {
        this._apiService.setActionUrl('products/products');
        this._apiService.setFilter({field: 'id', value: facility.facilityProductId, operator: 'eq'});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                let product: Product = response.data.records[0];
                facility.facilityProduct = product;
                facility.costPerUnit = product.productPrices[0].materialCost;
                this._dirtyRow = true;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    selectFacilityGroup(facilityGroup, load: boolean = true) {
        if (facilityGroup.selected) {
            this._selectedVersion.facilityGroups.push(facilityGroup.productGroupId);
        } else {
            this.allSelected = false;
            let currentIndex = this._selectedVersion.facilityGroups.indexOf(facilityGroup.productGroupId);
            this._selectedVersion.facilityGroups.splice(currentIndex, 1);
        }

        if (this._dirtyRow) {
            this.saveFacility(this.selectedFacility);
        }
        if (load) {
            this.selectedFacilityGroup = facilityGroup;
            this.loadFacilities();
        }

        this.selectedVersionChange.emit(this._selectedVersion);
        this.saveOrder.emit(true);
    }

    changeFacilityGroup(event) {
        if (this._dirtyRow) {
            this.saveFacility(this.selectedFacility);
        }
        this.selectedFacility = new OrderFacility();
        this.selectedFacilityGroup = event.data;
        this.loadFacilities();
        this.loadProducts();
    }

    selectAllFacilityGroups(selected) {
        this.selectedFacilityGroups.forEach(function (facilityGroup) {
            if (facilityGroup.selected !== selected) {
                facilityGroup.selected = selected;
                this.selectFacilityGroup(facilityGroup, false);
            }
        }, this);
        this.orderFacilities = [];
        this.selectedFacilityGroup = new SelectedFacilityGroup();
    }

    selectFacility(event) {
        if (this._dirtyRow && event.data.id !== this.selectedFacility.id) {
            this.saveFacility(this.selectedFacility);
        }
        this.selectedFacility = event.data;
    }

    saveFacility(facility: OrderFacility) {
        this._apiService.setActionUrl('orders/orderfacilities');
        this._apiService.saveRecord(facility).subscribe(response => {
            if (response.success) {
                this._dirtyRow = false;
                this.selectedFacility = response.data.records[0];
                this.loadFacilities();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    deleteFacility(facility: OrderFacility, ri) {
        if (facility.id == '')
            return;

        this._apiService.setActionUrl('orders/orderfacilities');
        this._apiService.deleteRecord(facility.id).subscribe(response => {
            if (response.success) {
                this.loadFacilities();
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    addRow(): OrderFacility {
        let row = new OrderFacility();
        row.orderId = this._selectedVersion.id;
        row.facilityProductGroupId = this.selectedFacilityGroup.productGroupId;
        return row;
    }

    rowVisible(facility): boolean {
        return (this._selectedVersion.facilityGroups.indexOf(facility.facilityProductGroupId) !== -1);
    }

    facilityTotalCost(): number {
        let totalCost = 0;
        this._selectedVersion.facilities.forEach(function(facility) {
            if (this._selectedVersion.facilityGroups.indexOf(facility.facilityProductGroupId) !== -1) {
                totalCost += facility.quantity * facility.multiplier * facility.costPerUnit;
            }
        }, this);
        return totalCost;
    }
}
