import {Component, EventEmitter, Input, Output} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder} from "@angular/forms";
import {Order, OrderDetail, OrderPartsFinish} from "./orders.models";
import {Product, ProductGroupFinish, ProductPrice, ProductProcess} from "../products/products.model";
import {SelectItem} from "primeng/api";
import {ProductsService} from "../products/products.service";
import {DomHandler} from 'primeng/dom';
import {Process} from "../masterdata/masterdata.models";
import {isNull} from "@angular/compiler/src/output/output_ast";

@Component({
    selector: 'orderDetails',
    templateUrl: './details.component.html',
    styleUrls: ['./orders.component.css']
})
export class DetailsComponent {

    public orderDetails: OrderDetail[] = [];
    public selectedOrderDetail: OrderDetail = new OrderDetail();
    public selectedProductTypeId: string = '';
    public productPrices:ProductPrice[] = [];
    public selectedProductPrice: ProductPrice = new ProductPrice();
    public _finishId: string;
    public _finish: OrderPartsFinish;
    public _order: Order = new Order();
    public _detailsLoading: boolean = false;
    public _columns: number = 8;
    public _canEdit: boolean;
    public _canDelete: boolean;
    public _canAdd: boolean;
    public _orderDetailsDirtyRows: boolean[] = [];
    public _productTypes: SelectItem[] = [];
    public _productTypeProcesses: SelectItem[] = [];
    public _productGroups: SelectItem[] = [];
    public _products: SelectItem[] = [];
    public _productsFiltered: SelectItem[] = [];
    public _completeProducts: Product[] = [];
    public _productGroupFinishes: ProductGroupFinish[] = [];
    public _showPGF: boolean = false;
    public _showProductPrices: boolean = false;
    public _sendToPGF: any = {};
    public _productProcesses: ProductProcess[] = [];
    public _showPP: boolean = false;
    public _sendToPP: any = {};
    public _productSelector: boolean = false;
    public rowSelected: number = 0;
    public productSelection: any = {productId: '', productGroupId: '', productTypeId: '', processId: ''};
    public _allSuppliers: boolean = false;
    public _productTypeEvent: SelectItem;
    public savedEvent: SelectItem;
    public orderDetailRow: number;
    public _showDetailTextPopup: boolean = false;

    @Input() get finish(): OrderPartsFinish {
        return this._finish;
    }

    set finish(finish: OrderPartsFinish) {
        if (this._orderDetailsDirtyRows.length > 0) {
            this.saveDirtyRows();
        }
        this._finish = finish;
        this._finishId = finish.id;
        if (finish.id.length > 0) {
            this.loadDetails();
            this.loadPGF();
        } else {
            this.orderDetails = [];
            this._productGroupFinishes = [];
        }
    }

    @Input() set saveDetails(saveDetails) {
        if (saveDetails) {
            this.saveDirtyRows();
        }
    }

    @Input() set reloadDetails(load: boolean) {
        if (load) {
            this.loadDetails();
        }
    }

    @Input() set order(order: Order) {
        this._order = order;
        this.loadProductTypes();
        this._completeProducts = this._productsService._products;
    }

    @Output() detailsSaved: EventEmitter<OrderDetail[]> = new EventEmitter<OrderDetail[]>();

    constructor(public _auth: AuthService, public _st: SupportTools, public _apiService: ApiService, public _aclService: AclService,
                public _transService: TranslateService, public _fb: FormBuilder, public _productsService: ProductsService) {
        this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        this._canAdd = (this._auth.modules['orders'].authLevel >= 30);
        this._canDelete = (this._auth.modules['orders'].authLevel >= 40);
        if (this._canDelete) {
            this._columns = this._columns + 1;
        }
    }

    selectInputContent(event) {
        event.target.select();
    }

    selectDetail(event) {
        if (event.data.sortOrderNumber !== this.selectedOrderDetail.sortOrderNumber)
            this.saveDirtyRows();

        this.selectedOrderDetail = event.data;

    }

    loadDetails() {
        this._detailsLoading = true;
        this._apiService.setActionUrl('orders/orderdetails');
        this._apiService.setFilter({field: 'orderPartFinishId', value: this._finishId, operator: 'eq'});
        this._apiService.setSort({field: 'sortOrderNumber', direction: 'ASC'});
        this._apiService.getStore().subscribe(response => {
            this._detailsLoading = false;
            if (response.success) {
                this.orderDetails = response.data.records;
                this.addRow();
                this.selectDetail({data: this.orderDetails[this.orderDetails.length - 1]});
            } else {
                this._auth.addError(response.errorMsg);
            }
            this._products = this._productsService._productsDD;
        });
    }

    loadPGF() {
        this._apiService.setActionUrl('products/productGroupFinishes');
        this._apiService.setFilter({field: 'finishId', value: this._finish.finishId, operator: 'eq'});
        // this._apiService.setSort({field: 'description', direction: 'ASC'})
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this._productGroupFinishes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadProductTypes() {
        this._apiService.setActionUrl('products/types');
        this._apiService.setFilter({field: 'uisVersion', operator: 'eq', value: this._order.uisVersion});
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(response => {
            if (response.success) {
                this._productTypes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadProductGroups(event: SelectItem) {
        this._productGroups = [];
        this._productProcesses = [];
        this.selectedProductTypeId = event.value;
        this._apiService.setActionUrl('products/groups');
        this._apiService.setFilter({field: 'productTypeId', operator: 'eq', value: event.value});
        this._apiService.setSort({field: 'description', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(response => {
            if (response.success) {
                this._productGroups = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this._apiService.setActionUrl('products/types');
        this._apiService.specialPostAction(JSON.stringify({productTypeId: event.value}), 'getProcesses').subscribe(response => {
            if (response.success) {
                this._productTypeProcesses = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });

    }

    loadProducts(event: SelectItem) {
        this._products = [];
        this._productTypeEvent = event;
        this._apiService.setActionUrl('products/products');
        this._apiService.setFilter({field: 'productGroupId', operator: 'eq', value: event.value});
        if (!this._allSuppliers) {
            this._order.preferredSuppliers.forEach(function (preferredSupplier) {
                if (preferredSupplier.productTypeId == this.selectedProductTypeId) {
                    this._apiService.setFilter({field: 'supplierId', operator: 'eq', value: preferredSupplier.supplierId});
                }
            }, this);
        }
        this._apiService.getDropDown(JSON.stringify(['name']), 'id', 'Y', 150).subscribe(response => {
            if (response.success) {
                this._products = response.data.records;
                let reload = false;
                if (this.productSelection.productId !== '') {
                    reload = true;
                    this._products.forEach(function(product) {
                        if (this.productSelection.productId === product.value) {
                            reload = false;
                        }
                    }, this);
                }
                if (reload) {
                    this._allSuppliers = true;
                    this.loadProducts(event);
                } else {
                    this.allProducts();
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });

    }

    gotoNextCell(event, detail: OrderDetail, ri: number) {
        let vc = document.getElementById('product_' + detail.sortOrderNumber);
        let nextCell = document.getElementById('materialCost_' + detail.sortOrderNumber);
        if (nextCell) {
            DomHandler.invokeElementMethod(vc, 'blur');
            DomHandler.invokeElementMethod(nextCell, 'focus');
            if (typeof event.preventDefault === "function") {
                event.preventDefault();
            }
        }
    }

    orderDetailMove(orderDetail: OrderDetail, newRow: number) {
        let newSortOrderNumber = orderDetail.sortOrderNumber;
        orderDetail.sortOrderNumber = this.orderDetails[newRow].sortOrderNumber;
        this.orderDetails[newRow].sortOrderNumber = newSortOrderNumber;
        this.orderDetailsSave(this.orderDetails[newRow]);
        this.orderDetailsSave(orderDetail, true);
    }

    orderDetailEditQuotationText(orderDetail: OrderDetail, row: number) {
        if (orderDetail.modifiedText == null || orderDetail.modifiedText.length === 0) {
            orderDetail.modifiedText = orderDetail.detailText;
        }
        this.selectedOrderDetail = orderDetail;
        this._showDetailTextPopup = true;
    }

    saveDetailText() {
        this._showDetailTextPopup = !(this.orderDetailsSave(this.selectedOrderDetail, false));
    }

    orderDetailsSave(orderDetail: OrderDetail, reload: boolean = false): boolean {
        if (orderDetail.materialCost == null || orderDetail.materialFactor == null ||
            orderDetail.materialLoss == null || orderDetail.labourCost == null ||
            orderDetail.labourFactor == null || orderDetail.orderPartFinishId.length == 0 ||
            orderDetail.productId.length == 0 || orderDetail.piecesPerHour == null) {
            return false;
        }
        this._apiService.setActionUrl('orders/orderdetails');
        this.selectedProductPrice = new ProductPrice();
        let isNew = (orderDetail.id.length === 0);
        this._apiService.saveRecord(orderDetail).subscribe(response => {
            if (response.success) {
                if (reload || true) {
                    this.loadDetails();
                } else if (isNew) {
                    orderDetail = response.data.records[0];
                    this.addRow();
                }
                this.emitDetails();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        return true;
    }

    orderDetailDelete(orderDetail: OrderDetail, row: number) {
        this._apiService.setActionUrl('orders/orderdetails');
        if (orderDetail.id == '') {
            return;
        }
        this._apiService.deleteRecord(orderDetail.id).subscribe(response => {
            if (response.success) {
                this.orderDetails.splice(row, 1);
                this.loadDetails();
                this.emitDetails();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });

    }

    emitDetails() {
        let details: OrderDetail[] = [];
        this.orderDetails.forEach(function (orderDetail) {
            if (orderDetail.id !== '')
                details.push({...orderDetail});
        })
        this.detailsSaved.emit(details);
    }

    saveDirtyRows() {
        if (this._orderDetailsDirtyRows.length > 0) {
            this._orderDetailsDirtyRows.forEach(function(value, index) {
                // check if row is valid
                let details: OrderDetail = this.orderDetails[index];
                if (details.productId.length == 0) {
                    return;
                }
                if (details.orderPartFinishId.length == 0) {
                    return;
                }
                this.orderDetailsSave(details);
            }, this)
            this._orderDetailsDirtyRows = [];
        }
    }

    setDirtyFlag(row: number) {
        this._orderDetailsDirtyRows[row] = true;
        if (this.orderDetails.length == (row + 1)) {
            this.addRow();
        }
    }

    addRow() {
        if (this._canAdd && this._finishId.length > 0) {
            let newOrderDetails = new OrderDetail();
            newOrderDetails.orderPartFinishId = this._finishId;
            newOrderDetails.quantity = this._finish.size;
            let lastNumber = 0;
            if (this.orderDetails.length > 0) {
                lastNumber = this.orderDetails[this.orderDetails.length - 1].sortOrderNumber;
            }
            newOrderDetails.sortOrderNumber = lastNumber + 1;
            newOrderDetails.labourFactor = 1;
            newOrderDetails.materialFactor = 1;
            newOrderDetails.product = new Product();
            this.orderDetails.push(newOrderDetails);
        }
    }

    changeProduct(event: SelectItem, row: number, detail: OrderDetail, cycle = 0) {
        if (this._order.uisVersion < 3) {
            this.changeProductV2(event, row, detail);
        } else {
            this.changeProductV3(event, row, detail, cycle);
        }
    }

    changeProductV2(event: SelectItem, row: number, detail: OrderDetail) {
        this.gotoNextCell(event, detail, row);
        if (detail.productId !== event.value) {
            detail.productId = event.value;
            this._apiService.setActionUrl('products/products');
            this._apiService.setFilter({field: 'id', value: event.value, operator: 'eq'});
            this._apiService.getStore().subscribe(response => {
                if (response.success) {
                    detail.product = response.data.records[0];
                    // detail.materialCost = detail.product.materialCost;
                    // detail.materialLoss = detail.product.materialLoss;
                    let labCost: number = 0;
                    if (detail.product.piecesPerHour > 0)
                        labCost = (1 / detail.product.piecesPerHour) * this._order.labourCost;

                    detail.labourCost = Math.round((labCost + 0.00001) * 100) / 100;
                    this.orderDetailsSave(detail);
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        }
    }

    changeProductV3(event: SelectItem, row: number, detail: OrderDetail, cycle = 0) {
        // if (detail.productId !== event.value) {
            let product: Product = this.getProduct(event.value);
            detail.product = product;
            this._apiService.setActionUrl('products/products');
            this._apiService.setFilter({field: 'id', operator: 'eq', value: product.id});
            this._apiService.getStore().subscribe(response => {
                if (response.success) {
                    this._productProcesses = response.data.records[0].processes;
                    if (product.productPrices.length === 1) {
                        this.selectedProductPrice = product.productPrices[0];
                    }
                    if (product.productPrices.length > 1 && this.selectedProductPrice.id === '') {
                        this.productPrices = product.productPrices;
                        this._showProductPrices = true;
                        this.savedEvent = event;
                        this.orderDetailRow = row;
                    }

                    // check if productGroupFinishProcess exists for this product
                    let pgf: ProductGroupFinish[] = this._productGroupFinishes.filter(_pgf => {
                        if (_pgf.productGroupId === detail.product.productGroupId) {
                            return _pgf;
                        }
                    });
                    if (pgf.length === 0 && !this._showProductPrices) {
                        let sendEvent = {event: event, mustComplete: true, detail: detail, row: row, cycle: cycle++};
                        let newPGF = new ProductGroupFinish();
                        newPGF.productGroupId = detail.product.productGroupId;
                        newPGF.productGroup = detail.product.productGroup;
                        newPGF.finishId = this._finish.finishId;
                        newPGF.finish = this._finish.finish;
                        this._sendToPGF.newPGF = newPGF;
                        this._sendToPGF.event = sendEvent;
                        this._showPGF = true;
                    }

                    let pp: ProductProcess[] = this._productProcesses.filter(_pp => {
                        if (_pp.processId == detail.processId) {
                            return _pp;
                        }
                    });
                    if(pp.length === 0 && !this._showProductPrices && !this._showPGF) {
                        let ppSendEvent = {event: event, mustComplete: true, detail: detail, row: row, cycle: cycle++};
                        let newPP = new ProductProcess();
                        let proc = new Process();
                        proc.id = detail.processId;
                        let ptpProcess = this._productTypeProcesses.find(ptp => {
                            if (ptp.value === detail.processId) {
                                return ptp;
                            }
                        });
                        if (typeof ptpProcess !== 'undefined') {
                            proc.description = ptpProcess.label;
                        } else {
                            proc.description = 'Onbekend';
                        }
                        newPP.processId = detail.processId;
                        newPP.process = proc;
                        newPP.productId = event.value;
                        newPP.product = detail.product;
                        this._sendToPP.newPP = newPP;
                        this._sendToPP.event = ppSendEvent;
                        this._showPP = true;
                    }

                    if (!(this._showProductPrices || this._showPP || this._showPGF)) {
                        detail.productId = event.value;
                        detail.materialCost = this.selectedProductPrice.materialCost;
                        detail.productPriceId = this.selectedProductPrice.id;
                        this._apiService.setActionUrl('products/productProcesses');
                        this._apiService.setFilter({field: 'id', operator: 'eq', value: pp[0].id});
                        this._apiService.getStore().subscribe(response => {
                            if (response.success) {
                                pp[0].process = response.data.records[0].process;
                                let labCost: number = 0;
                                let ml = pp[0].process.materialLossConstruct;
                                let pph = pp[0].process.piecesPerHourConstruct;
                                if (this._order.isRenovation) {
                                    ml = pp[0].process.materialLossRenovation;
                                    pph = pp[0].process.piecesPerHourRenovation;
                                }
                                if (pph > 0)
                                    labCost = (1 / pph) * this._order.labourCost;

                                detail.materialLoss = ml;
                                detail.piecesPerHour = pph;
                                detail.labourCost = Math.round((labCost + 0.00001) * 100) / 100;
                                this.gotoNextCell(event, detail, row);
                                this.orderDetailsSave(detail);
                            }
                        })
                    }
                }
            });
        // } else {
        //     this.orderDetailsSave(detail);
        //     this.gotoNextCell(event, detail, row);
        // }
    }

    calcMaterial(quantity: number, loss: number) {
        let factor: number = (1 + (loss / 100));
        let material: number = quantity * factor;
        return Math.round(material * 1000) / 1000;
    }

    filterProducts(event) {
        this._productsFiltered = this._st.filterAutoComplete(event, this._products);
    }

    allProducts() {
        this._productsFiltered = this._products;
    }

    savedPGFForm(returnData) {
        this._showPGF = false;
        if (returnData.cancelled) {
            return;
        }
        this._productGroupFinishes.push(returnData.pgf);
        this.changeProduct(returnData.event.event, returnData.event.row, returnData.event.detail, returnData.event.cycle);
    }

    savedPPForm(returnData) {
        this._showPP = false;
        if (returnData.cancelled) {
            return;
        }
        this._productProcesses.push(returnData.pp);
        this.changeProduct(returnData.event.event, returnData.event.row, returnData.event.detail, returnData.event.cycle);
    }
    calcPiecesPerHour(orderDetail: OrderDetail): number {
        return Math.round(((orderDetail.piecesPerHour / orderDetail.labourFactor) + Number.EPSILON) * 100) / 100;
    }

    openProductSelector(ri: number) {
        this._productSelector = true;
        this._allSuppliers = false;
        this._productTypeProcesses = [];
        this._productGroups = [];
        this.rowSelected = ri;
        this.productSelection.productId = this.selectedOrderDetail.productId;
        this.productSelection.productTypeId = '';
        this.productSelection.productGroupId = '';
        if (this.selectedOrderDetail.productId.length > 0) {
            this.selectedOrderDetail.product = this.getProduct(this.selectedOrderDetail.productId);
            this.productSelection.productTypeId = this.selectedOrderDetail.product.productGroup.productTypeId;
            this.productSelection.productGroupId = this.selectedOrderDetail.product.productGroupId;
            this.loadProductGroups({value: this.selectedOrderDetail.product.productGroup.productTypeId, label: ''});
            this.loadProducts({value: this.selectedOrderDetail.product.productGroupId, label: ''});
        } else {
            this._productGroups = [];
            this._products = [];
        }
    }

    changeProductSelector(event) {
        this.selectedOrderDetail.product = this.getProduct(event.value);
        this.productSelection.productId = event.value;
    }

    changeProcess(event) {
        this.productSelection.processId = event.value;
    }

    changeProductPrice(event) {
        this.selectedOrderDetail.materialCost = event.data.materialCost;
        this.selectedOrderDetail.productPriceId = event.data.id;
        this.selectedProductPrice = event.data;
        this._showProductPrices = false;
        this.changeProduct(this.savedEvent, this.rowSelected, this.selectedOrderDetail);
    }

    saveProductSelector() {
        let event = {value: this.productSelection.productId};
        this.changeProduct(event, this.rowSelected, this.selectedOrderDetail);
        this._productSelector = false;
        this.rowSelected = 0;
    }

    private getProduct(productId): Product {
        if (productId === '') {
            return new Product();
        }
        return this._completeProducts.find(prod => {
            if (prod.id === productId) {
                return prod;
            }
        });
    }
}
