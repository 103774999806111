import {BaseModelShared} from "../base/base.model";

export class Finish extends BaseModelShared {
    code: string;
    description: string;
    uisVersion: number;
}

export class ShippingMethod extends BaseModelShared {
    shippingMethod: string;
}

export class PaymenTerm extends BaseModelShared {
    paymentTerm: string;
}

export class Warranty extends BaseModelShared {
    description: string;
    percentage: number;
    text: number;
}

export class RoofingType extends BaseModelShared {
    description: string;
    availableFrom: number;
    availableFromDate: Date;
    availableTill: number;
    availableTillDate: Date;
}

export class Process extends BaseModelShared {
    description: string;
    code: string;
    quotationText: string;
    piecesPerHourConstruct: number = 0;
    piecesPerHourRenovation: number = 0;
    materialLossConstruct: number = 0;
    materialLossRenovation: number = 0;
}

export class Choice extends BaseModelShared {
    parentId: string;
    name: string = '';
    hasFields: boolean = false;
    isField: boolean = false;
    fieldType: string = '';
    replacesTag: string = '';
    quotationText: string = '';
    optionList: string = '';
    children: Choice[] = [];
}

export class Text extends BaseModelShared {
    textItemId: number;
    text: string = '';
}
