import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {OrdersComponent} from "./orders.component";
import {PartsComponent} from "./parts.component";
import {FinishesComponent} from "./finishes.component";
import {DetailsComponent} from "./details.component";
import {CompanyComponent} from "./company.component";
import {FinishChoiceComponent} from "./finishchoice.component";
import {MasterDataModule} from "../masterdata/masterdata.module";
import {RecipesComponent} from "./recipes.component";
import {ProductsModule} from "../products/products.module";
import {FacilitiesComponent} from "./facilities.component";

@NgModule({
    imports: [
        SharedModule,
        MasterDataModule,
        ProductsModule
    ],
    declarations: [
        OrdersComponent,
        PartsComponent,
        FinishesComponent,
        DetailsComponent,
        CompanyComponent,
        FinishChoiceComponent,
        RecipesComponent,
        FacilitiesComponent
    ]
})
export class OrdersModule {

}
