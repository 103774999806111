import {Component} from "@angular/core";
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.css']
})
export class BaseComponent {
    private _loadCompanies: boolean = false;

    constructor(private _apiService: ApiService, private _auth: AuthService) {

    }

    loadCompanies() {
        this._loadCompanies = true;
    }

}