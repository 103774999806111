import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import {OrderPartsFinishChoice} from "./orders.models";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {Choice} from "../masterdata/masterdata.models";
import {SelectItem} from "primeng/api";
import {ApiService} from "../api.service";

@Component({
    selector: 'finishChoice',
    templateUrl: './finishchoice.component.html',
    styleUrls: ['./orders.component.css']
})
export class FinishChoiceComponent implements OnInit {

    public _canEdit: boolean = false;
    public _canAdd: boolean = false;
    public _canDelete: boolean = false;
    public _masterDataCanAdd: boolean = false;
    public _choices: OrderPartsFinishChoice[] = [];
    public _availableChoices: SelectItem[] = [];
    public _filteredChoices: SelectItem[] = [];
    public _optionChoices: SelectItem[] = [];
    public selectedChoice: OrderPartsFinishChoice = new OrderPartsFinishChoice();
    public backGroundStyle: string;
    public _level: number;
    public _parentChoiceId: string;
    public _parentDescription: string;
    public _addChoice: boolean = false;
    public _newChoice: Choice = new Choice();
    public allChoices: Choice[] = [];

    @Input() set level(level: number) {
        this._level = level;
    };

    @Input() set parentChoiceId(id: string) {
        this._parentChoiceId = id;
        this.loadChoices(id);
    }

    @Input() get choices(): OrderPartsFinishChoice[] {
        return this._choices;
    }

    set choices(choices) {
        this._choices = choices;
        if (this._parentChoiceId !== '--') {
            let addChoice = true;
            this._choices.forEach(function(choice) {
                if (choice.choiceId === '--') {
                    addChoice = false;
                }
            });
            if (addChoice)
                this._choices.push(this.addRow(this._choices.length + 1));
        }

    }

    @Input() set parentDescription(description: string) {
        this._parentDescription = description;
    }

    @Output() choicesChange: EventEmitter<OrderPartsFinishChoice[]> = new EventEmitter<OrderPartsFinishChoice[]>();

    constructor(public auth: AuthService, public _st: SupportTools, private _apiService: ApiService) {
        this._canEdit = this.auth.modules['orders'].authLevel >= 20;
        this._canAdd = this.auth.modules['orders'].authLevel >= 30;
        this._canDelete = this.auth.modules['orders'].authLevel >= 50;
        this._masterDataCanAdd = this.auth.modules['masterdata'].authLevel >= 30;
    }

    ngOnInit() {
        this.loadAllChoices()
    }

    loadAllChoices() {
        this._apiService.setActionUrl('masterdata/choices');
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this.allChoices = response.data.records;
            } else {
                this.auth.addError(response.errorMsg);
            }
        });
    }


    selectChoice(event) {
        this.selectedChoice = event.data;
    }

    loadChoices(parentId: string) {
        this._apiService.setActionUrl('masterdata/choices');
        this._apiService.setFilter({field: 'parentId', operator: 'eq', value: parentId});
        this._apiService.getDropDown(JSON.stringify(['name']), 'id', 'Y').subscribe(response => {
            if (response.success) {
                this._availableChoices = response.data.records;
                this._availableChoices.push({value: "ADD", label: "Voeg keuze toe"});
            } else {
                this.auth.addError(response.errorMsg);
            }
        });
    }
    changeValue(event) {
        this.choicesChange.emit(this._choices);
    }

    getOptions(id: string) {
        let chosen = this.allChoices.find(x => x.id === id);
        let options = chosen.optionList.split(';');
        let optionChoices: SelectItem[] = [];
        options.forEach(function (option) {
            optionChoices.push({value: option, label: option});
        }, this);
        return optionChoices;
    }

    calculateTime(dateString: string) {
        return new Date(dateString).getTime() / 1000;
    }

    filterChoices(event) {
        this._filteredChoices = this._st.filterAutoComplete(event, this._availableChoices);
    }

    resetChoices() {
        this._filteredChoices = this._availableChoices;
    }

    changeChoice(event, ri: number, choice: OrderPartsFinishChoice) {
        if (choice.choiceId == '') {
            this._choices.push(this.addRow(this._choices.length + 1));
        }
        if (event.value === 'ADD') {
            this._addChoice = true;
            this._parentDescription = this.allChoices.find(x => x.id == this._parentChoiceId).quotationText;
        } else if (event.value !== '') {
            choice.choiceId = event.value;
            let chosen = this.allChoices.find(x => x.id == choice.choiceId);
            choice.choiceName = chosen.name;
            choice.isField = chosen.isField;
            choice.hasFields = chosen.hasFields;
            choice.fieldType = chosen.fieldType;
            if (choice.isField && choice.fieldValue == null) {
                switch (choice.fieldType) {
                    case 'NUM':
                        choice.fieldValue = 0;
                        break;
                    case 'TXT':
                        choice.fieldValue = '';
                        break;
                    case 'DAT':
                        choice.fieldValue = 0;
                        break;
                    case 'CB':
                        choice.fieldValue = 0;
                        break;
                    default:
                        choice.fieldValue = '';
                        break;
                }
            }
            if (choice.subChoices.length == 0) {
                choice.subChoices.push(this.addRow(choice.subChoices.length + 1));
            }
            this.choicesChange.emit(this._choices);
        }
    }

    selectInputChoice(event) {
        event.target.select();
    }

    addRow(sortOrderNumber: number): OrderPartsFinishChoice {
        let newChoice = new OrderPartsFinishChoice();
        newChoice.sortOrderNumber = sortOrderNumber;
        newChoice.subChoices = [];
        return newChoice
    }

    deleteChoice(sortOrderNumber: number) {
        this._choices.forEach(function(choice, index) {
            if (choice.sortOrderNumber == sortOrderNumber) {
                this._choices.splice(index, 1);
            }
        }, this);
        this._choices.forEach(function(choice, index) {
            choice.sortOrderNumber = index + 1;
        });
        this.choicesChange.emit(this._choices);
    }

    emitChangeChoice(event) {
        this.selectedChoice.subChoices = event;
        this.choicesChange.emit(this._choices);
    }

    reloadChoices(event) {
        if (event) {
            this.loadChoices(this._parentChoiceId);
        }

    }
}
