import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {Product, ProductGroup, ProductPrice, ProductProcess, ProductType} from "./products.model";
import {MenuItem, SelectItem} from "primeng/api";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {ShortcutInput} from "ng-keyboard-shortcuts";
import {Table} from "primeng/table";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ProductsService} from "./products.service";

@Component({
    selector: 'products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, AfterViewInit {

    public selectedItem: string;
    public selectedProductType: ProductType = new ProductType();
    public selectedProduct: Product = new Product();
    public selectedProductGroup: ProductGroup = new ProductGroup();
    public products: Product[] = [];
    public productTypes: ProductType[] = [];
    public productGroups: ProductGroup[] = [];
    public productProcesses: ProductProcess[] = [];
    public productsLoading: boolean = false;
    public typesLoading: boolean = false;
    public groupsLoading: boolean = false;
    public processesLoading: boolean = false;
    public suppliers: any[] = [];

    public _showProducts: boolean = false;
    public _showProductDialog: boolean = false;
    public _showTypes: boolean = false;
    public _showGroups: boolean = false;
    public _showProcesses: boolean = false;
    public _showProductProcesses: boolean = false;
    public _showImport: boolean = false;
    public _showImports: boolean = false;
    public _showProductGroupChange: boolean = false;
    private _tmpProductTypes: ProductType[] = [];
    private _tmpProductGroups: ProductGroup[] = [];
    private _tmpProductProcesses: ProductProcess[] = [];
    public _contextMenu: MenuItem[] = [];
    public _contextMenuGroup: MenuItem[] = [];
    public _contextMenuProduct: MenuItem[] = [];
    private _showCancelYesNo: boolean = false;
    private _canAdd: boolean = false;
    public _productForm: FormGroup;
    public _supplierDD: SelectItem[] = [];
    public _productsDD: SelectItem[] = [];
    public _parentProductsDD: SelectItem[] = [];
    public processesDD: SelectItem[] = [];
    public processesFiltered: SelectItem[] = [];
    public finishesDD: SelectItem[] = [];
    public finishesFiltered: SelectItem[] = [];
    public kbShortCuts: ShortcutInput[] = [];
    public uisVersion: number = 3;
    public selectProductGroups: SelectItem[] = [];
    public available: string = '1';
    public disableSaveButton: boolean = false;

    constructor(public _auth: AuthService, public _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder, protected _productsService: ProductsService) {

    }

    @ViewChild('typesTable') tt: Table;
    @ViewChild('groupsTable') gt: Table;
    @ViewChild('processTable') pt: Table;

    ngOnInit(): void {
        this.loadTypes();
        this.loadDropDowns();
        this.buildContextMenus();
        this._showTypes = true;

        this._canAdd = (this._auth.modules['products'].authLevel >= 30);

        this._productForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'productGroupId': new FormControl({value: '', disabled: true}),
            'productGroup': new FormControl({value: '', disabled: true}),
            'parentProductId': new FormControl({value: '', disabled: false}),
            'parentProduct': new FormControl({value: '', disabled: true}),
            'productType': new FormControl({value: '', disabled: true}),
            'productTypeId': new FormControl({value: '', disabled: true}),
            'supplierId': new FormControl(''),
            'supplier': new FormControl({value: '', disabled: true}),
            'name': new FormControl('', Validators.required),
            'description': new FormControl(''),
            'itemCode': new FormControl('', Validators.required),
            'materialCost': new FormControl(''),
            'calculationFactor': new FormControl(''),
            'minimumAmount': new FormControl({value: 0, disabled: false}),
            'hasParentProduct': new FormControl({value: '', disabled: false}),
            'purchasePrice': new FormControl({value: '', disabled: true}),
            'materialLoss': new FormControl({value: '', disabled: true}),
            'piecesPerHour': new FormControl({value: '', disabled: true}),
            'labourHours': new FormControl({value: '', disabled: true}),
            'available': new FormControl({value: '', disabled: false}),
            'processes': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'productPrices': new FormControl({value: '', disabled: true})
        });
        this._productsDD = this._productsService._productsDD;
    }

    ngAfterViewInit(): void {
        this.kbShortCuts.push(
            {
                key: 'ctrl + s',
                preventDefault: true,
                description: "Opslaan",
                label: "Commando's",
                command: event => this.save()
            }, {
                key: 'ctrl + e',
                preventDefault: true,
                description: "Bewerken",
                label: "Commando's",
                command: event => this.editRecord()
            }, {
                key: 'up',
                preventDefault: true,
                description: 'Omhoog',
                label: 'Navigatie',
                command: event => this.rowUp()
            }, {
                key: 'up',
                preventDefault: true,
                description: 'Omlaag',
                label: 'Navigatie',
                command: event => this.rowDown()
            }
        );
    }

    buildContextMenus() {
        this._contextMenu = [];
        this._contextMenuGroup = [];
        this._contextMenuProduct = [];
        switch (this.uisVersion) {
            case 2:
                this._transService.get(['products.showProducts', 'base.edit', 'base.delete', 'base.details', 'masterdata.processes', 'products.showGroups', 'products.showProcesses']).subscribe(response => {
                    this._contextMenu.push({label: response['masterdata.processes'], icon: 'fa fa-project-diagram', command: (event) => this.showProcesses()});
                    this._contextMenu.push({label: response['products.showProducts'], icon: 'fa fa-list', command: (event) => this.showGroups()});
                    if (this._auth.modules['products'].authLevel >= 20) {
                        //this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editRecord()});
                        this._contextMenuProduct.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.startEditProduct(event)});
                    }
                    if (this._auth.modules['products'].authLevel >= 40) {
                        this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteType()});
                        this._contextMenuProduct.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteProduct(event)});
                        //this._contextMenuGroup.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteGroup()});
                    }
                });
                break;
            case 3:
                this._transService.get(['products.showProducts', 'base.edit', 'base.delete', 'base.details', 'base.copy', 'masterdata.processes', 'products.showGroups', 'products.showProcesses']).subscribe(response => {
                    this._contextMenu.push({label: response['masterdata.processes'], icon: 'fa fa-project-diagram', command: (event) => this.showProcesses()});
                    this._contextMenu.push({label: response['products.showGroups'], icon: 'fa fa-list', command: (event) => this.showGroups()});
                    this._contextMenuGroup.push({label: response['products.showProducts'], icon: 'fa fa-list', command: (event) => this.showProducts()});
                    //this._contextMenuGroup.push({label: response['products.showProcesses'], icon: 'fa fa-list', command: (event) => this.showProcesses()});
                    if (this._auth.modules['products'].authLevel >= 20) {
                        //this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editRecord()});
                        this._contextMenuProduct.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.startEditProduct(event)});
                        this._contextMenuProduct.push({label: response['base.copy'], icon: 'fa fa-clone', command: (event) => this.startCopyProduct(event)});
                        this._contextMenuProduct.push({label: response['masterdata.processes'], icon: 'fa fa-list', command: (event) => this.showProductProcesses(event)});
                        this._contextMenuProduct.push({label: "Wijzig productgroep", icon: 'fa fa-change', command: (event) => this.changeProductGroup(event)});
                    }
                    if (this._auth.modules['products'].authLevel >= 40) {
                        this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteType()});
                        this._contextMenuProduct.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteProduct(event)});
                        this._contextMenuGroup.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteGroup()});
                    }
                });
                break;
        }
    }

    save() {
        if (this._showProducts) {
            this.saveProduct();
        } else {
            this.typeEditSave(this.selectedProductType);
        }
    }

    rowUp() {
        if (this._showProducts) {
            let curRow = this.products.indexOf(this.selectedProduct);
            if (curRow == 0) {
                return;
            }
            this.selectedProduct = this.products[curRow - 1];
        } else {
            let curRow = this.productTypes.indexOf(this.selectedProductType);
            if (curRow == 0) {
                return;
            }
            this.selectedProductType = this.productTypes[curRow -1];
        }
    }

    rowDown() {
        if (this._showProducts) {
            let curRow = this.products.indexOf(this.selectedProduct);
            if (curRow == this.products.length - 1) {
                return;
            }
            this.selectedProduct = this.products[curRow + 1];
        } else {
            let curRow = this.productTypes.indexOf(this.selectedProductType);
            if (curRow == this.productTypes.length - 1) {
                return;
            }
            this.selectedProductType = this.productTypes[curRow + 1];
        }
    }
    showProducts() {
        this.loadProducts();
        this._showProducts = true;
        this._productsDD = this._productsService._productsDD;
    }

    showGroups() {
        this.loadGroups();
        this._showGroups = true;
    }

    showProcesses() {
        this._showProcesses = true;
    }

    loadProducts() {
        this.productsLoading = true;
        this._apiService.setActionUrl('products/products');
        this._apiService.setFilter({field: 'available', value: this.available, operator: 'eq'})
        if (this.selectedProductGroup) {
            this._apiService.setFilter({field: 'productGroupId', value: this.selectedProductGroup.id, operator: 'eq'});
        }
        this._apiService.getStore().subscribe(response => {
            this.productsLoading = false;
            if (response.success) {
                this.products = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadGroups() {
        this.groupsLoading = true;
        this._apiService.setActionUrl('products/groups');
        if (this.selectedProductType) {
            this._apiService.setFilter({field: 'productTypeId', value: this.selectedProductType.id, operator: 'eq'});
        }
        // this._apiService.setSort({field: 'description', direction: 'asc'});
        this._apiService.getStore().subscribe(response => {
            this.groupsLoading = false;
            if (response.success) {
                if (response.data.records.length === 1 && this.uisVersion === 2) {
                    this.selectedProductGroup = response.data.records[0];
                    this._showGroups = false;
                    this._showProducts = true;
                    this.loadProducts();
                } else {
                    this.productGroups = response.data.records;
                    this.addGroupRow();
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadDropDowns() {
        this._apiService.loadGoRelations('supplier').subscribe(response => {
            if (response.success) {
                this.suppliers = response.data.records;
                this._supplierDD.push({value: '', label: this._transService.instant('base.useSelectValue')});
                this.suppliers.forEach(function(supplier) {
                    this._supplierDD.push({value: supplier.id, label: supplier.name});
                }, this)
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this._apiService.setActionUrl('masterdata/finishes');
        this._apiService.getDropDown(JSON.stringify(['code'])).subscribe(response => {
            if (response.success) {
                this.finishesDD = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this._apiService.setActionUrl('masterdata/processes');
        this._apiService.getDropDown(JSON.stringify(['description']), 'id', 'N').subscribe(response => {
            if (response.success) {
                this.processesDD = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadTypes() {
        this.typesLoading = true;
        this._apiService.setActionUrl('products/types');
        this._apiService.setFilter({field: 'uisVersion', operator: 'eq', value: this.uisVersion});
        this._apiService.getStore().subscribe(response => {
            this.typesLoading = false;
            if (response.success) {
                this.productTypes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    switchVersion() {
        this.uisVersion++;
        if (this.uisVersion > 3)
            this.uisVersion = 2;

        this.loadTypes();
    }

    typeEditInit(type: ProductType) {
        this._tmpProductTypes[type.id] = { ... type };
    }

    typeEditSave(type: ProductType, reload: boolean = true) {
        if (type.code.length > 0) {
            this._apiService.setActionUrl('products/types');
            this._apiService.saveRecord(type).subscribe(response => {
                if (response.success) {
                    if (reload) {
                        this.selectedProductType = new ProductType();
                        this.loadTypes();
                    }
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        }
    }

    typeEditCancel(type: ProductType, row: number) {
        this.productTypes[row] = this._tmpProductTypes[type.id];
        this._tmpProductTypes[type.id] = null;
    }


    groupEditInit(group: ProductGroup) {
        this._tmpProductGroups[group.id] = { ... group };
    }

    groupEditSave(group: ProductGroup) {
        if (group.description.length > 0) {
            this._apiService.setActionUrl('products/groups');
            if (group.id.length === 0) {
                this._apiService.createRecord(JSON.stringify(group)).subscribe(response => {
                    if (response.success) {
                        this.selectedProductGroup = new ProductGroup();
                        this.loadGroups();
                    }
                });
            } else {
                this._apiService.updateRecord(JSON.stringify(group)).subscribe(response => {
                    if (response.success) {
                        this.selectedProductGroup = new ProductGroup();
                        this.loadGroups();
                    }
                });
            }
        }
    }

    groupEditCancel(group: ProductGroup, row: number) {
        this.productGroups[row] = this._tmpProductGroups[group.id];
        this._tmpProductGroups[group.id] = null;
    }

    processEditInit(process: ProductProcess) {
        this._tmpProductProcesses[process.id] = { ... process };
    }

    processEditSave(process: ProductProcess) {
        this._apiService.setActionUrl('products/productProcesses');
        this._apiService.saveRecord(process).subscribe(response => {
            if (response.success) {
                this.showProductProcesses({})
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    processEditCancel(process: ProductProcess, row: number) {
        this.productProcesses[row] = this._tmpProductProcesses[process.id];
        this._tmpProductProcesses[process.id] = null;
    }

    createType() {
        let type = new ProductType();
        type.id = '';
        this.productTypes.unshift(type);
    }

    deleteType() {
        this._apiService.setActionUrl('products/types');
        this._apiService.deleteRecord(this.selectedProductType.id).subscribe(response => {
            if (response.success) {
                this.loadTypes();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    deleteGroup() {
        this._apiService.setActionUrl('products/groups');
        this._apiService.deleteRecord(this.selectedProductGroup.id).subscribe(response => {
            if (response.success) {
                this.loadGroups();
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    cancelUnselectPT(event) {
        this.selectedProductType = new ProductType();
        this.productGroups = [];
    }

    cancelUnselectPG(event) {
        this.selectedProductGroup = new ProductGroup();
    }

    startEditProduct(event) {
        this.editProduct(this.selectedProduct);
    }

    startCopyProduct(event) {
        let newProduct = {... this.selectedProduct};
        newProduct.id = '';
        newProduct.itemCode = 'KOPIE - ' + this.selectedProduct.itemCode;
        newProduct.description = this.selectedProduct.description;
        this.editProduct(newProduct, true);
    }

    editProduct(product: Product, copy: boolean = false) {
        this._apiService.setActionUrl('products/products');
        this._apiService.specialPostAction(JSON.stringify({productTypeId: this.selectedProductType.id}), 'getParentProducts').subscribe(response => {
            if (response.success) {
                this._parentProductsDD = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
        this.selectedProduct = product;
        this.addPriceLine(null, new ProductPrice());
        this._showProductDialog = true;
        this._showProducts = false;
        if (this.selectedProduct.id !== '' || copy) {
            this._productForm.setValue(this.selectedProduct as any)
        } else {
            this._productForm.reset();
            this._productForm.controls.id.patchValue('');
            this._productForm.controls.available.patchValue(true);
        }
    }

    deleteProduct(event) {
        this._apiService.setActionUrl('products/products');
        this._apiService.deleteRecord(this.selectedProduct.id).subscribe(response => {
            if (response.success) {
                this.loadProducts();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    cancelProductListDialog() {
        this._showProducts = false;
        this.tt.selection = null;
        this.gt.selection = null;
        this.available = '1';
        alert('Change available');
    }

    cancelProductDialog() {
        // check if product is dirty
        let dirty: boolean = false;
        if (dirty) {
            this._showCancelYesNo = true;
        } else {
            this.productEditCancel();
        }
    }

    cancelProcessListDialog() {
        this._showProcesses = false;
        //this.pt.selection = null;
    }

    preSaveProduct(product: Product) {
        this.selectedProduct = product;
        this.saveProduct();
    }

    preSaveProductForm() {
        let prices = this.selectedProduct.productPrices;
        this.selectedProduct = this._productForm.getRawValue();
        this.selectedProduct.productPrices = prices;
        this.saveProduct();
    }

    saveProduct(newProductGroup: boolean = false) {
        this.disableSaveButton = true;
        this._apiService.setActionUrl('products/products');
        if (!this.selectedProduct.hasParentProduct) {
            this.selectedProduct.parentProductId = '';
            this.selectedProduct.calculationFactor = 1;
        }
        this.removeEmptyPrices();
        if (!newProductGroup) {
            this.selectedProduct.productGroupId = this.selectedProductGroup.id;
        }
        this._apiService.saveRecord(this.selectedProduct).subscribe(response => {
            if (response.success) {
                this._productsService.updateProduct(response.data.records[0]);
                if (this._showProductDialog) {
                    this.cancelProductDialog();
                    this.selectedProduct = new Product();
                    this.loadProducts();
                }
                if (this._showProductGroupChange) {
                    this._showProductGroupChange = false;
                    this.loadProducts();
                    this.selectedProduct = new Product();
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
            this.disableSaveButton = false;
        });

    }

    removeEmptyPrices() {
        this.selectedProduct.productPrices.forEach(function(price, index) {
            if (price.materialCost === 0 || price.materialCost === undefined) {
                this.selectedProduct.productPrices.splice(index, 1);
            }
        }, this);
    }

    productEditCancel() {
        this.removeEmptyPrices();
        this._showProductDialog = false;
        this._showProducts = true;
    }

    editRecord() {
        if (this._showProducts) {
            this.editProduct(this.selectedProduct);
            return;
        }
        if (this._showTypes) {
            this.typeEditInit(this.selectedProductType);
            this.tt.initRowEdit(this.selectedProductType);
            return;
        }
    }

    cancelUnselectProduct(event) {
        this.selectedProduct = event.data;
        this.productProcesses = [];
        this._showProductProcesses = false;
    }

    selectProduct() {
        this._showProductProcesses = false;
        this.productProcesses = [];
    }

    createProduct() {
        this.selectedProduct = new Product();
        this.selectedProduct.productPrices = [];
        this.editProduct(new Product());
    }

    addGroupRow() {
        if (this._canAdd) {
            let newGroup = new ProductGroup();
            newGroup.productTypeId = this.selectedProductType.id;
            this.productGroups.push(newGroup);
        }
    }

    allFinishes() {
        this.finishesFiltered = this.finishesDD;
    }

    filterFinishes(event) {
        this.finishesFiltered = this._st.filterAutoComplete(event, this.finishesDD);
    }

    allProcesses() {
        this.processesFiltered = this.processesDD;
    }

    filterProcesses(event) {
        this.processesFiltered = this._st.filterAutoComplete(event, this.processesDD);
    }

    selectProductType() {
        this._showGroups = false;
        this._showProcesses = false;
        this.productGroups = [];
    }

    selectProductGroup() {
        this._showProcesses = false;
        this._showProducts = false;
        this.products = [];
    }

   updateProcess(event, process: SelectItem) {
        if (event.checked) {
            this.selectedProductType.processes.push(process.value);
        } else {
            let currentIndex = this.selectedProductType.processes.indexOf(process.value);
            this.selectedProductType.processes.splice(currentIndex, 1);
        }
        this.typeEditSave(this.selectedProductType, false);
    }

    getProcessChecked(process: SelectItem): boolean {
        return (this.selectedProductType.processes.indexOf(process.value) > -1);
    }

    selectParentProduct(event) {
        let productId = event.value;
        let products = this._productsService._products;
        this.selectedProduct.parentProductId = productId;
        this.selectedProduct.parentProduct = products.find(function (prod) {
            if (prod.id === productId) {
                return prod;
            }
        });
    }

    importCSV() {
        this._showImport = true;
    }

    showImports() {
        this._showImports = true;
    }

    showProductProcesses(event) {
        this._showProductProcesses = true;
        this._apiService.setActionUrl('products/productProcesses');
        this._apiService.setFilter({field: 'productId', operator: 'eq', value: this.selectedProduct.id});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                this.productProcesses = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    changeProductGroup(event) {
        this._showProductGroupChange = true;
        this.selectProductGroups = [];
        this.productGroups.forEach(function(pg) {
            this.selectProductGroups.push({label: pg.description, value: pg.id});
        }, this);
    }

    cancelProductGroupChangeDialog() {
        this.selectedProduct.productGroupId = this.selectedProductGroup.id;
        this._showProductGroupChange = false;
    }

    addPriceLine(event, pp: ProductPrice) {
        if (pp.oldOptionName === '' || pp.oldOptionName === undefined) {
            let newCode = 'STD';
            let newOptionName = 'Standaard';
            this.selectedProduct.productPrices.forEach(function( ppCheck) {
                if (ppCheck.optionName === 'Standaard' || ppCheck.optionCode === 'STD') {
                    newCode = '';
                    newOptionName = '';
                }
            }, this);
            let ppNew = new ProductPrice();
            ppNew.optionCode = newCode;
            ppNew.optionName = newOptionName;
            this.selectedProduct.productPrices.push(ppNew);
        }
        // this.checkValidity(pp);
    }

    checkValidity(pp: ProductPrice) {
        this.disableSaveButton = false;
        if (pp.optionCode.length === 0 && pp.materialCost > 0) {
            this.disableSaveButton = true;
        } else {
            for (let productPrice of this.selectedProduct.productPrices) {
                if (productPrice.optionCode.length === 0 && productPrice.materialCost > 0) {
                    this.disableSaveButton = true;
                    break;
                }
            }
        }
    }

    deleteProductPrice(priceId: string, index: number) {
        if (priceId === '') {
            this.selectedProduct.productPrices.splice(index, 1);
        } else {
            this._apiService.setActionUrl('products/products');
            this._apiService.specialPostAction(JSON.stringify({productPriceId: priceId}), 'deleteProductPrice').subscribe(response => {
                if (response.success) {
                    this.selectedProduct.productPrices.splice(index, 1);
                } else {
                    this._auth.addError(response.errorMsg);
                }
            })
        }
        this.checkValidity(new ProductPrice());
    }

    toggleAvailable() {
        if (this.available === '1') {
            this.available = '0';
        } else {
            this.available = '1';
        }
        this.loadProducts();
    }
}
