<p-dialog [(visible)]="_showNewCompany" positionTop="250" positionLeft="250" styleClass="ui-g-6 pDialog" [modal]="true" (onHide)="hideDialog()">
    <form [formGroup]="_companyForm" novalidate (ngSubmit)="saveCustomer">
        <div class="ui-g-3"><label for="customer">{{ 'orders.customer' | translate }}</label></div>
        <div class="ui-g-9">
            <p-dropdown [options]="_customerDropDown" id="customer" formControlName="customerId" filter="true" (onChange)="preLoadContacts($event)" appendTo="body"></p-dropdown>
        </div>
        <div class="ui-g-3"><label for="contact">{{ 'orders.contact' | translate }}</label></div>
        <div class="ui-g-9">
            <p-dropdown [options]="_contactDropDown" id="contact" formControlName="contactId" filter="true" appendTo="body"></p-dropdown>
        </div>
    </form>
    <button type="button" pButton icon="fa fa-save" label="{{ 'base.save' | translate}}" (click)="saveCustomer()"></button>
</p-dialog>
