<div id="moduleMenu">
    <span *ngIf="selectedUser" class="itemName floatLeft">{{ 'users.user' | translate }}: {{ selectedUser.fullName }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'users.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadUsers()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="_auth.modules['users'].authLevel >= 30"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createUser()" label="{{ 'base.create' | translate }}"></button>
        <!--button *ngIf="selectedUser && _auth.modules.users.authLevel >= 20 && !newUser"
                class="pButton" type="button" pButton icon="fa fa-pencil-square-o" (click)="editUser()" label="{{ 'base.edit' | translate }}"></button>
        <button *ngIf="selectedUser && _auth.modules.users.authLevel >= 40 && !newUser"
                class="pButton" type="button" pButton icon="fa fa-minus-square-o" (click)="deleteUser()" label="{{ 'base.delete' | translate }}"></button-->
    </div>
    <p-table #dt [loading]="loading" [value]="users" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
             [(contextMenuSelection)]="selectedUser" [contextMenu]="cm"
             [sortField]="'userName'">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'userName'">{{ 'base.userName' | translate }}
                    <p-sortIcon [field]="'userName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'fullName'">{{ 'users.fullName' | translate }}
                    <p-sortIcon [field]="'fullName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'lastLogin'">{{ 'users.lastLogin' | translate }}
                    <p-sortIcon [field]="'lastLogin'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                    <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                    <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'userName', 'contains')"></th>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'fullName', 'contains')"></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr [ngClass]="{ 'redLine' : (user.loginEnabled == 0)}" [pContextMenuRow]="user" (dblclick)="onRowDblclick($event, user)">
                <td>{{ user.userName }}</td>
                <td>{{ user.fullName }}</td>
                <td>
                    <djc-date [timeInput]="user.lastLogin" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                </td>
                <td>{{ user.modifier.fullName }}</td>
                <td>
                    <djc-date [timeInput]="user.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
    <div *ngIf="displayDialog">
        <p-dialog header="{{ _recordTitle }}" [(visible)]="displayDialog" [modal]="true" [responsive]="true"
                  [styleClass]="'ui-g-6 pDialog'" (onHide)="cancel()">
            <p-tabView effect="fade">
                <p-tabPanel header="{{ 'users.account' | translate }}">
                    <form [formGroup]="_userForm" novalidate (ngSubmit)="saveUser()">
                        <div class="ui-g-6">
                            <div class="ui-g-4"><label for="fullName">{{ 'users.fullName' | translate }}</label></div>
                            <div class="ui-g-8"><input pInputText id="fullName" formControlName="fullName"/></div>
                            <div class="ui-g-4"><label for="userName">{{ 'base.userName' | translate }}</label></div>
                            <div class="ui-g-8"><input pInputText id="userName" formControlName="userName" [readOnly]="!newUser"/></div>
                            <div class="ui-g-4"><label for="passwordOne">{{ 'base.password' | translate }}</label></div>
                            <div class="ui-g-7"><input pPassword type="password" id="passWordOne" formControlName="password"/></div>
                            <div class="ui-g-4"><label for="passWordTwo">{{ 'users.passwordRepeat' | translate }}</label></div>
                            <div class="ui-g-7"><input pPassword type="password" id="passWordTwo" formControlName="password2"/></div>
                            <div class="ui-g-1">
                                <button class="ui-button-info" type="button" pButton icon="fa fa-plus" style="margin-left: 0px;"
                                        (click)="generatePassword()"></button>
                            </div>
                        </div>
                        <div class="ui-g-6">
                            <div class="ui-g-4"><label for="mailAddress">{{ 'base.email' | translate }}</label></div>
                            <div class="ui-g-8"><input pInputText id="mailAddress" formControlName="email"/></div>
                            <div class="ui-g-4"><label for="reference">{{ 'users.reference' | translate }}</label></div>
                            <div class="ui-g-8"><input pInputText id="reference" formControlName="reference" /></div>
                            <div class="ui-g-12">
                                <p-checkbox label="{{ 'users.loginEnabled' | translate }}" formControlName="loginEnabled"
                                            [value]="selectedUser.loginEnabled" binary="true" [disabled]="_auth.modules['users'].authLevel < 100"></p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox label="{{ 'users.forcePasswordChange' | translate }}" formControlName="forcePasswordChange"
                                            binary="true" [disabled]="_auth.modules['users'].authLevel < 100"></p-checkbox>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel header="{{ 'base.settings' | translate }}">
                    <form [formGroup]="_settingsForm" novalidate (ngSubmit)="saveUser()">
                        <div class="ui-g-6">
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="dateFormat">{{ 'base.dateFormat'| translate }}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="dateFormat" [options]="_dateFormats"
                                                formControlName="dateFormat"></p-dropdown>
                                </div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="timeFormat">{{ 'base.timeFormat'| translate }}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="timeFormat" [options]="_timeFormats"
                                                formControlName="timeFormat"></p-dropdown>
                                </div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="language">{{ 'base.language'| translate }}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="language" [options]="_langs"
                                                formControlName="language"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="ui-g-6">
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="defaultRows">{{ 'base.rows'| translate }}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="defaultRows" [options]="_rows"
                                                formControlName="defaultRows"></p-dropdown>
                                </div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="expiresSeconds">{{ 'users.autoLogoutTime'| translate }}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="expiresSeconds" [options]="_expiresSeconds"
                                                formControlName="expiresSeconds"></p-dropdown>
                                </div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="thousandSeparator">{{ 'users.thousandSeparator'| translate }}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="thousandSeparator" [options]="_separators"
                                                formControlName="thousandSeparator"></p-dropdown>
                                </div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="decimalSeparator">{{ 'users.decimalSeparator'| translate }}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="decimalSeparator" [options]="_separators"
                                                formControlName="decimalSeparator"></p-dropdown>
                                </div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="signatureFile">{{ 'settings.file' | translate }}</label></div>
                                <div class="ui-g-8"><p-fileUpload id="signatureFile" name="signatureFile[]" accept=".jpg, .png, .jpeg" maxFileSize="5000000" chooseLabel="Browse"
                                                                  [customUpload]="false" url="api/users/usersignatures/upload" (onBeforeUpload)="uploadFile($event, selectedUser)"
                                                                  (onUpload)="uploadReady($event)"></p-fileUpload></div>

                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel header="{{ 'groups.name' | translate }}" [disabled]="_auth.modules['users'].authLevel < 100">
                    <div class="ui-g-6">
                        <div *ngFor="let group of groups">
                            <div class="ui-g">
                                <div class="ui-g-4" style="text-align: center;">
                                    <p-checkbox name="groupSelector" value="{{group.id}}"
                                                [(ngModel)]="selectedGroups" inputId="{{ 'group-' + group.id }}"></p-checkbox>
                                </div>
                                <div class="ui-g-8"><label for="group-{{ group.id }}">{{ group.name }}</label></div>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="{{ 'modules.name' | translate }}" [disabled]="newUser || _auth.modules['users'].authLevel < 100">
                    <p-table #mt [value]="aclModules" [sortField]="'name'">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{ 'base.strName' | translate }}</th>
                                <th width="15%">{{ 'base.none' | translate }}</th>
                                <th width="15%">{{ 'base.use' | translate }}</th>
                                <th width="15%">{{ 'base.manage' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-module>
                            <tr>
                                <td>{{ module.name }}</td>
                                <td style="text-align: center">
                                    <p-radioButton name="{{module.id}}" value="0" [(ngModel)]="module.authLevel"
                                                   [disabled]="module.authLevelGroup > 100"></p-radioButton>
                                </td>
                                <td style="text-align: center">
                                    <p-radioButton name="{{module.id}}" value="10" [(ngModel)]="module.authLevel"
                                                   [disabled]="module.authLevelGroup > 100"></p-radioButton>
                                </td>
                                <td style="text-align: center">
                                    <p-radioButton name="{{module.id}}" value="100" [(ngModel)]="module.authLevel"
                                                   [disabled]="module.authLevelGroup > 100"></p-radioButton>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
            </p-tabView>
            <p-footer>
                <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveUser()"
                        label="{{ 'base.save' | translate }}" [disabled]="!(_userForm.valid && _settingsForm.valid)"></button>
                <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                        label="{{ 'base.cancel' | translate }}"></button>
                <button class="pButton" type="button" pButton icon="fa fa-close" (click)="checkForm()"
                        label="Controle" [hidden]="(_userForm.valid && _settingsForm.valid)"></button>
            </p-footer>
        </p-dialog>
        <p-confirmDialog header="{{ 'base.confirmation' | translate }}" icon="fa fa-exclamation-circle" width="425" #cd>
            <p-footer>
                <button type="button" pButton icon="fa fa-check" label="OK" (click)="cd.accept()"></button>
            </p-footer>
        </p-confirmDialog>
    </div>
</div>
