<div id="moduleMenu">
    <!--span *ngIf="_selectedAddressbook" class="itemName floatLeft">{{ 'addressbook.addressbook' | translate }}: {{ _selectedAddressbook.name }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'addressbook.name' | translate }}</span-->
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button class="pButton" type="button" pButton icon="fa fa-refresh" label="{{ 'base.refresh' | translate }}"></button>
    </div>
    <div class="ui-g" style="height: calc(100% - 65px); padding-bottom: 35px;">
        SOME CONTENT
    </div>
</div>
