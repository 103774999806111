<div class="entryform jumbotron center-block">
    <div *ngIf="_loginForm">
        <h1>{{ 'base.loginDescription' | translate }}</h1>
        <form #loginForm="ngForm" (ngSubmit)="login()">
            <div class="form-group">
                <label for="username">{{ 'base.userName' | translate }}</label>
                <input type="text" name="username" class="form-control" id="username" placeholder="{{ 'base.userName' | translate }}" [(ngModel)]="_login.userName">
            </div>
            <div class="form-group">
                <label for="password">{{ 'base.password' | translate }}</label>
                <input type="password" name="password" class="form-control" id="password" placeholder="{{ 'base.password' | translate }}" [(ngModel)]="_login.password">
            </div>
            <button type="submit" class="btn btn-default">{{ 'base.login' | translate }}</button>
            <button type="button" class="btn btn-default" (click)="forgotPassword($event)">{{ 'base.forgotPassword' | translate }}</button>
            <button type="button" class="btn btn-default" (click)="forgotUserName()">{{ 'base.forgotUserName' | translate }}</button>
        </form>
    </div>
    <div *ngIf="_userNameForm">
        <h1>{{ 'base.forgotUserName' | translate }}</h1>
        <form #userNameForm="ngForm" (ngSubmit)="userName()">
            <div class="form-group">
                <label for="mailAddress">{{ 'base.mailAddress' | translate }}</label>
                <input type="email" name="mailAddress" class="form-control" id="mailAddress" placeholder="{{ 'base.email' | translate }}" [(ngModel)]="_forgotMailAddress">
            </div>
            <button type="submit" class="btn btn-default">{{ 'base.send' | translate }}</button>
        </form>
    </div>
</div>