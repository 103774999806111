<div class="ui-g module">
    <div class="ui-g-12">
        <p-table #importsTable tableStyleClass="djc-table" [value]="imports" [rows]="_auth.defaultRows"
                 [paginator]="true" [pageLinks]="5">
            <ng-template pTemplate="header">
                <tr>
                    <th>Datum</th>
                    <th>Leverancier</th>
                    <th>Bestand</th>
                </tr>
                <tr>
                    <th></th>
                    <th><input pInputText type="text" (input)="importsTable.filter($event.target.value, 'supplier.name', 'contains')"></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>{{rowData.importDate}}</td>
                    <td>{{rowData.supplier.name}}</td>
                    <td>{{rowData.fileName}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
