
import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ApiService} from "./api.service";
import {ToolsModule} from "./support.tools";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AclService} from "./acl.service";
import {AuthService} from "./auth.service";
import {GroupsService} from "./groups/groups.service";
import {UsersService} from "./users/users.service";
import {AclModule} from "./acl.module";
import { TableModule } from "primeng/table";
import { ConfirmationService } from "primeng/api";
import { ListboxModule } from "primeng/listbox";
import {KeyboardShortcutsModule} from "ng-keyboard-shortcuts";
import {ProductsService} from "./products/products.service";
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TreeTableModule } from 'primeng/treetable';
import { SharedModule as SharedPrimeNg} from "primeng/api";
import { CheckboxModule } from 'primeng/checkbox';
import { SpinnerModule } from 'primeng/spinner';
import { PasswordModule } from 'primeng/password';
import { FieldsetModule } from 'primeng/fieldset';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessagesModule } from 'primeng/messages';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from "primeng/tooltip";

@NgModule({
    declarations: [

    ],
    imports: [

    ],
    exports: [
        AclModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ButtonModule,
        TableModule,
        InputTextModule,
        TabViewModule,
        DropdownModule,
        CalendarModule,
        DialogModule,
        AutoCompleteModule,
        TreeTableModule,
        SharedPrimeNg,
        CheckboxModule,
        SpinnerModule,
        PasswordModule,
        FieldsetModule,
        ProgressBarModule,
        MessagesModule,
        FileUploadModule,
        MultiSelectModule,
        BrowserAnimationsModule,
        ToolsModule,
        ConfirmDialogModule,
        RadioButtonModule,
        ListboxModule,
        ContextMenuModule,
        InputSwitchModule,
        InputTextareaModule,
        KeyboardShortcutsModule,
        ScrollPanelModule,
        TooltipModule
    ],
    providers: [
        ApiService,
        AclService,
        AuthService,
        GroupsService,
        UsersService,
        ProductsService,
        ConfirmationService,
        TitleCasePipe,
        DatePipe,
        CurrencyPipe,
        DecimalPipe
    ]
})
export class SharedModule {}
