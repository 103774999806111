<div *ngIf="_productsService.isLoaded()">
    <p-table #orderPartsTable tableStyleClass="djc-table" [loading]="_loadingParts" [value]="orderParts" [scrollable]="true" scrollHeight="flex"
             selectionMode="single" dataKey="id" (onRowSelect)="selectOrderPart($event)" [contextMenu]="cm" contextMenuSelectionMode="joint">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:8em;">Code</th>
                <th>{{ 'base.strDescription' | translate }}</th>
                <th style="width:15em ">{{ 'orders.acreage' | translate }}</th>
                <th style="width:15em ">{{ 'orders.length' | translate }}</th>
                <th style="width:20em ">{{ 'orders.salesPrice' | translate }}</th>
                <th *ngIf="_order.uisVersion >= 3" style="width:20em ">{{ 'orders.roofingType' | translate }}</th>
                <th *ngIf="_canDelete" style="width:4em;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-orderPart let-editing="editing" let-ri="rowIndex">
            <tr [pContextMenuRow]="orderPart" [pSelectableRow]="orderPart" [ngStyle]="_st.getRowStyle(orderPart.id, selectedOrderPart.id, ri)">
                <td *ngIf="!_canEdit" style="width:8em;">{{ orderPart.code }}</td>
                <td *ngIf="_canEdit" [pEditableColumn]="orderPart" [pEditableColumnRowIndex]="ri" style="width:8em;">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="orderPart.code" (ngModelChange)="setDirtyFlag(ri)"/>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPart.code }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="!_canEdit">{{ orderPart.description }}</td>
                <td *ngIf="_canEdit"  [pEditableColumn]="orderPart" [pEditableColumnRowIndex]="ri">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="orderPart.description" (ngModelChange)="setDirtyFlag(ri)"/>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPart.description }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="!_canEdit || orderPart._lengthFilled" class="right" style="width:15em ">{{ orderPart.size }}</td>
                <td *ngIf="_canEdit && !orderPart._lengthFilled" [pEditableColumn]="orderPart" [pEditableColumnRowIndex]="ri" class="right" style="width:15em ">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderPart.size" (ngModelChange)="sizeChanged(orderPart, ri)"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPart.size }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="!_canEdit || orderPart._sizeFilled" class="right" style="width:15em ">{{ orderPart.length }}</td>
                <td *ngIf="_canEdit && !orderPart._sizeFilled" [pEditableColumn]="orderPart" [pEditableColumnRowIndex]="ri" class="right" style="width:15em ">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderPart.length" (ngModelChange)="sizeChanged(orderPart, ri)"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPart.length }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="!_canEdit" class="right" style="width:20em ">{{ orderPart.salesPrice }}</td>
                <td *ngIf="_canEdit" [pEditableColumn]="orderPart" [pEditableColumnRowIndex]="ri" class="right" style="width:20em ">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-spinner [(ngModel)]="orderPart.salesPrice" (ngModelChange)="setDirtyFlag(ri)"
                                       [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                       [decimalSeparator]="_auth.getUserSettings().decimalSeparator">
                            </p-spinner>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPart.salesPrice }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width:20em" *ngIf="!_canEdit && _order.uisVersion >= 3">{{ orderPart.roofingType.description }}</td>
                <td style="width:20em" *ngIf="_canEdit && _order.uisVersion >= 3" [pEditableColumn]="orderPart" [pEditableColumnRowIndex]="ri">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <djc-autoComplete [ngClass]="'djcAutoComplete'" appendTo="body" [suggestions]="roofingTypesFiltered" (completeMethod)="filterRoofingTypes($event)"
                                              (onClear)="allRoofingTypes()" field="label" (onSelect)="changeRoofingType($event, ri, orderPart)"
                                              (onFocus)="selectInputContent($event)" [scrollHeight]="'300px'" [panelStyle]="{'width':'30em'}"
                                              [ngModelOptions]="{standalone: true}" [ngModel]="{label: orderPart.roofingType.description}" [dropdown]="true"
                                              [dropdownMode]="'current'">
                            </djc-autoComplete>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ orderPart.roofingType.description }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="_canDelete" style="width:4em;text-align:center">
                    <button pButton type="button" icon="fa fa-times" class="ui-button-danger" style="margin-right: .3em;margin-left: .2em"
                            (click)="orderPartDelete(orderPart, ri)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-contextMenu #cm [model]="partItems" appendTo="body"></p-contextMenu>
    <div *ngIf="_showFinishes">
        <orderFinishes [part]="selectedOrderPart" [(visible)]="_showFinishes" [order]="_order"></orderFinishes>
    </div>
    <ng-keyboard-shortcuts [shortcuts]="kbShortCuts"></ng-keyboard-shortcuts>
    <ng-keyboard-shortcuts-help [key]="'f1'" [closeKey]="'esc'" [title]="'Help'"></ng-keyboard-shortcuts-help>
</div>
<div *ngIf="!_productsService.isLoaded()"><span class="fa fa-spinner">Nog niet alles geladen</span></div>
