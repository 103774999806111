<div id="moduleMenu">
    <span *ngIf="selectedModule" class="itemName floatLeft">{{ 'modules.module' | translate }}: {{ selectedModule.name }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'modules.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button *ngIf="showTable"
                class="pButton floatLeft" type="button" pButton icon="fa fa-refresh" (click)="loadModules()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="displayDialog"
                class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()" label="{{ 'base.cancel' | translate }}"></button>
    </div>
    <p-table #dt *ngIf="showTable" [loading]="loading" [value]="modules" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
             [(contextMenuSelection)]="selectedModule" [contextMenu]="cm">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'name'">
                    {{ 'modules.name' | translate }}
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                </th>
                <th>{{ 'base.strDescription' | translate }}</th>
                <th>{{ 'modules.installed' | translate }}</th>
            </tr>
            <tr>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains')"></th>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'description', 'contains')"></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-module>
            <tr [pContextMenuRow]="module" (dblclick)="onRowDblclick($event, module)">
                <td>{{ module.name }}</td>
                <td>{{ module.description }}</td>
                <td>
                    <button *ngIf="!module.installed && _auth.modules.modules.authLevel == 100"
                            class="pButton" type="button" pButton (click)="installModule(module)" label="{{ 'modules.install' | translate }}"></button>
                    <button *ngIf="module.installed && _auth.modules.modules.authLevel == 100 &&
                                !(module.moduleName === 'base' || module.moduleName === 'modules' ||
                                module.moduleName === 'groups' || module.moduleName === 'users')"
                            class="pButton" type="button" pButton (click)="uninstallModule(module)" label="{{ 'modules.uninstall' | translate }}"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
    <div *ngIf="displayDialog">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.security' | translate}}" [disabled]="_auth.modules.modules.authLevel < 100">
                <acl *ngIf="_auth.modules.modules.authLevel == 100"></acl>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
