import {Component, OnInit} from "@angular/core";
import {AuthService} from "../auth.service";
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {ProductImports} from "./products.model";

@Component({
    selector: 'productimports',
    templateUrl: './product-imports.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductImportsComponent implements OnInit {

    public imports: ProductImports[] = [];
    constructor(public _auth: AuthService, private _api: ApiService, private _transService: TranslateService) {
    }
    ngOnInit(): void {
        this.loadImports();
    }

    private loadImports() {
        this._api.setActionUrl('products/productImport');
        this._api.setSort({field: 'importDate', direction: 'desc'});
        this._api.getStore().subscribe(response => {
            if (response.success) {
                this.imports = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

}
