import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {MasterDataComponent} from "./masterdata.component";
import {ChoicesComponent} from "./choices.component";

@NgModule({
    imports: [
        SharedModule
    ],
    exports: [
        ChoicesComponent
    ],
    declarations: [
        MasterDataComponent,
        ChoicesComponent
    ]
})
export class MasterDataModule {

}
