<div id="moduleMenu">
    <span class="itemName floatLeft">{{ getTitle() }}</span>
    <span id="ordersMenuTitle" class="floatRight">{{ 'orders.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <p-dropdown *ngIf="!_showOrderDialog" class="ui-g-1 pButton noPadding" [options]="years" [(ngModel)]="selectedYear" (onChange)="loadOrders(0)"></p-dropdown>
        <button *ngIf="!_showOrderDialog" class="pButton floatLeft" type="button" pButton icon="fa fa-refresh" (click)="reloadOrders()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="_auth.modules['orders'].authLevel >= 20 && !_showOrderDialog"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createOrder()" label="{{ 'base.create' | translate }}"></button>
        <span *ngIf="_showFinishes" class="floatLeft">{{ selectedOrderPart.code + ': ' + selectedOrderPart.description }}</span>
    </div>
    <div class="ui-g" *ngIf="!_showOrderDialog" style="height: 100%">
        <div class="ui-g-12 ui-md-12">
            <p-table #ordersTable tableStyleClass="djc-table" [loading]="ordersLoading" [value]="orders" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
                     [scrollable]="true" scrollHeight="calc(100% - 70px)"
                     selectionMode="single" [(selection)]="selectedOrder" (onRowSelect)="loadVersions()" sortMode="multiple" [sortField]="'orderNumber'" [sortOrder]="-1">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'orderNumber'" width="5%">{{ 'orders.orderNumber' | translate }}</th>
                        <th width="15%">{{ 'orders.customer' | translate }}</th>
                        <th>{{ 'orders.projectTitle' | translate }}</th>
                        <th width="7%">{{ 'orders.orderDate' | translate }}</th>
                    </tr>
                    <tr>
                        <th><input pInputText type="text" (input)="ordersTable.filter($event.target.value, 'orderNumber', 'startsWith')"></th>
                        <th><input pInputText type="text" (input)="ordersTable.filter($event.target.value, 'customer.displayName', 'contains')"></th>
                        <th><input pInputText type="text" (input)="ordersTable.filter($event.target.value, 'displayTitle', 'contains')"></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order let-ri="rowIndex">
                    <tr [pContextMenuRow]="order" [pSelectableRow]="order" [ngStyle]="_st.getRowStyle(order.id, selectedOrder.id, ri)">
                        <td width="5%">{{ order.orderNumber }}</td>
                        <td width="15%">{{ order.displayName }}</td>
                        <td>{{ order.displayTitle }}</td>
                        <td width="7%">
                            <djc-date [timeInput]="order.orderDate" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </td>
                    </tr>
                    <tr *ngIf="_showVersion && selectedOrder.id == order.id">
                        <td [colSpan]="4">
                            <p-table #versionTable tableStyleClass="djc-table" [value]="orderVersions" [rows]="10" [paginator]="true" [pageLinks]="5"
                                     selectionMode="single" [(selection)]="selectedVersion" (onRowSelect)="preLoadOrderForm()">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [pSortableColumn]="'versionNumber'" width="3%">#</th>
                                        <th>{{ 'base.strDescription' | translate }}</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-orderVersion let-ri="rowIndex">
                                    <tr [pSelectableRow]="orderVersion" [ngStyle]="_st.getRowStyle(orderVersion.id, selectedVersion.id, ri)">
                                        <td>{{ orderVersion.versionNumber }}</td>
                                        <td>{{ orderVersion.projectTitle + ' - ' + orderVersion.projectDescription.substring(0, 150) }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <ng-keyboard-shortcuts [shortcuts]="kbShortCuts"></ng-keyboard-shortcuts>
        <ng-keyboard-shortcuts-help [key]="'f1'" [closeKey]="'esc'" [title]="'Help'"></ng-keyboard-shortcuts-help>
    </div>
    <div *ngIf="_showOrderDialog && !_showFinishes" style="height: calc(100% - 100px);">
        <p-scrollPanel [style]="{width: '100%', height: '100%'}">
            <p-tabView [activeIndex]="tabIndex" (onChange)="handleTabChange($event)">
                <p-tabPanel header="{{ 'orders.calculation' | translate }}">
                    <form [formGroup]="_orderForm" novalidate>
                        <p-fieldset legend="{{ 'base.details' | translate }}">
                            <div class="ui-g">
                                <div class="ui-g-12">
                                    <div class="ui-g-2"><label for="orderNumber">{{ 'orders.number' | translate }}</label></div>
                                    <div class="ui-g-2"><input pInputText type="text" id="orderNumber" formControlName="orderNumber"/></div>
                                    <div class="ui-g-1"><input pInputText type="text" formControlName="versionNumber"/></div>
                                    <div class="ui-g-1"><label for="order">{{ 'orders.order' | translate }}</label></div>
                                    <div class="ui-g-1">
                                        <p-checkbox id="order" formControlName="order" binary="true"></p-checkbox>
                                    </div>
                                    <div class="ui-g-1"><label for="construction">{{ 'orders.construction' | translate }}</label></div>
                                    <div class="ui-g-1">
                                        <p-checkbox id="construction" formControlName="isConstruction" binary="true"
                                                    (ngModelChange)="constructionRenovationChange('isConstruction', 'isRenovation')"></p-checkbox>
                                    </div>
                                    <div class="ui-g-1"><label for="renovation">{{ 'orders.renovation' | translate }}</label></div>
                                    <div class="ui-g-1">
                                        <p-checkbox id="renovation" formControlName="isRenovation" binary="true"
                                                    (ngModelChange)="constructionRenovationChange('isRenovation', 'isConstruction')"></p-checkbox>
                                    </div>
                                    <div class="ui-g-1"></div>
                                </div>
                                <div class="ui-g-12">
                                    <div class="ui-g-2"><label for="employee">Gecalculeerd door</label></div>
                                    <div class="ui-g-8">
                                        <p-dropdown [options]="_employeeDropDown" id="employee" formControlName="employeeId" filter="true"></p-dropdown>
                                    </div>
                                    <div class="ui-g-2">
                                        <button class="ui-g-12" pButton icon="fa fa-trash" label="{{ 'base.delete' | translate }}" (click)="deleteOrder()" [disabled]="!_canDelete"></button>
                                    </div>
                                </div>
                                <div class="ui-g-12">
                                    <div class="ui-g-2"><label for="responsible">Uitgebracht door</label></div>
                                    <div class="ui-g-8">
                                        <p-dropdown [options]="_employeeDropDown" id="responsible" formControlName="responsibleId" filter="true"></p-dropdown>
                                    </div>
                                    <div class="ui-g-2">
                                    </div>
                                </div>
                                <div class="ui-g-12">
                                    <div class="ui-g-2"><label for="project">{{ 'orders.project' | translate }}</label></div>
                                    <div class="ui-g-8"><input pInputText type="text" id="project" formControlName="projectTitle" maxlength="100"/></div>
                                    <div class="ui-g-2">
                                        <button class="ui-g-12" pButton icon="fa fa-copy" label="{{ 'orders.newVersion' | translate }}" (click)="preNewOrderVersion()" [disabled]="!_canAdd"></button>
                                    </div>
                                </div>
                                <div class="ui-g-12">
                                    <div class="ui-g-2"><label for="address">{{ 'orders.address' | translate }}</label></div>
                                    <div class="ui-g-5"><input pInputText type="text" id="address" formControlName="workAddress" maxlength="250"/></div>
                                    <div class="ui-g-1"></div>
                                    <div class="ui-g-4"><input pInputText type="text" formControlName="workCity" maxlength="150"/></div>
                                </div>
                                <div class="ui-g-12">
                                    <div class="ui-g-2"><label for="description">{{ 'base.strDescription' | translate }}</label></div>
                                    <div class="ui-g-8"><textarea pInputTextarea id="description" formControlName="projectDescription"></textarea></div>
                                    <div class="ui-g-2"></div>
                                </div>
                            </div>
                        </p-fieldset>
                    </form>
                    <!--div class="ui-g" style="height: 350px">
                        <orderParts [order]="selectedVersion" [(showFinishes)]="_showFinishes" [(orderPart)]="selectedOrderPart" [(reloadParts)]="_reloadOrderParts"></orderParts>
                    </div-->
                </p-tabPanel>
                <newCompany [(showNewCompany)]="_showNewContact" [orderId]="selectedVersion.parentId" [sortOrder]="maxSortOrder" [customer]="_orderCustomer" (onHide)="loadOrderCustomers()"></newCompany>
                <p-tabPanel header="{{ 'orders.details' | translate }}">
                    <form [formGroup]="_orderForm" novalidate (ngSubmit)="preSaveOrder()">
                        <div class="ui-g">
                            <div class="ui-g-6">
                                <p-fieldset legend="{{ 'orders.customer' | translate }}">
                                    <div class="ui-g">
                                        <div class="ui-g-10">
                                            <div class="ui-g-3"><label for="customer">{{ 'orders.customer' | translate }}</label></div>
                                            <div class="ui-g-9">
                                                <p-dropdown [options]="_customerDropDown" id="customer" formControlName="customerId" filter="true" (onChange)="preLoadContacts($event)"></p-dropdown>
                                            </div>
                                            <div class="ui-g-3"><label for="contact">{{ 'orders.contact' | translate }}</label></div>
                                            <div class="ui-g-9">
                                                <p-dropdown [options]="_contactDropDown" id="contact" formControlName="contactId" filter="true"></p-dropdown>
                                            </div>
                                        </div>
                                        <div class="ui-g-2">
                                            <button type="button" pButton icon="fa fa-add" (click)="newContact()" icon="fa fa-plus"></button>
                                        </div>
                                    </div>
                                    <p-table *ngIf="selectedVersion.orderCustomers.length > 1 && _ocLoaded" #orderCustomersTable tableStyleClass="djc-table" [value]="selectedVersion.orderCustomers" [rows]="10" [paginator]="false">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width: 3em">#</th>
                                                <th>{{ 'orders.customer' | translate }}</th>
                                                <th>{{ 'orders.contact' | translate }}</th>
                                                <th style="width:3em"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-orderCustomer let-ri="rowIndex">
                                            <tr *ngIf="orderCustomer.sortOrder !== 'a'">
                                                <td style="width: 3em; text-align: center">{{ orderCustomer.sortOrder }}</td>
                                                <td>{{ orderCustomer.customer.name }}</td>
                                                <td>{{ orderCustomer.contact.name }}</td>
                                                <td style="width: 3em"><button *ngIf="orderCustomer.sortOrder !== 'a'" pButton type="button" icon="fa fa-pencil" class="ui-button-info" style="margin-right: .3em;margin-left: .2em"
                                                                               (click)="editOrderCustomer(orderCustomer)"></button></td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-fieldset>
                                <p-fieldset legend="{{ 'orders.labourCost' | translate }}">
                                    <div class="ui-g">
                                        <div class="ui-g-2">{{ 'orders.labourCost' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                       [min]="0" [step]="0.01" formControlName="labourCost" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class=ui-g-6></div>
                                        <div class="ui-g-2">{{ 'orders.safetyHours' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="safetyHours" [step]="0.5" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.transportHours' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="transportHours" [step]="0.5" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.preparationHours' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="preparationHours" [step]="0.5" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                    </div>
                                </p-fieldset>
                                <p-fieldset legend="{{ 'orders.otherCosts' | translate }}">
                                    <div class="ui-g">
                                        <div class="ui-g-2">{{ 'orders.travelHours' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="travelHours" [step]="0.5" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.travelCost' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="travelCost" [step]="0.5" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.calculationHours' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="calculationHours" [step]="0.5" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.calculationCost' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="calculationCost" [step]="0.01" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.inspectionHours' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="inspectionHours" [step]="0.5" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-6"></div>
                                        <div class="ui-g-2">{{ 'orders.parkingCost' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="parkingCost" [step]="0.01" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-6">{{ 'orders.parkingCostUnit' | translate }}</div>
                                        <div *ngIf="selectedVersion.uisVersion < 3">
                                            <div class="ui-g-2">{{ 'orders.craneHours' | translate }}</div>
                                            <div class="ui-g-4">
                                                <p-spinner
                                                    [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                    formControlName="craneHours" [formatInput]="false" [step]="0.1">
                                                </p-spinner>
                                            </div>
                                            <div class="ui-g-2">{{ 'orders.craneCost' | translate }}</div>
                                            <div class="ui-g-4">
                                                <p-spinner
                                                    [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                    formControlName="craneCost" [step]="0.01" [formatInput]="false">
                                                </p-spinner>
                                            </div>
                                        </div>
                                    </div>
                                </p-fieldset>
                            </div>
                            <div class="ui-g-6">
                                <p-fieldset legend="{{ 'orders.profit' | translate }}">
                                    <div class="ui-g">
                                        <div class="ui-g-2">{{ 'orders.commonCosts' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="commonCosts" [step]="0.01" [formatInput]="false">
                                            </p-spinner>
                                            %
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.bonusContractPrice' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="bonusContractPrice" [step]="0.01" [formatInput]="false">
                                            </p-spinner>
                                            %
                                        </div>
                                        <div class="ui-g-2" >{{ 'orders.profitAndRisk' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="profitAndRisk" [step]="0.01" [formatInput]="false" (onChange)="calculateTotal('profitAndRisk')">
                                            </p-spinner>
                                            %
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.calculatedPrice' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="totalPrice" [step]="100" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-6"></div>
                                        <div class="ui-g-2">{{ 'orders.quotationPrice' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="newTotal" [step]="100" [formatInput]="false" (onChange)="calculateTotal('endPrice')">
                                            </p-spinner>
                                        </div>
                                    </div>
                                </p-fieldset>
                                <p-fieldset legend="{{ 'orders.vat' | translate }}">
                                    <div class="ui-g">
                                        <div class="ui-g-2">{{ 'orders.vatLabour' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="vatLabour" [step]="0.1" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.vatMaterials' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-spinner
                                                [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                                                formControlName="vatMaterials" [step]="0.1" [formatInput]="false">
                                            </p-spinner>
                                        </div>
                                        <div class="ui-g-2">Particulier</div>
                                        <div class="ui-g-4"><p-checkbox formControlName="privateAssignment" binary="true"></p-checkbox></div>
                                    </div>
                                </p-fieldset>
                                <p-fieldset legend="{{ 'orders.dates' | translate }}">
                                    <div class="ui-g">
                                        <div class="ui-g-2">{{ 'orders.quotationDate' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-calendar [showIcon]="false" dateFormat="dd-mm-yy" formControlName="orderDateDate"></p-calendar>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.sendDate' | translate }}</div>
                                        <div class="ui-g-4">
                                            <p-calendar [showIcon]="false" dateFormat="dd-mm-yy" formControlName="sendDateDate"></p-calendar>
                                        </div>
                                    </div>
                                </p-fieldset>
                                <p-fieldset legend="{{ 'orders.extraInfo' | translate }}">
                                    <div class="ui-g">
                                        <div class="ui-g-2">{{ 'orders.quotation' | translate }}</div>
                                        <div class="ui-g-2">
                                            <p-checkbox formControlName="addQuotation" binary="true"></p-checkbox>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.constructionPhysicalCalculation' | translate }}</div>
                                        <div class="ui-g-2">
                                            <p-checkbox formControlName="constructionPhysicalCalculation" binary="true"></p-checkbox>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.windLoad' | translate }}</div>
                                        <div class="ui-g-2">
                                            <p-checkbox formControlName="windLoad" binary="true"></p-checkbox>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.cadDrawings' | translate }}</div>
                                        <div class="ui-g-2">
                                            <p-checkbox formControlName="cadDrawings" binary="true"></p-checkbox>
                                        </div>
                                        <div class="ui-g-2">{{ 'orders.photos' | translate }}</div>
                                        <div class="ui-g-2">
                                            <p-checkbox formControlName="photos" binary="true"></p-checkbox>
                                        </div>
                                        <div class="ui-g-4"></div>
                                        <div class="ui-g-2">{{ 'orders.otherInfo' | translate }}</div>
                                        <div class="ui-g-10"><input pInputText type="text" formControlName="otherInfo"/></div>
                                    </div>
                                </p-fieldset>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel *ngIf="selectedVersion.uisVersion >= 3" header="{{ 'orders.preferredSupplier' | translate }}">
                    <p-table tableStyleClass="djc-table" [value]="selectedVersion.preferredSuppliers" styleClass="table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{ 'products.productType' | translate }}</th>
                                <th>{{ 'products.supplier' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-preferredSupplier let-ri="rowIndex">
                            <tr>
                                <td>
                                    <p-dropdown [options]="productTypes" [(ngModel)]="preferredSupplier.productTypeId" (onChange)="preferredSupplierChange($event, ri)"></p-dropdown>
                                </td>
                                <td>
                                    <p-dropdown [options]="suppliers" [(ngModel)]="preferredSupplier.supplierId" (onChange)="preferredSupplierChange($event, ri)"></p-dropdown>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="{{ 'orders.parts' | translate }}">
                    <orderParts [order]="selectedVersion" [(showFinishes)]="_showFinishes" [(orderPart)]="selectedOrderPart" [(reloadParts)]="_reloadOrderParts"></orderParts>
                </p-tabPanel>
                <p-tabPanel *ngIf="selectedVersion.uisVersion >= 3" header="{{ 'orders.facilities' | translate }}">
                    <orderFacilities [(selectedVersion)]="selectedVersion" [orderSaving]="_orderSaving" (saveOrder)="saveOrder(false)"></orderFacilities>
                </p-tabPanel>
                <p-tabPanel header="{{ 'orders.textfields' | translate }}">
                    <form [formGroup]="_orderForm" novalidate (ngSubmit)="preSaveOrder()">
                        <div class="ui-g">
                            <div class="ui-g-2">{{ 'orders.standardText01' | translate }}</div>
                            <div class="ui-g-3">
                                <p-dropdown [options]="_defaultText01" id="standText01"
                                            (onChange)="mergeText('standardText01', $event.value)">
                                </p-dropdown>
                            </div>
                            <div class="ui-g-7"><textarea pInputTextarea formControlName="standardText01" rows="5" cols="120"></textarea></div>
                            <div class="ui-g-2">{{ 'orders.standardText02' | translate }}</div>
                            <div class="ui-g-3">
                                <p-dropdown [options]="_defaultText02" id="standText02"
                                            (onChange)="mergeText('standardText02', $event.value)">
                                </p-dropdown>
                            </div>
                            <div class="ui-g-7"><textarea pInputTextarea formControlName="standardText02" rows="5" cols="120"></textarea></div>
                            <div class="ui-g-2">{{ 'orders.standardText03' | translate }}</div>
                            <div class="ui-g-3">
                                <p-dropdown [options]="_defaultText03" id="standText03"
                                            (onChange)="mergeText('standardText03', $event.value)">
                                </p-dropdown>
                            </div>
                            <div class="ui-g-7"><textarea pInputTextarea formControlName="standardText03" rows="5" cols="120"></textarea></div>
                            <div class="ui-g-2">{{ 'orders.maintenance' | translate }}</div>
                            <div class="ui-g-3">
                                <p-dropdown [options]="_maintenanceText" id="maintText"
                                            (onChange)="mergeText('standardText04', $event.value)">
                                </p-dropdown>
                            </div>
                            <div class="ui-g-7"><textarea pInputTextarea formControlName="standardText04" rows="5" cols="120"></textarea></div>
                            <div class="ui-g-2">{{ 'masterdata.warranty' | translate }}</div>
                            <div class="ui-g-10">
                                <p-dropdown [options]="warranties" id="warranty"
                                            formControlName="warrantyId" (onChange)="changeWarranty($event)">
                                </p-dropdown>
                            </div>
                            <div class="ui-g-2">{{ 'masterdata.paymentterms' | translate }}</div>
                            <div class="ui-g-10">
                                <p-dropdown [options]="paymentTerms" id="paymentTerm"
                                            formControlName="paymentTermId">
                                </p-dropdown>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel header="{{ 'orders.summary' | translate }}">
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'base.total' | translate }} {{ 'orders.materialCost' | translate | lowercase }}</div>
                        <div class="ui-g-7"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.materialCost | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'base.total' | translate }} {{ 'orders.labourCost' | translate | lowercase }}</div>
                        <div class="ui-g-7"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g"></div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.travelCost' | translate }} {{ 'orders.travelDays' | translate | lowercase }}</div>
                        <div class="ui-g-2 right">{{ selectedVersion.totals.labourDays | number : '1.0-0' }}</div>
                        <div class="ui-g-2 right">{{ selectedVersion.travelHours | number : '1.0-0' }}</div>
                        <div class="ui-g-2 price">{{ selectedVersion.travelCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.labourDays * selectedVersion.travelHours * selectedVersion.travelCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.parkingCost' | translate }}</div>
                        <div class="ui-g-2 right">{{ selectedVersion.totals.labourDays | number : '1.0-0' }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.parkingCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.labourDays * selectedVersion.parkingCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                    </div>
                    <div class="ui-g"></div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.facilities' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">1</div>
                        <div class="ui-g-3"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.facilityCost | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.transportCost' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2">{{ selectedVersion.transportHours | number : '1.0-0' }}</div>
                        <div class="ui-g-2 price">{{ selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.transportHours * selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g"></div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.safetyCost' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.safetyHours | number : '1.0-0' }}</div>
                        <div class="ui-g-2 price">{{ selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.safetyHours * selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                    </div>
                    <div class="ui-g"></div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.preparationCost' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.preparationHours | number : '1.0-0' }}</div>
                        <div class="ui-g-2 price">{{ selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.preparationHours * selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.inspectionCost' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.inspectionHours | number : '1.0-0' }}</div>
                        <div class="ui-g-2 price">{{ selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.inspectionHours * selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.calculationCost' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.calculationHours | number : '1.0-0' }}</div>
                        <div class="ui-g-2 price">{{ selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.calculationHours * selectedVersion.labourCost | currency : 'EUR':'symbol':'1.2-2':'nl'}}</div>
                    </div>
                    <div class="ui-g"></div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'base.total' | translate }} {{ 'orders.directCost' | translate | lowercase }}</div>
                        <div class="ui-g-7"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.totDirectCost | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.commonCosts' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.commonCosts | number : '1.2-2' }}%</div>
                        <div class="ui-g-3"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.commonCosts | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'base.total' | translate }} {{ 'orders.costPrice' | translate | lowercase }}</div>
                        <div class="ui-g-7"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.totDirectCost + selectedVersion.totals.commonCosts | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.bonusContractPrice' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.bonusContractPrice | number : '1.2-2' }}%</div>
                        <div class="ui-g-3"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.bonusContractPrice | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'base.total' | translate }} {{ 'orders.contractPrice' | translate | lowercase }}</div>
                        <div class="ui-g-7"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.contractPrice | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.profitAndRisk' | translate }}</div>
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.profitAndRisk | number : '1.2-2' }}%</div>
                        <div class="ui-g-3"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.profitAndRisk | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'base.total' | translate }} {{ 'orders.projectPrice' | translate | lowercase }}</div>
                        <div class="ui-g-7"></div>
                        <div class="ui-g-2 price font-weight-bold">{{ selectedVersion.totals.contractPrice + selectedVersion.totals.profitAndRisk | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                    <div class="ui-g"></div>
                    <div *ngIf="selectedVersion.privateAssignment" style="background-color: lightgrey">
                        <div class="ui-g">
                            <div class="ui-g-3 font-weight-bold">{{ 'orders.vatLabour' | translate }} {{ selectedVersion.vatLabour }}%</div>
                            <div class="ui-g-7"></div>
                            <div class="ui-g-2 price">{{ selectedVersion.totals.vatLabour | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-3 font-weight-bold">{{ 'orders.vatMaterials' | translate }} {{ selectedVersion.vatMaterials }}%</div>
                            <div class="ui-g-7"></div>
                            <div class="ui-g-2 price">{{ selectedVersion.totals.vatMaterials | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-3 font-weight-bold">{{ 'orders.totalInclVat' | translate }}</div>
                            <div class="ui-g-7"></div>
                            <div class="ui-g-2 price font-weight-bold">{{ selectedVersion.totals.contractPrice - selectedVersion.totals.discount + selectedVersion.totals.vatLabour + selectedVersion.totals.vatMaterials | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                    </div>
                    <div class="ui-g"></div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.squareMeterPrice' | translate }}</div>
                        <div class="ui-g-4"></div>
                        <div class="ui-g-2 price">{{ selectedVersion.totals.contractPrice / selectedVersion.totals.squareMeters | currency : 'EUR':'symbol':'1.2-2':'nl' }}</div>
                        <div class="ui-g-3"></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.squareMeters' | translate }}</div>
                        <div class="ui-g-4"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.totals.squareMeters | number : '1.2-2' }}</div>
                        <div class="ui-g-3"></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.labourHoursDays' | translate }}</div>
                        <div class="ui-g-4"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.totals.labourHours | number : '1.2-2' }} / {{ selectedVersion.totals.labourDays | number : '1.2-2' }}</div>
                        <div class="ui-g-3"></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3 font-weight-bold">{{ 'orders.squareMetersPerDay' | translate }}</div>
                        <div class="ui-g-4"></div>
                        <div class="ui-g-2 right">{{ selectedVersion.totals.squareMeters / selectedVersion.totals.labourDays | number : '1.2-2' }}</div>
                        <div class="ui-g-3"></div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-scrollPanel>
        <p-footer>
            <button type="button" pButton icon="fa fa-save" (click)="preSaveOrder(false)" label="{{ 'base.save' | translate }}" [disabled]="!_canEdit" [ngStyle]="{'background-color': _orderForm.dirty ? 'red' : ''}"></button>
            <button type="button" pButton icon="fa fa-close" (click)="preCancelOrderDialog()" label="{{ 'base.cancel' | translate }}"></button>
            <button type="button" pButton icon="fa fa-calculator" (click)="calculatePartsPrice()" label="{{ 'orders.calculate' | translate }}" [disabled]="!_canEdit"></button>
            <button *ngIf="selectedVersion.goProjectId > 0 && selectedVersion.newTotal > 0"type="button" pButton class="floatRight" icon="fa fa-file-pdf-o" (click)="preCreateOpenBudget()" label="{{ 'orders.generateOpenBudget' | translate }}"></button>
            <button *ngIf="selectedVersion.goProjectId > 0" type="button" pButton class="floatRight" icon="fa fa-file-pdf-o" (click)="createDetail()" label="{{ 'orders.generateDetail' | translate }}"></button>
            <button *ngIf="selectedVersion.goProjectId > 0" type="button" pButton class="floatRight" icon="fa fa-file-word-o" (click)="preCreateDoc()" label="{{ 'orders.generateDoc' | translate }}"></button>
            <button *ngIf="selectedVersion.goProjectId == 0" type="button" pButton class="floatRight" icon="fa fa-files-o" (click)="createGkProject($event)" label="{{ 'orders.createProject' | translate }}" [disabled]="!_canAdd"></button>
            <button *ngIf="selectedVersion.goProjectId !== 0" type="button" pButton class="floatRight ui-button-danger" icon="fa fa-files-o" (click)="deleteGkProject($event)" label="{{ 'orders.disconnectProject' | translate }}" [disabled]="!_canAdd"></button>
        </p-footer>
    </div>
    <div *ngIf="_showFinishes" style="height: 100%">
        <orderFinishes [part]="selectedOrderPart" [(visible)]="_showFinishes" [order]="selectedVersion" (visibleChange)="handleTabChange({index: 3})"></orderFinishes>
    </div>
    <p-dialog [(visible)]="_showDocOptions" header="{{ 'orders.options' | translate }}" [modal]="true" [closable]="true" class="pDialog" [style]="{'width': '40%'}">
        <div class="ui-g">
            <div class="ui-g-3">{{ 'orders.subTotals' | translate }}</div>
            <div class="ui-g-2"><p-checkbox [(ngModel)]="_subTotals" binary="true"></p-checkbox></div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">{{ 'orders.includingVAT' | translate }}</div>
            <div class="ui-g-2"><p-checkbox [(ngModel)]="_includeVAT" binary="true" (onChange)="checkSubTotals()"></p-checkbox></div>
        </div>
        <div *ngIf="selectedVersion.orderCustomers.length > 1">
            <div class="ui-g">
                <div class="ui-g-3">{{ 'orders.customer' | translate }}</div>
                <div class="ui-g-9">
                    <p-dropdown [options]="_orderCustomers"
                                [(ngModel)]="_selectedCustomerId" appendTo="body">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <button type="button" pButton icon="fa fa-file-word-o" (click)="createDoc(_subTotals, _includeVAT, _selectedCustomerId)" [disabled]="_selectedCustomerId.length == 0"></button>
    </p-dialog>
    <p-dialog [(visible)]="_showOpenBudgetOptions" header="{{ 'orders.options' | translate }}" [modal]="true" [closable]="true" class="pDialog" [style]="{'width': '40%'}">
        <div class="ui-g">
            <div class="ui-g-3">{{ 'orders.additionPercentage' | translate }} {{ 'products.products' | translate }}</div>
            <div class="ui-g-2">
                <p-spinner
                    [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                    [(ngModel)]="_openBudgetOptions.additionMaterial" [step]="0.1" [formatInput]="false">
                </p-spinner>
            </div>
            <div class="ui-g-7"></div>
            <div class="ui-g-3">{{ 'orders.additionPercentage' | translate }} {{ 'orders.labour' | translate }}</div>
            <div class="ui-g-2">
                <p-spinner
                    [decimalSeparator]="_auth.getUserSettings().decimalSeparator" [thousandSeparator]="_auth.getUserSettings().thousandSeparator"
                    [(ngModel)]="_openBudgetOptions.additionLabour" [step]="0.1" [formatInput]="false">
                </p-spinner>
            </div>
            <div class="ui-g-7"></div>
        </div>
        <div *ngIf="selectedVersion.orderCustomers.length > 1">
            <div class="ui-g">
                <div class="ui-g-3">{{ 'orders.customer' | translate }}</div>
                <div class="ui-g-9">
                    <p-dropdown [options]="_orderCustomers"
                                [(ngModel)]="_openBudgetOptions.customerId" appendTo="body">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <button type="button" pButton icon="fa fa-file-pdf-o" (click)="createOpenBudget()" [disabled]="_selectedCustomerId.length == 0 && _addition == 0"></button>
    </p-dialog>
    <p-dialog [(visible)]="_showNewVersionInfo" header="{{ 'orders.options' | translate }}" [modal]="true" [closable]="true" class="pDialog" [style]="{'width': '40%'}">
        <div class="ui-g">
            <div class="ui-g-3">{{ 'orders.updatePrices' | translate }}</div>
            <div class="ui-g-2"><p-checkbox [(ngModel)]="_newVersionInfo.updatePrices" [binary]="true"></p-checkbox></div>
            <div class="ui-g-7"></div>
            <div class="ui-g-3">{{ 'orders.emptyOrder' | translate }}</div>
            <div class="ui-g-2"><p-checkbox [(ngModel)]="_newVersionInfo.emptyOrder" [binary]="true"></p-checkbox></div>
            <div class="ui-g-7"></div>
        </div>
        <button type="button" pButton icon="fa fa-plus" (click)="newOrderVersion()"></button>
    </p-dialog>
</div>
