import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {ProductsComponent} from "./products.component";
import {ProductGroupFinishComponent} from "./pgf.component";
import {ProductProcessComponent} from "./pp.component";
import {CsvImportComponent} from "./csvimport.component";
import {ProductImportsComponent} from "./product-imports.component";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        ProductsComponent,
        ProductGroupFinishComponent,
        ProductProcessComponent,
        CsvImportComponent,
        ProductImportsComponent
    ],
    exports: [
        ProductGroupFinishComponent,
        ProductProcessComponent
    ]
})
export class ProductsModule {}
