<p-dialog appendTo="body" [closable]="true" (onHide)="cancelPP()" styleClass="ui-g-8 pDialog" [modal]="true" positionTop="250" [visible]="true">
    <div class="ui-g">
        <div class="ui-g-3"><label for="productGroup">{{ 'products.product' | translate }}</label></div>
        <div class="ui-g-9"><span id="productGroup">{{ _pp.product.name }}</span></div>
    </div>
    <div class="ui-g">
        <div class="ui-g-3"><label for="process">{{ 'masterdata.process' | translate }}</label></div>
        <div class="ui-g-9"><span id="process">{{ _pp.process.description }}</span></div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="savePP()" label="{{ 'base.save' | translate }}"></button>
        <button type="button" pButton icon="pi pi-times" (click)="cancelPP()" label="{{ 'base.cancel' | translate }}" *ngIf="!_event.mustComplete"></button>
    </p-footer>
</p-dialog>
